import { CompassPayFrequency } from "./CompassPayFrequency";

export enum PayFrequency {
    ANNUALLY = 'Annually',
    MONTHLY = 'Monthly',
    SEMI_MONTHLY = 'Semi-monthly',
    BI_WEEKLY = 'Bi-weekly',
    WEEKLY = 'Weekly',
    HOURLY = 'Hourly'
}

export function findPayFrequencyFor(compassPayFrequency: CompassPayFrequency) {
    if (compassPayFrequency === CompassPayFrequency.YEAR) {
        return PayFrequency.ANNUALLY
    } else if (compassPayFrequency === CompassPayFrequency.MONTH) {
        return PayFrequency.MONTHLY
    } else if (compassPayFrequency === CompassPayFrequency.SEMIMONTHLY) {
        return PayFrequency.SEMI_MONTHLY
    } else if (compassPayFrequency === CompassPayFrequency.BIWEEKLY) {
        return PayFrequency.BI_WEEKLY
    } else if (compassPayFrequency === CompassPayFrequency.WEEK) {
        return PayFrequency.WEEKLY
    } else if (compassPayFrequency === CompassPayFrequency.HOUR) {
        return PayFrequency.HOURLY
    }
    return null;
}