import React from 'react';
import SelectDropdown, { SelectDropdownOption } from "./SelectDropdown";

export type StateDropdownProps = {
    id: string,
    label: string,
    required: boolean,
    changeCallback?: Function,
    children?: React.ReactNode
}

const StateDropdown = (props: StateDropdownProps) => {
    return (
        <SelectDropdown options={buildOptions()} {...props}>
        </SelectDropdown>
    )
};

function buildOptions(): SelectDropdownOption[] {
    let stateOptions = [] as SelectDropdownOption[];
    for (const state of states) {
        stateOptions.push({
            id: state,
            text: state
        })
    }
    return stateOptions;
}

const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY"
]

export default StateDropdown;
