import React, { useEffect } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import { FormikErrors } from "formik";
import PhoneInput from "../../input/PhoneInput";
import { ValidateAddressResponse } from "../../../service/AddressService";
import AddressModal from "../../structure/AddressModal";
import TwoColumnDiv from "../../structure/TwoColumnDiv";
import { PfmlPhysicianFormValues } from "../../pfml/PfmlPhysicianFormValues";
import StateDropdown from "../../input/StateDropdown";
import ZipCodeInput from "../../input/ZipCodeInput";
import HorizontalRule from "../../structure/HorizontalRule";

export type AboutPhysicianProps = {
    values?: PfmlPhysicianFormValues,
    errors?: FormikErrors<any>,
    setFieldValue?: Function,
    validateForm?: Function,
    submitForm?: Function,
    children?: React.ReactNode;
}

const AboutPhysician = (props: AboutPhysicianProps) => {
    const setFieldValue = props.setFieldValue;
    useEffect(() => {
        if(setFieldValue) {
            setFieldValue('useEnteredAddress', false, false)
        }
    }, [setFieldValue])

    const enteredAddress = {
        streetAddress: props.values?.physicianStreetAddress,
        city: props.values?.physicianCity,
        state: props.values?.physicianState,
        zipCode: props.values?.physicianZipCode
    } as ValidateAddressResponse;

    return (
        <>
            {
                <AddressModal {...props} enteredAddress={enteredAddress} correctedAddress={(props.errors?.physicianStreetAddress as any)?.message as any}
                              streetAddressField="physicianStreetAddress" cityField="physicianCity" stateField="physicianState"
                              zipCodeField="physicianZipCode"/>
            }
            <QuestionPanel headerText="What's the treating physician's contact information?"
                           panelContent={
                               <div>
                                   <div>
                                       <TextInput id="physician-first-name" label="First name" required={true} maxLength={1024}/>
                                       <TextInput id="physician-last-name" label="Last name" required={true} maxLength={1024}/>
                                       <TextInput id="physician-degree" label="Degree / specialty" required={true} maxLength={1024}/>
                                       <PhoneInput id="physician-phone-number" label="Phone number" required={true}/>
                                       <PhoneInput id="physician-fax-number" label="Fax number" required={false}/>
                                   </div>
                                   <HorizontalRule/>
                                   <div>
                                       <TextInput id="physician-street-address" label="Office address" required={true}/>
                                       <TwoColumnDiv leftContent={<TextInput id="physician-city" label="City" required={true}/>}
                                                     rightContent={<TwoColumnDiv
                                                        leftContent={<StateDropdown id="physician-state" label="State" required={true}/>}
                                                        rightContent={<ZipCodeInput id="physician-zip-code" label="ZIP" required={true}/>}>
                                                     </TwoColumnDiv>}/>
                                       <TextInput id="form-completer-name" label="Name of person completing form" required={false} maxLength={1024}/>
                                   </div>
                               </div>
                           }>
            </QuestionPanel>
        </>
    )
};
export default AboutPhysician;