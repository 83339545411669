import React, { useEffect } from 'react';
import TwoColumnDiv from "../structure/TwoColumnDiv";
import { ListElementProps } from "../button/AddListElementButton";
import CloseButton from "../button/CloseButton";
import TextInput from "./TextInput";
import { TypeOfClaim } from "../enums/TypeOfClaim";
import SelectDropdown from "./SelectDropdown";
import { findPregnancyDiagnosisDescriptionFor, PregnancyDiagnosis } from "../enums/PregnancyDiagnosis";
var _ = require('lodash');

let setFieldValue: Function | undefined;

const DiagnosisCode = (props: ListElementProps) => {
    const namePrefix = _.camelCase(props.idPrefix);
    setFieldValue = props.setFieldValue;

    const diagnosisCodeId = props.idPrefix + "-diagnosis-code-" + props.index;
    const diagnosisCodeName = `${namePrefix}[${props.index}].diagnosisCode`;
    const diagnosisName = `${namePrefix}[${props.index}].diagnosis`;

    const index = props.index;
    const isPregnancyPrimaryDiagnosis = index === 0 && props.values?.claimType === TypeOfClaim.PREGNANCY;

    useEffect(() => {
        if(isPregnancyPrimaryDiagnosis) {
            return;
        }
        // @ts-ignore
        new window.Def.Autocompleter.Search(diagnosisCodeId,
            'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name',
            {tableFormat: true, valueCols: [0], colHeaders: ['Code', 'Name']});
        // @ts-ignore
        window.Def.Autocompleter.Event.observeListSelections(diagnosisCodeName, (hash: any) => {
            if(!hash.list || !hash.final_val) {
                return;
            }
            const diagnosis = hash.list.find((value: any) => value[0] === hash.final_val);
            if(diagnosis && setFieldValue) {
                setFieldValue(diagnosisCodeName, diagnosis[0]);
                setFieldValue(diagnosisName, diagnosis[1]);
            }
        });
    }, [isPregnancyPrimaryDiagnosis, diagnosisCodeId, diagnosisCodeName, diagnosisName])

    return (
        <div>
            <TwoColumnDiv
                leftContent={buildDiagnosisLabel()}
                rightContent={
                    index !== 0 && props.closeCallback && <CloseButton idPrefix="" index={index} closeCallback={props.closeCallback}/>
                }
            />
            <TwoColumnDiv
                leftContent={
                    isPregnancyPrimaryDiagnosis
                            ? <SelectDropdown id={diagnosisCodeId}
                                              name={diagnosisCodeName}
                                              label="ICD 10 code"
                                              required={true}
                                              options={Object.keys(PregnancyDiagnosis).map(p => ({
                                                  id: p.replace(/_/g, '-').toLowerCase(),
                                                  text: (PregnancyDiagnosis as any)[p]
                                              }))}
                                              changeCallback={(target: HTMLInputElement) => {
                                                  const description = findPregnancyDiagnosisDescriptionFor(target.value as PregnancyDiagnosis);
                                                  setFieldValue?.(diagnosisName, description);
                                              }}/>
                            : <TextInput id={diagnosisCodeId}
                                         name={diagnosisCodeName}
                                         label="ICD 10 code" required={true} maxLength={1024}/>
                }
                rightContent={
                    <TextInput id={props.idPrefix + "-diagnosis-" + index}
                               name={diagnosisName}
                               label="Diagnosis" required={true} maxLength={1024}/>
                }
            />
        </div>
    )

    function buildDiagnosisLabel() {
        if(props.index === 0) {
            return <label>Primary diagnosis</label>
        }
        else if(props.index === 1) {
            return <label>Secondary diagnoses / complications</label>
        }
        return <></>
    }
};

export default DiagnosisCode;