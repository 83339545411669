import React, { useEffect } from 'react';
import TextInput from "./TextInput";
import { FieldValidator } from "formik";
import { PdsIconClock } from "@principal/design-system-icons-react";

export type TimeInputProps = {
    id: string,
    name?: string,
    label: string,
    required: boolean,
    validate?: FieldValidator,
}

const TimeInput = (props: TimeInputProps) => {
    const id = props.id;

    useEffect(() => {
        const shadowRoot = document.getElementById(id)?.shadowRoot;
        const styleId = 'time-style';
        if(shadowRoot?.getElementById(styleId)) {
            return;
        }
        const styleElement = document.createElement('style');
        styleElement.setAttribute('id', styleId);
        styleElement.innerHTML = 'input[type="time"]::-webkit-calendar-picker-indicator {\n' +
            '    background: none;\n' +
            '}';
        shadowRoot?.appendChild(styleElement);
    }, [id])

    return <TextInput id={id} name={props.name} label={props.label} required={props.required}
                      inputProps={{ type: 'time' }} rightContent={<PdsIconClock/>} />
};

export default TimeInput;