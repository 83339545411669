export enum PhysicianPages {
    START = 'Start',
    PHYSICIAN_INFO = 'Physician info',
    PATIENT_INFO = 'Patient info',
    CLAIM_TYPE = 'Claim type',
    CONDITION = 'Condition',
    PATIENT_CARE = 'Patient care',
    DOCUMENT = 'Document',
    COVERED_INDIVIDUAL = 'Covered individual',
    ABILITY_TO_WORK = 'Ability to work',
    SUMMARY = 'Summary',
    FINISH = 'Finish'
}