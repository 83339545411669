import { defaultDisabilityFormValues, DisabilityFormValues } from "./DisabilityFormValues";
import { defaultPhysicianFormValues, PhysicianFormValues } from "../common/PhysicianFormValues";
import { Medication } from "../domain/Medication";
import { Procedure } from "../domain/Procedure";
import { Referral } from "../domain/Referral";

export type DisabilityPhysicianFormValues = PhysicianFormValues & DisabilityFormValues & {
    physicianFaxNumber: string,
    formCompleterName: string,
    workRelated: string,
    medications: Medication[],
    surgeries: Procedure[],
    therapies: Procedure[],
    referrals: Referral[],
}

export const defaultDisabilityPhysicianFormValues = {
    ...defaultPhysicianFormValues,
    ...defaultDisabilityFormValues,
    physicianFaxNumber: '',
    formCompleterName: '',
    workRelated: '',
    medications: [],
    surgeries: [],
    therapies: [],
    referrals: [],
}