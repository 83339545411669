import Modal from "./Modal";
import React from "react";
import Checkbox from "../input/Checkbox";
import { ModalSize } from "../enums/ModalSize";
import CenteredFullWidthDiv from "./CenteredFullWidthDiv";
import Button from "../button/Button";
import { FormikValues } from "formik";
import { BenefitType } from "../enums/BenefitType";

export type EConsentModalProps = {
    values?: FormikValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

const EConsentModal = (props: EConsentModalProps) => {
    const consent = props.values?.eConsent;
    const benefit = props.values?.benefit;

    if(consent && props.values?.eConsentContinue) {
        return null;
    }

    return <Modal id="e-consent-validation"
                  headerText="Consent to do business electronically"
                  modalContent={
                      <div>
                          <ul>
                              <li>
                                  Your consent applies to documents relating to your claim with Principal Life Insurance Company which are available in electronic format and which you prefer to provide or receive via email. An electronic format may not be available for all types of claims or for all types of documents.
                              </li>
                              <li>
                                  You are not required to handle any portion of your claim electronically. You can decline to consent to this document and your claim will be handled using paper documents.
                              </li>
                              <li>
                                  Once you provide your consent, you will have the right at any time to withdraw it.
                              </li>
                              <li>
                                  We will need your email address in order to communicate and exchange documents electronically. If your email address should ever change, you must notify us and provide updated information.
                              </li>
                              <li>
                                  You will need access to a computer or device capable of sending and receiving email messages with attachments. You will need an operating system that allows you to download and print documents or save them. You will need Adobe Reader or similar software to view and retain documents in PDF format. If we should ever change the hardware or software requirements needed to access or share documents electronically, we will advise you.
                              </li>
                              <li>
                                  You will have the ability to download and print any documents we send or make available to you electronically. You may also request delivery of paper copies by contacting us.
                              </li>
                              <li>
                                  If you decide to withdraw your consent, request paper copies of electronic documents, or report a change in your email address, please contact us at: 800-245-1522.
                              </li>
                          </ul>
                          <p>
                              Agreement - By consenting to do business electronically, you understand and agree that you were able to access and read this information electronically and also were able to print it or save it for your future reference and access.
                              {benefit === BenefitType.LIFE && <strong> Disclaimer: If you are a California resident, E-consent is not applicable.</strong>}
                          </p>
                          <CenteredFullWidthDiv divContent={<Checkbox id="e-consent" label="I consent"/>} />
                      </div>}
                  modalFooter={
                      <>
                          { consent && <Button buttonContent="Continue" onClick={() => {
                              if(props.setFieldValue) {
                                  props.setFieldValue('eConsentContinue', true);
                              }
                          }}/> }
                      </>}
                  modalSize={ModalSize.LARGE}
                  closeable={false}/>;
}

export default EConsentModal;
