import {UploadedDocument} from "../domain/UploadedDocument";

export type FormValues = {
    claimId: string,
    claimNumber: string,
    benefit: string,
    party: string,
    eConsent: boolean,
    eConsentContinue: boolean,
    claimType: string,
    relationship: string,
    firstName: string,
    lastName: string,
    useEnteredAddress: boolean,
    medicalRelated: boolean,
    defaultDocumentType: string,
    documents: UploadedDocument[],
    summaryContent: string,
    currentStep: string,
    formKey: string,
    loading: boolean
}

export const defaultFormValues = {
    claimId: '',
    claimNumber: '',
    benefit: '',
    party: '',
    eConsent: false,
    eConsentContinue: false,
    claimType: '',
    relationship: '',
    firstName: '',
    lastName: '',
    useEnteredAddress: false,
    medicalRelated: false,
    defaultDocumentType: '',
    documents: [{
        document: '',
        documentContent: '',
        documentType: ''
    }],
    summaryContent: '',
    currentStep: '',
    formKey: '',
    loading: false
}