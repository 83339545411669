import { defaultPfmlFormValues, PfmlFormValues } from "./PfmlFormValues";
import { defaultEmployerFormValues, EmployerFormValues } from "../common/EmployerFormValues";
import { QuarterlyEarnings } from "../domain/QuarterlyEarnings";
import { DateTime } from "luxon";
import { PaymentPreference } from "../enums/PaymentPreference";

export type PfmlEmployerFormValues = EmployerFormValues & PfmlFormValues & {
    priorDisability: boolean,
    priorDisabilityDates: [{
        startDate: string,
        endDate: string
    }],
    priorPaidFamilyMedicalLeave: boolean,
    priorPaidFamilyMedicalLeaveDates: [{
        startDate: string,
        endDate: string
    }],
    priorPaidFamilyMedicalLeaveHours: string,
    fullPayAfterDateLastWorkedEndDate: string,
    earningsQuarters: QuarterlyEarnings[],
    employerReimbursement: string,
    employerReimbursementConsent: boolean,
    employerSignature: string,
    employerSignatureDate: string,
    employerPaymentPreference: string,
    employerAccountNumber: string,
    employerAccountNumber2: string,
    employerRoutingNumber: string,
    employerAccountType: string,
    eftConsent: boolean,
    eftConsentContinue: boolean
}

export const defaultPfmlEmployerFormValues = {
    ...defaultEmployerFormValues,
    ...defaultPfmlFormValues,
    priorDisability: false,
    priorDisabilityDates: [{
        startDate: '',
        endDate: ''
    }],
    priorPaidFamilyMedicalLeave: false,
    priorPaidFamilyMedicalLeaveDates: [{
        startDate: '',
        endDate: ''
    }],
    priorPaidFamilyMedicalLeaveHours: '',
    fullPayAfterDateLastWorkedEndDate: '',
    earningsQuarters: [{
        startDate: '',
        endDate: '',
        amount: ''
    }, {
        startDate: '',
        endDate: '',
        amount: ''
    }, {
        startDate: '',
        endDate: '',
        amount: ''
    }, {
        startDate: '',
        endDate: '',
        amount: ''
    }, {
        startDate: '',
        endDate: '',
        amount: ''
    }],
    employerReimbursement: '',
    employerReimbursementConsent: false,
    employerSignature: '',
    employerSignatureDate: DateTime.now().toFormat('MM/dd/yyyy'),
    employerPaymentPreference: PaymentPreference.EFT,
    employerAccountNumber: '',
    employerAccountNumber2: '',
    employerRoutingNumber: '',
    employerAccountType: '',
    eftConsent: false,
    eftConsentContinue: false
}