import React, { ReactElement } from 'react';
import TextInput from "./TextInput";
import { string } from "yup";

type EmailInputProps = {
    id: string,
    name?: string,
    label: string,
    required: boolean,
    labelContent?: ReactElement,
    children?: React.ReactNode
}

const EmailInput = (props: EmailInputProps) => {
    return <TextInput id={props.id} name={props.name} label={props.label} required={props.required} labelContent={props.labelContent}
                      maxLength={320} validate={validateEmail}/>

    function validateEmail(value: string) {
        return string().email().isValidSync(value) ? '' : 'Please specify a valid email address.'
    }
};

export default EmailInput;