import React from "react";
import { useCombobox } from 'downshift'
import { PdsTextInput } from "@principal/design-system-react";
import { PdsIconSearch } from "@principal/design-system-icons-react";

export type SearchProps = {
    id: string,
    label: string,
    options: Option[],
    placeholder?: string,
    maxResults?: number,
    children?: React.ReactNode
}

export type Option = {
    id: string,
    text: string,
    value: string,
    onSelect?: Function
}

const Search = (props: SearchProps) => {
    const [inputItems, setInputItems] = React.useState(props.options)
    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
        items: inputItems,
        itemToString: (item) => item?.text || '',
        onInputValueChange: ({inputValue}) => {
            const chosenOptions = props.options.filter(item =>
                item.text.toLowerCase().includes(inputValue.toLowerCase()),
            );
            setInputItems(chosenOptions)
        },
        onSelectedItemChange: ({ selectedItem }) => {
            selectedItem?.onSelect?.(selectedItem.id)
        }
    })

    return (
        <div className="pds-u-margin-bottom-16">
            <PdsTextInput {...getInputProps()} label={props.label} helpText={props.placeholder}>
                <span slot="suffix"><PdsIconSearch/></span>
            </PdsTextInput>
            <ul {...getMenuProps()}
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    background: 'white',
                    listStyle: 'none',
                    width: '80%',
                    padding: '0',
                    margin: '4px 0 0 0',
                }}>
                {isOpen &&
                    inputItems
                        .slice(0, props.maxResults)
                        .map((item, index) => (
                            <li style={{
                                padding: '4px',
                                backgroundColor: highlightedIndex === index ? '#bde4ff' : undefined,
                            }}
                                key={`${item}${index}`}
                                {...getItemProps({
                                    item,
                                    index,
                                })}>
                                {item.text}
                            </li>
                        ))}
            </ul>
        </div>
    )
};

export default Search;