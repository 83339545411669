import { BenefitType } from "../enums/BenefitType";

export type HospitalIndemnityFormValues = {
    links: {
        employee: string
        employer: string
        physician: string
    }
}

export const defaultHospitalIndemnityFormValues = {
    benefit: BenefitType.HOSPITAL_INDEMNITY,
    links: {
        employee: "",
        employer: "",
        physician: ""
    }
}