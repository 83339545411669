import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import DateInput from "../../input/DateInput";
import SelectDropdown from "../../input/SelectDropdown";
import { BenefitType, findRelationshipsFor } from "../../enums/BenefitType";
import { Party } from "../../enums/Party";
import { getKeyByValue } from "../../enums/KeyFinder";
import { Relationship } from "../../enums/Relationship";
import { FormikValues } from "formik";
import DependentChoice from "../employee/DependentChoice";
import Callout from '../../structure/Callout';
import { PatientType } from "../../enums/PatientType";

export type AboutRelationshipProps = {
    initialValues?: FormikValues,
    values?: FormikValues,
    setFieldValue?: Function
}

const AboutRelationship = (props: AboutRelationshipProps) => {
    const values = props.values;
    const setFieldValue = props.setFieldValue;
    const benefit = values?.benefit as BenefitType;
    const party = values?.party as Party;
    const relationship = values?.relationship;
    const patientName = BenefitType.LIFE === benefit ? PatientType.DECEASED : PatientType.PATIENT;
    const lowercasePatientName = patientName.toLowerCase();

    const firstNameField = `${lowercasePatientName}FirstName`;
    const lastNameField = `${lowercasePatientName}LastName`;
    const dateOfBirthField = `${lowercasePatientName}DateOfBirth`;

    const relationships = findRelationshipsFor(benefit);
    const isPhysician = Party.PHYSICIAN === party;
    const showPatientDemographicQuestions = isPhysician
        || (props.initialValues?.[firstNameField] !== undefined && relationship !== '' && relationship !== Relationship.EMPLOYEE);
    return (
        <QuestionPanel headerText={`Who is the ${lowercasePatientName}?`}
                       detailsText={PatientType.PATIENT === patientName && 'The patient is the person who is receiving care (from an employee or physician). For instance, if the claim is for the care of a child, choose "Family member" or "Covered dependent" for the relationship.'}
                       panelContent={
                           <div>
                               <SelectDropdown id="relationship" label="Relationship" required={true}
                                               options={relationships.map(r => ({
                                                   id: getKeyByValue(Relationship, r).replace(/_/g, '-'),
                                                   text: Party.EMPLOYEE === party && Relationship.EMPLOYEE === r ? Relationship.MYSELF : r,
                                                   value: r,
                                               }))}
                                               changeCallback={(target: HTMLSelectElement) => {
                                                   if(!setFieldValue) {
                                                       return
                                                   }
                                                   if(target.value === Relationship.EMPLOYEE) {
                                                       setFieldValue(firstNameField, values?.firstName)
                                                       setFieldValue(lastNameField, values?.lastName)
                                                       setFieldValue(dateOfBirthField, values?.dateOfBirth)
                                                       return;
                                                   }
                                                   setFieldValue(firstNameField, '')
                                                   setFieldValue(lastNameField, '')
                                                   setFieldValue(dateOfBirthField, '')
                                                   if(isPhysician) {
                                                       setFieldValue('firstName', '')
                                                       setFieldValue('lastName', '')
                                                       setFieldValue('patientRelationship', '')
                                                       setFieldValue('patientRequiresCare', '')
                                                       setFieldValue('recommendedTimeOff', '')
                                                       setFieldValue('recommendedTimeOffDays', '')
                                                       setFieldValue('recommendedTimeOffHours', '')
                                                       setFieldValue('returnToWork', '')
                                                       setFieldValue('returnToWorkDays', '')
                                                       setFieldValue('returnToWorkHours', '')
                                                   }
                                               }}/>
                               {showPatientDemographicQuestions && <Callout>
                                   <DependentChoice values={values} setFieldValue={setFieldValue}/>
                                   <TextInput id={`${lowercasePatientName}-first-name`} label={`${patientName}'s first name`} required={true} maxLength={1024}/>
                                   <TextInput id={`${lowercasePatientName}-last-name`} label={`${patientName}'s last name`} required={true} maxLength={1024}/>
                                   <DateInput id={`${lowercasePatientName}-date-of-birth`} label={`${patientName}'s date of birth`} required={true}/>
                               </Callout>}
                           </div>
                       }
        >
        </QuestionPanel>
    )
};
export default AboutRelationship;