import { Party } from "../enums/Party";
import { FormikStepper } from "../navigation/FormikStepper";
import { FormikStep } from "../navigation/FormikStep";
import { EmployerPages } from "../enums/EmployerPages";
import Start from "../page/employer/Start";
import { object } from "yup";
import {
    accountNumberValidator,
    addressValidator,
    dateRangeArrayValidator,
    dateRangeValidator,
    eftConsentValidator,
    employerReimbursementConsentValidator,
    lessThanEqualToCurrentDateValidator,
    requiredIfFieldNotPresent,
    validateBeneficiaryDesignation,
    validateDocumentContent,
    validateDocumentsTotalSize,
    validatePolicyNumber,
    validatePrivacyId,
    validateUnitNumber,
    validRoutingNumber,
} from "../../form/Validators";
import AboutEmployer from "../page/employer/AboutEmployer";
import AboutEmployee from "../page/employer/AboutEmployee";
import Occupation from "../page/employer/Occupation";
import Employment from "../page/employer/Employment";
import EmployerClaimType from "../page/employer/EmployerClaimType";
import EmployerDocument from "../page/employer/EmployerDocument";
import Condition from "../page/employer/Condition";
import Financial from "../page/employer/Financial";
import EmployerSummary from "../page/employer/EmployerSummary";
import React, { useEffect } from "react";
import { gatherInitialValues, submitHandler } from "../../service/FormService";
import { BenefitType, findPartyChoicesFor, findRelationshipsFor } from "../enums/BenefitType";
import { defaultPfmlEmployerFormValues, PfmlEmployerFormValues } from "../pfml/PfmlEmployerFormValues";
import { defaultLifeEmployerFormValues } from "../life/LifeEmployerFormValues";
import { defaultDisabilityEmployerFormValues } from "../disability/DisabilityEmployerFormValues";
import { WorkSchedule } from "../enums/WorkSchedule";
import { useAuth } from "oidc-react";
import EmployerFinished from "../page/employer/EmployerFinished";
import { defaultHospitalIndemnityEmployerFormValues } from "../indemnity/HospitalIndemnityEmployerFormValues";
import { FormikValues } from "formik";
import UseLinkError from "../page/employer/UseLinkError";
import AboutRelationship from "../page/common/AboutRelationship";
import AboutContactPerson from "../page/employer/AboutContactPerson";
import Payments from "../page/common/Payments";
import { useParams } from "react-router-dom";
import Spinner from "../structure/Spinner";

const Employer = () => {
    const auth = useAuth();
    const [formInitialValues, setFormInitialValues] = React.useState();

    const params = useParams();
    const coverage = params.coverage as BenefitType;
    const claimId = params.claimId;
    const token = auth?.userData?.access_token;
    const authLoading = auth.isLoading;

    useEffect(() => {
        async function findInitialValues() {
            const formValues = await gatherInitialValues(claimId, coverage, Party.EMPLOYER, token);
            setFormInitialValues(formValues)
        }
        if(!authLoading) {
            findInitialValues();
        }
    }, [coverage, claimId, token, authLoading])

    const initialValues = {
        ...findEmployerValuesFor(coverage),
        ...(formInitialValues ?? {}),
    } as FormikValues;

    const benefit = initialValues.benefit as BenefitType;
    if(!findPartyChoicesFor(benefit).includes(Party.EMPLOYER) && !claimId) {
        return <UseLinkError/>
    } else if(authLoading || (auth.userData && !formInitialValues)) {
        return <Spinner spinnerText="Loading"/>;
    }
    return <FormikStepper initialValues={initialValues}
                          onSubmit={submitHandler}>
        <FormikStep label={EmployerPages.START}>
            <Start/>
        </FormikStep>
        <FormikStep label={EmployerPages.EMPLOYER} hideStep={() => initialValues.policyNumber === undefined}
                    validationSchema={object({
                        policyNumber: validatePolicyNumber(),
                        unitNumber: validateUnitNumber(),
                    })}>
            <AboutEmployer/>
        </FormikStep>
        <FormikStep label={EmployerPages.EMPLOYEE} hideStep={() => initialValues.privacyId === undefined}
                    validationSchema={object({
                        privacyId: validatePrivacyId(),
                        firstName: requiredIfFieldNotPresent('privacyId', 'If you don\'t specify a Principal member ID, please specify your employee\'s first name.'),
                        lastName: requiredIfFieldNotPresent('privacyId', 'If you don\'t specify a Principal member ID, please specify your employee\'s last name.'),
                        dateOfBirth: requiredIfFieldNotPresent('privacyId', 'If you don\'t specify a Principal member ID, please specify your employee\'s date of birth.'),
                    })}
                    validate={(values) => addressValidator(values, "streetAddress", values.streetAddress, values.city, values.state, values.zipCode)}>
            <AboutEmployee/>
        </FormikStep>
        <FormikStep label={EmployerPages.RELATIONSHIP}
                    hideStep={() => findRelationshipsFor(initialValues.benefit).length < 2}>
            <AboutRelationship/>
        </FormikStep>
        <FormikStep label={EmployerPages.CONTACT} hideStep={() => initialValues.contactName === undefined}>
            <AboutContactPerson/>
        </FormikStep>
        <FormikStep label={EmployerPages.OCCUPATION} hideStep={() => initialValues.jobTitle === undefined}>
            <Occupation/>
        </FormikStep>
        <FormikStep label={EmployerPages.EMPLOYMENT}
                    hideStep={() => initialValues.dateOfHire === undefined}
                    validationSchema={object({
                        dateLastWorked: dateRangeValidator('dateOfHire'),
                        dateReturnedToWork: dateRangeValidator('dateLastWorked'),
                        datePremiumsPaidThrough: lessThanEqualToCurrentDateValidator()
                    })} validate={(values) => {
            if (values.workSchedule === '') {
                return {
                    workSchedule: 'Please specify at least one work schedule.'
                }
            }
            const firstWeeklyWorkSchedule = values.weeklyWorkSchedule[0];
            if ([WorkSchedule.REGULAR, WorkSchedule.ROTATING].includes(values.workSchedule)
                && [firstWeeklyWorkSchedule.sunday, firstWeeklyWorkSchedule.monday, firstWeeklyWorkSchedule.tuesday,
                    firstWeeklyWorkSchedule.wednesday, firstWeeklyWorkSchedule.thursday, firstWeeklyWorkSchedule.friday,
                    firstWeeklyWorkSchedule.saturday].every(item => !item)) {
                return {
                    weeklyWorkSchedule: 'Please specify at least one work schedule.'
                }
            }
        }}>
            <Employment/>
        </FormikStep>
        <FormikStep
            label={EmployerPages.CLAIM_TYPE}
            hideStep={() => initialValues.claimType === undefined}
            validationSchema={object({
                priorDisabilityDates: dateRangeArrayValidator(),
                priorPaidFamilyMedicalLeaveDates: dateRangeArrayValidator()
            })}>
            <EmployerClaimType/>
        </FormikStep>
        <FormikStep label={EmployerPages.CONDITION} hideStep={() => initialValues.workRelated === undefined}>
            <Condition/>
        </FormikStep>
        <FormikStep
            label={EmployerPages.FINANCIAL}
            hideStep={() => (initialValues.baseWage === undefined
                && initialValues.premiumTaxation === undefined
                && (initialValues as PfmlEmployerFormValues).earningsQuarters === undefined)}
            validationSchema={object({
                fullPayAfterDateLastWorkedEndDate: dateRangeValidator('dateLastWorked'),
                employerReimbursementConsent: employerReimbursementConsentValidator()
            })}>
            <Financial/>
        </FormikStep>
        <FormikStep
            label={EmployerPages.PAYMENTS}
            hideStep={(values) => (initialValues as PfmlEmployerFormValues).employerPaymentPreference === undefined
                || !values?.employerReimbursement || 'No' === values?.employerReimbursement }
            validationSchema={object({
                employerRoutingNumber: validRoutingNumber(Party.EMPLOYER),
                employerAccountNumber2: accountNumberValidator(Party.EMPLOYER),
                eftConsent: eftConsentValidator(Party.EMPLOYER)
            })}>
            <Payments/>
        </FormikStep>
        <FormikStep
            label={EmployerPages.DOCUMENT}
            hideStep={() => initialValues.documents === undefined}
            validationSchema={object({
                documents: validateDocumentContent(),
            })}
            validate={(values) => {
                return {
                    ...validateDocumentsTotalSize(values),
                    ...validateBeneficiaryDesignation(values)
                }
            }}>
            <EmployerDocument/>
        </FormikStep>
        <FormikStep label={EmployerPages.SUMMARY}>
            <EmployerSummary/>
        </FormikStep>
        <FormikStep label={EmployerPages.FINISH}>
            <EmployerFinished/>
        </FormikStep>
    </FormikStepper>
}

function findEmployerValuesFor(claimType: BenefitType) {
    if(BenefitType.PFML === claimType) {
        return defaultPfmlEmployerFormValues;
    } else if(BenefitType.DISABILITY === claimType) {
        return defaultDisabilityEmployerFormValues;
    } else if(BenefitType.HOSPITAL_INDEMNITY === claimType) {
        return defaultHospitalIndemnityEmployerFormValues;
    } else if(BenefitType.LIFE === claimType) {
        return defaultLifeEmployerFormValues;
    }
    return {};
}

export default Employer;