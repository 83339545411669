import { array, bool, date, MixedSchema, object, ref, string } from "yup";
import { FormikValues } from "formik";
import * as AddressService from "../service/AddressService";
import { PaymentPreference } from "../component/enums/PaymentPreference";
import axios from "axios";
import { UploadedDocument } from "../component/domain/UploadedDocument";
import {DateTime} from "luxon";
import { DocumentType } from "../component/enums/DocumentType";
import {BeneficiaryDesignationStatus} from "../component/enums/BeneficiaryDesignationStatus";
import { Party } from "../component/enums/Party";
import _ from "lodash";
import { findDocumentTypesFor, hasRequiredDocumentsFor } from "../component/enums/BenefitType";

export function requiredIfFieldNotPresent(field: string, message: string, schema: MixedSchema<any | undefined> = string()) {
    return schema.when(field, {
        is: (value) => value !== undefined && value.length > 0,
        then: schema.notRequired(),
        otherwise: schema.required(message)
    });
}

export async function addressValidator(values: FormikValues, streetAddressField: string, streetAddress: string, city: string, state: string, zipCode: string) {
    if (values.useEnteredAddress) {
        return;
    }
    const uspsAddress = await AddressService.validateAddress(streetAddress, city, state, zipCode);
    if (uspsAddress
        && (uspsAddress.streetAddress.toUpperCase() !== streetAddress.toUpperCase()
            || uspsAddress.city.toUpperCase() !== city.toUpperCase()
            || uspsAddress.state.toUpperCase() !== state.toUpperCase()
            || uspsAddress.zipCode.toUpperCase() !== zipCode.toUpperCase())) {
        return {
            [streetAddressField]: {
                hidden: true,
                message: uspsAddress
            }
        };
    }
}

export function dateRangeArrayValidator() {
    return array()
        .of(
            object().shape({
                endDate: dateRangeValidator('startDate')
            })
        );
}

export function dateRangeValidator(startDateField: string) {
    return date()
        .typeError("You must provide a valid date.")
        .when(
            startDateField,
            (startDate: any, schema: any) => {
                try {
                    return (startDate && schema
                        .min(startDate, "The end date must be greater than the start date."))
                } catch(e) {
                    return schema.typeError("You must provide a valid date.")
                }
            }
        )
}

export function lessThanEqualToCurrentDateValidator() {
    const currentDate = DateTime.now().toLocaleString();
    return date()
        .typeError("You must provide a valid date.")
        .max(currentDate, "The inputted date must be less than or equal to the current date.")
}

export function validRoutingNumber(party: Party) {
    return string().when(findFieldNameFor(party, 'paymentPreference'), {
        is: (value) => {
            return value !== undefined && value === PaymentPreference.EFT;
        },
        then: string().test(
            'valid-routing-number',
            'Please enter a valid routing number.',
            (async (value: any) => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_FORMS_SERVICE_URL}/routingNumber?routingNumber=${value}`)
                    return response.status === 204;
                } catch(e) {
                    return false;
                }
            }),
        )
    })
}

export function eftConsentValidator(party: Party) {
    return bool().when(findFieldNameFor(party, 'paymentPreference'), {
        is: (value) => {
            return value === PaymentPreference.EFT;
        },
        then: bool().oneOf([true], 'Please complete the eft consent.')
    })
}

export function employerReimbursementConsentValidator() {
    return bool().when('employerReimbursement', {
        is: (value) => {
            return value === 'Yes';
        },
        then: bool().oneOf([true], 'Please complete the employer reimbursement consent.')
    })
}

export function accountNumberValidator(party: Party) {
    return string().oneOf([ref(findFieldNameFor(party, 'accountNumber'))], 'The account numbers must match.');
}

export function validateDocumentContent() {
    return array().of(
        object().shape({
            documentContent: string()
                .test('type', 'File is not a PDF.', val => val?.startsWith('data:application/pdf') ?? true)
                .notRequired()
        })
    );
}

export function validateDocumentsTotalSize(values: FormikValues) {
    const documents = values.documents as UploadedDocument[];
    const totalLength = documents.reduce<number>((accumulator, current) => {
        return accumulator + current.documentContent.length;
    }, 0);
    if (totalLength > 4100000) {
        return {
            documents: 'Files are too large (total must be less than 3MB).'
        }
    }
    return {}
}

export function validateBeneficiaryDesignation(values: FormikValues) {
    const notProvidedBeneficiary = values.beneficiaryDesignationStatus === BeneficiaryDesignationStatus.UPLOADED
        && !values.documents.some((d: UploadedDocument) =>
            [DocumentType.BENEFICIARY_DESIGNATION, DocumentType.ENROLLMENT_FORM].includes(d.documentType as DocumentType) && d.documentContent)
    if (notProvidedBeneficiary) {
        return {
            beneficiaryDesignationStatus: "You must either provide a beneficiary designation or state that you don't have one."
        }
    }
    return {}
}

export function validatePolicyNumber() {
    return validateStringLength('policy-number-length', 'Principal account ID must be 7 digits.', 7);
}

export function validateUnitNumber() {
    return validateStringLength('unit-number-length', 'Unit number must be 5 digits.', 5);
}

export function validatePrivacyId() {
    const errorMessage = 'Principal member ID must be 9 digits and start with a 9.';
    return validateStringLength('policy-number-length', errorMessage, 9)
        .test('policy-number-starts-with-9', errorMessage, val => !val || val.length === 0 || val.startsWith('9'));
}

export function validateStringLength(testName: string, errorMessage: string, length: number) {
    return string().test(testName, errorMessage, val => !val || val.length === 0 || val.length === length);
}

export function validateFieldNotEqualTo(fieldToCompare: string, errorMessage: string) {
    return string().when(fieldToCompare, (value: any, schema: any) => {
        return (value && schema.notOneOf([value], errorMessage))
    })
}

export function validateTwoFieldsNotEqualTo(fieldOneToCompare: string, fieldTwoToCompare: string, errorMessage: string) {
    return string().when([fieldOneToCompare, fieldTwoToCompare], (valueOneToCompare: any, valueTwoToCompare: any, schema: any) => {
        const value = valueOneToCompare + ` ${valueTwoToCompare}`;
        return (value && schema.notOneOf([value], errorMessage))
    })
}

export function validateHasRequiredDocuments(values: FormikValues) {
    const benefit = values.benefit;
    const notProvidedDocument = hasRequiredDocumentsFor(benefit) && !values.documents.some((d: UploadedDocument) =>
            findDocumentTypesFor(benefit).includes(d.documentType as DocumentType) && d.documentContent)
    if (notProvidedDocument) {
        return {
            documents: "You must provide at least one required document."
        }
    }
    return {}
}

function findFieldNameFor(party: Party, fieldName: string) {
    return _.camelCase(Party.EMPLOYER === party ? `employer-${fieldName}` : fieldName);
}
