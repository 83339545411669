import { defaultEmployerFormValues, EmployerFormValues } from "../common/EmployerFormValues";
import { defaultDisabilityFormValues, DisabilityFormValues } from "./DisabilityFormValues";

export type DisabilityEmployerFormValues = EmployerFormValues & DisabilityFormValues & {
    requiredSittingFrequency: string,
    requiredStandingFrequency: string,
    requiredWalkingFrequency: string,
    requiredKeyboardingFrequency: string,
    requiredFineManipulationFrequency: string,
    requiredLiftingContinuouslyPounds: string,
    requiredLiftingFrequentlyPounds: string,
    requiredLiftingOccasionallyPounds: string,
    normalScheduleOnDateLastWorked: boolean,
    hoursWorkedOnDateLastWorked: string,
    normalScheduleOnReturnedToWork: boolean,
    product: string,
    employeeContributionPercentage: string,
    premiumTaxation: string,
    employeeOwnership: boolean,
    employeeOwnershipPercentage: string,
    bonusesOrCommissions: boolean,
    payTypesAfterDateLastWorked: [{
        payType: string,
        endDate: string
    }]
}

export const defaultDisabilityEmployerFormValues = {
    ...defaultEmployerFormValues,
    ...defaultDisabilityFormValues,
    requiredSittingFrequency: '',
    requiredStandingFrequency: '',
    requiredWalkingFrequency: '',
    requiredKeyboardingFrequency: '',
    requiredFineManipulationFrequency: '',
    requiredLiftingContinuouslyPounds: '',
    requiredLiftingFrequentlyPounds: '',
    requiredLiftingOccasionallyPounds: '',
    normalScheduleOnDateLastWorked: true,
    hoursWorkedOnDateLastWorked: '',
    normalScheduleOnReturnedToWork: false,
    product: '',
    employeeContributionPercentage: '',
    premiumTaxation: '',
    employeeOwnership: false,
    employeeOwnershipPercentage: '',
    bonusesOrCommissions: false,
    payTypesAfterDateLastWorked: [{
        payType: '',
        endDate: ''
    }]
}