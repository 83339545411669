import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import { EmployerFormValues } from "../../common/EmployerFormValues";
import OccupationRequirements from "../../structure/OccupationRequirements";

export type OccupationProps = {
    initialValues?: EmployerFormValues
}

const Occupation = (props: OccupationProps) => {
    const initialValues = props.initialValues;
    return (
        <QuestionPanel headerText="Please describe your employee's occupation."
                       panelContent={
                           <div>
                               <TextInput id="job-title" label="Employee's job title" required={true} maxLength={320}/>
                               <OccupationRequirements label={initialValues && Object.keys(initialValues).includes("requiredSittingFrequency")
                                   ? "In a typical work day, your employee is required to:" : undefined} idPrefix="required" required={true}/>
                           </div>}
        >
        </QuestionPanel>
    )
};

export default Occupation;
