import React, { MutableRefObject, ReactElement, useEffect, useRef } from 'react';
import Inputmask, { Options } from 'inputmask'
import { Field, FieldHelperProps, FieldMetaProps, FieldValidator, FormikErrors, useField } from "formik";
import { FieldProps } from "formik/dist/Field";
import { PdsTextInput } from "@principal/design-system-react";

var _ = require('lodash');

type TextInputProps = {
    id: string,
    name?: string,
    label?: string,
    required: boolean,
    inputComponent?: React.FunctionComponent<React.ComponentProps<'input'>>,
    inputProps?: any
    labelContent?: ReactElement,
    leftContent?: ReactElement,
    rightContent?: ReactElement,
    inputMask?: Options,
    maxLength?: number,
    changeCallback?: Function,
    validate?: FieldValidator,
    children?: React.ReactNode
}

const TextInput = (props: TextInputProps) => {
    const name = props.name ? props.name : _.camelCase(props.id);
    const [, meta, helpers] = useField({
        name: name,
        validate: validateInput
    });
    const inputRef = useRef(null as any as HTMLElement)
    const mask = props.inputMask

    useEffect(() => {
        if(!mask || !inputRef.current) {
            return;
        }
        const timer = setInterval(() => {
            if(inputMask(inputRef.current, mask)) {
                clearInterval(timer)
                return;
            }
        }, 200);
        return () => clearInterval(timer);
    }, [inputRef, mask]);

    const fieldRemoved = meta.initialValue === undefined;
    return (fieldRemoved ? null :
        <div className={"pds-u-margin-bottom-16"}>
            {createInput(props, name, meta, helpers, inputRef)}
        </div>
    )

    function validateInput(value: string) {
        if(fieldRemoved || (!props.required && !value)) {
            return '';
        } else if(props.required && !value) {
            return 'Please fill out this field.'
        }
        return props.validate?.(value)
    }
};

function createInput(props: TextInputProps, name: any, meta: FieldMetaProps<any>, helpers: FieldHelperProps<any>, inputRef: MutableRefObject<any>) {
    return <Field component={Input} {...props} name={name} meta={meta} helpers={helpers} inputRef={inputRef}/>
}

const Input = ({ field, form, meta, helpers, inputRef, ...props }:
                   FieldProps & TextInputProps & {meta: FieldMetaProps<any>, helpers: FieldHelperProps<any>, inputRef: MutableRefObject<any>}) => {
    const error = meta.error
    const Component = props.inputComponent ?? PdsTextInput;
    return <Component {...field}
                      type="text"
                      id={props.id}
                      fieldId={props.id}
                      label={props.label}
                      required={props.required}
                      maxLength={props.maxLength}
                      errorMessage={(error && !(error as unknown as FormikErrors<any>).hidden) ? error as string : undefined}
                      ref={inputRef}
                      {...props.inputProps}
                      onChange={(event) => {
                          helpers.setError(undefined)
                          if (field) {
                              field.onChange(event);
                          }
                          if (props.changeCallback) {
                              props.changeCallback(event.target);
                          }
                      }}>
        {props.leftContent && <span slot="prefix">{props.leftContent}</span>}
        <label slot="label" data-field-name={field.name} data-field-label={props.label} htmlFor={props.id}>
            {props.label}{props.labelContent}
        </label>
        {props.rightContent && <span slot="suffix">{props.rightContent}</span>}
        {props.inputProps?.children}
    </Component>;
};

function inputMask(domInput: HTMLElement | null, maskOptions?: Options) {
    if(!domInput || !maskOptions) {
        return;
    }
    const shadowInput = domInput?.shadowRoot?.querySelector("input");
    if(shadowInput && !shadowInput.inputmask) {
        Inputmask(maskOptions).mask(shadowInput);
        (shadowInput.inputmask as any).shadowRoot = domInput.shadowRoot
    }
    return !!shadowInput?.inputmask
}
export default TextInput;