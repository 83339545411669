import React from 'react';
import { buildArrayIdFor, buildArrayNameFor, ListElementProps } from "../button/AddListElementButton";
import SelectDropdown from "./SelectDropdown";
import CloseButton from "../button/CloseButton";
import { LifeBenefitType } from "../enums/LifeBenefitType";
import MoneyInput from "./MoneyInput";

const LifeBenefit = (props: ListElementProps) => {
    return <>
        { props.closeCallback && <CloseButton idPrefix="" index={props.index} closeCallback={props.closeCallback}/> }
        <SelectDropdown id={buildArrayIdFor("claimed", props)} name={buildArrayNameFor("claimed", props)} label="Benefit claimed" required={true}
                        options={Object.keys(LifeBenefitType).map(benefit => ({
                            id: benefit.replace(/_/g, '-').toLowerCase(),
                            text: (LifeBenefitType as any)[benefit]
                        }))}/>
        <MoneyInput id={buildArrayIdFor("amount", props)} name={buildArrayNameFor("amount", props)} label="Amount" required={true}/>
    </>
}

export default LifeBenefit;