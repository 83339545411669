import React, { ReactElement, ReactNode } from 'react';

export type PanelProps = {
    headerText?: ReactNode,
    panelContent?: ReactElement,
    children?: React.ReactNode
}

const Panel = (props: PanelProps) => {
    return (
        <div>
            <div>
                <h3 className="pds-u-padding-bottom-8">{props.headerText}</h3>
            </div>
            {props.panelContent}
        </div>
    )
};
export default Panel;