import React from 'react';
import { PdsIconLoader } from "@principal/design-system-icons-react";

export type SpinnerProps = {
    spinnerText?: string,
    children?: React.ReactNode
}

const Spinner = (props: SpinnerProps) => {
    return (
        <div><PdsIconLoader className="fa-spin pds-u-inline-block"/> {props.spinnerText}</div>
    )
};
export default Spinner;