import React, { ReactElement } from 'react';
import { PdsGrid, PdsGridItem } from "@principal/design-system-react";

type TwoColumnDivProps = {
    leftContent?: string | ReactElement | false,
    rightContent?: string | ReactElement | false,
    children?: React.ReactNode
}

const TwoColumnDiv = (props: TwoColumnDivProps) => {
    return (
        <PdsGrid variant="2up">
            <PdsGridItem id="left-div">
                {props.leftContent}
            </PdsGridItem>
            <PdsGridItem id="right-div">
                {props.rightContent}
            </PdsGridItem>
        </PdsGrid>
    )
};
export default TwoColumnDiv;