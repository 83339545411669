import React from 'react';
import { buildArrayIdFor, buildArrayNameFor, ListElementProps } from "../button/AddListElementButton";
import CloseButton from "../button/CloseButton";
import TextInput from "./TextInput";
import SelectDropdown from "./SelectDropdown";
import DecimalInput from "./DecimalInput";
import { DosageUnit } from "../enums/DosageUnit";
import { PdsGrid, PdsGridItem } from "@principal/design-system-react";

const MedicationSelection = (props: ListElementProps) => {
    return (
        <div>
            {props.closeCallback && <CloseButton idPrefix="" index={props.index} closeCallback={props.closeCallback}/>}
            {buildMedicationQuestions()}
        </div>
    )

    function buildMedicationQuestions() {
        return <>
                <TextInput id={buildArrayIdFor("medication", props)}
                           name={buildArrayNameFor("medication", props)}
                           label="Generic or brand name"
                           required={true}/>
                <PdsGrid id={buildArrayIdFor("dosage-row", props)} variant="3up">
                    <PdsGridItem>
                        <DecimalInput id={buildArrayIdFor("dosage", props)}
                                      name={buildArrayNameFor("dosage", props)}
                                      label="Dose"
                                      required={true}/>
                    </PdsGridItem>
                    <PdsGridItem>
                        <SelectDropdown id={buildArrayIdFor("dosage-unit", props)}
                                        name={buildArrayNameFor("dosageUnit", props)}
                                        label="Unit"
                                        required={true}
                                        options={Object.keys(DosageUnit).map(p => ({
                                            id: p.replace(/_/g, '-').toLowerCase(),
                                            text: (DosageUnit as any)[p]
                                        }))}/>
                    </PdsGridItem>
                    <PdsGridItem>
                        <TextInput id={buildArrayIdFor("dosage-frequency", props)}
                                   name={buildArrayNameFor("dosageFrequency", props)}
                                   label="Frequency"
                                   required={true}/>
                    </PdsGridItem>
                </PdsGrid>
            </>
    }
};

export default MedicationSelection;