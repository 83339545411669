import React from 'react';
import NumericInput from "../input/NumericInput";
import Tooltip from "../input/Tooltip";
import SelectDropdown from "../input/SelectDropdown";
import { Frequency } from "../enums/Frequency";
import HorizontalRule from "./HorizontalRule";

export type OccupationRequirementsProps = {
    label?: string,
    idPrefix: string,
    required: boolean,
}

const OccupationRequirements = (props: OccupationRequirementsProps) => {
    const frequencyOptions = Object.keys(Frequency).map(p => ({
        id: p.replace(/_/g, '-').toLowerCase(),
        text: (Frequency as any)[p]
    }))

    const frequencyTooltip = <Tooltip text={<><b>Continuously</b> is defined as 6-8 hours in an 8-hour day or 60 times per hour.<br/>
<b>Frequently</b> is defined as 3-6 hours in an 8-hour day or up to 12-60 times per hour.<br/>
<b>Occasionally</b> is defined as up to 3 hours in an 8-hour day or 1-12 times per hour.</>}/>

    const idPrefix = props.idPrefix;
    const required = props.required;
    return <div>
        {props.label && <>
            <HorizontalRule/>
            <label>{props.label}</label>
        </>}
        <SelectDropdown id={`${idPrefix}-sitting-frequency`} label="Sit" required={required} options={frequencyOptions} labelContent={frequencyTooltip}/>
        <SelectDropdown id={`${idPrefix}-standing-frequency`} label="Stand" required={required} options={frequencyOptions} labelContent={frequencyTooltip}/>
        <SelectDropdown id={`${idPrefix}-walking-frequency`} label="Walk" required={required} options={frequencyOptions} labelContent={frequencyTooltip}/>
        <SelectDropdown id={`${idPrefix}-keyboarding-frequency`} label="Keyboarding" required={required} options={frequencyOptions} labelContent={frequencyTooltip}/>
        <SelectDropdown id={`${idPrefix}-fine-manipulation-frequency`} label="Fine manipulation" required={required} options={frequencyOptions} labelContent={frequencyTooltip}/>
        <NumericInput id={`${idPrefix}-lifting-continuously-pounds`} label="Lift continuously (pounds)" required={required} maxLength={4} labelContent={frequencyTooltip}/>
        <NumericInput id={`${idPrefix}-lifting-frequently-pounds`} label="Lift frequently (pounds)" required={required} maxLength={4} labelContent={frequencyTooltip}/>
        <NumericInput id={`${idPrefix}-lifting-occasionally-pounds`} label="Lift occasionally (pounds)" required={required} maxLength={4} labelContent={frequencyTooltip}/>
    </div>
};

export default OccupationRequirements;
