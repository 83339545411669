import React, { useEffect } from 'react';
import { useField } from "formik";
import Spinner, { SpinnerProps } from "./Spinner";

const LoadingSpinner = (props: SpinnerProps) => {
    const [, , {setError}] = useField({
        name: 'loading',
        validate: validateInput
    });

    useEffect(() => {
        return () => setError('')
    }, [setError])

    function validateInput(value: boolean) {
        return value ? 'Please wait, the form is loading...' : '';
    }

    return (
        <Spinner {...props}></Spinner>
    )
};
export default LoadingSpinner;