import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import SelectDropdown from "../../input/SelectDropdown";
import NumericInput from "../../input/NumericInput";
import EftModal from "../../structure/EftModal";
import { PaymentPreference } from "../../enums/PaymentPreference";
import CheckGraphic from "../../../images/CheckGraphic.jpg"
import { FormikErrors } from "formik";
import { EmployeeFormValues } from "../../common/EmployeeFormValues";
import { BenefitType } from "../../enums/BenefitType";
import { BankAccountType } from "../../enums/BankAccountType";
import { PfmlEmployerFormValues } from "../../pfml/PfmlEmployerFormValues";
import { Party } from "../../enums/Party";
import _ from "lodash";

export type PaymentsProps = {
    values?: EmployeeFormValues | PfmlEmployerFormValues,
    errors?: FormikErrors<any>,
    submitForm?: Function,
    setFieldValue?: Function
}

const resetAccountFields = (setFieldValue: Function, party: Party) => {
    const accountNumberFields = ['accountNumber', 'accountNumber2', 'routingNumber', 'accountType'];
    for(const field of accountNumberFields) {
        const fieldToSet = Party.EMPLOYER === party ? `employer-${field}` : field;
        setFieldValue(_.camelCase(fieldToSet), '');
    }
}

const Payments = (props: PaymentsProps) => {
    const onPaymentPreferenceChange = function (target: HTMLSelectElement) {
        if(target.value !== PaymentPreference.EFT && props.setFieldValue) {
            resetAccountFields(props.setFieldValue, props.values?.party as Party);
        }
    }

    const isEmployer = props.values?.party === Party.EMPLOYER;
    const paymentPreference = isEmployer ? (props.values as PfmlEmployerFormValues)?.employerPaymentPreference : (props.values as EmployeeFormValues)?.paymentPreference;
    const isEft = paymentPreference === PaymentPreference.EFT;
    const isCheck = paymentPreference === PaymentPreference.CHECK;
    const benefit = props.values?.benefit as BenefitType;
    const isDisability = benefit === BenefitType.DISABILITY;
    const isWellness = benefit === BenefitType.WELLNESS;
    const employerPrefix = isEmployer ? `${Party.EMPLOYER}-` : '';
    const mailingAddressMessage = isEmployer ? 'address we have on file for you' : 'residency address you provided on this form'
    return (
        <>
            <EftModal {...props}/>
            <QuestionPanel headerText="How do you prefer to be paid?"
                           detailsText={
                               <>
                                   We can deposit funds directly in your bank account (faster) or send you a check.
                                   {isCheck &&
                                       <div>
                                           <br/>
                                           {`We will mail your check to the ${mailingAddressMessage}.`}
                                       </div>
                                   }
                                   {isEft &&
                                       <div>
                                           <br/>
                                           You can find your routing number and account number at the bottom of your check, or from your bank's website.
                                           <br/><br/>
                                           {isDisability
                                               && <>Some disability policies do not currently support electronic funds transfer. If you are a member of one of these policies, you will receive your payment(s) by check.<br/><br/></>}
                                           <img src={CheckGraphic} alt="Check graphic" style={{maxWidth: "100%", maxHeight: "100%"}} />
                                       </div>
                                   }
                                   {isWellness &&
                                       <div><br/>
                                           If you are eligible for multiple payments under multiple products or with multiple patients, you will
                                           receive multiple checks or electronic deposits into your account.
                                       </div>
                                   }
                               </>
                           }
                           panelContent={
                                   <div>
                                       <SelectDropdown id={`${employerPrefix}payment-preference`} label="Payment preference" required={true}
                                                   options={[{id: "eft", text: PaymentPreference.EFT},
                                                       {id: "check", text: PaymentPreference.CHECK}]}
                                                   changeCallback={onPaymentPreferenceChange}/>
                                       {isEft
                                           && <div>
                                               <NumericInput id={`${employerPrefix}account-number`} label="Account number" required={true} maxLength={17}/>
                                               <NumericInput id={`${employerPrefix}account-number-2`} label="Re-type account number" required={true}
                                                             maxLength={17}/>
                                               <NumericInput id={`${employerPrefix}routing-number`} label="Routing number" required={true} maxLength={9}/>
                                               <SelectDropdown id={`${employerPrefix}account-type`} label="Account type" required={true}
                                                               options={[{ id: "checking", text: BankAccountType.CHECKING },
                                                                   { id: "savings", text: BankAccountType.SAVINGS }]}/>
                                           </div>}
                                   </div>}
            >
            </QuestionPanel>
        </>
    )
};
export default Payments;