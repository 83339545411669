import React from 'react';
import Panel, { PanelProps } from "./Panel";
import { PdsCard, PdsSidebar } from "@principal/design-system-react";

type QuestionPanelProps = PanelProps & {
    detailsText?: string | React.ReactElement | false,
    footnotes?: string[]
}

const QuestionPanel = (props: QuestionPanelProps) => {
    const footnoteElements = [];
    const footnotes = props.footnotes;
    if(footnotes) {
        for(let i = 0; i < footnotes.length; ++i) {
            footnoteElements.push(<small><sup>{i + 1} </sup>{footnotes[i]}</small>)
        }
    }
    return (
        <Panel headerText={props.headerText}
               panelContent={
                   <PdsSidebar gap="none">
                       <div className="pds-u-padding-right-16" style={{borderRight: '1px solid #e6e6e6'}}>
                           {props.panelContent}
                       </div>
                       <div slot="right-sidebar" className="pds-u-padding-left-16">
                           {
                               props.detailsText &&
                               <div>
                                   <PdsCard className="pds-u-margin-bottom-8">
                                       {props.detailsText}
                                   </PdsCard>
                               </div>
                           }
                           <span className="pds-u-typography-color-error">*</span><small> Required field</small>
                           <div>
                               {footnoteElements}
                           </div>
                       </div>
                   </PdsSidebar>}
        >
        </Panel>
    )
};
export default QuestionPanel;