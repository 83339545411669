import React, { ReactElement, useEffect, useRef } from 'react';
import { Form, Formik, FormikConfig, FormikErrors, FormikProps, FormikValues } from "formik";
import { FormikStepProps } from "./FormikStep";
import AlertBanner from "../structure/AlertBanner";
import Breadcrumbs from "./Breadcrumbs";
import PreviousNext from "./PreviousNext";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { FormikHelpers } from "formik/dist/types";
import { addPageView } from "./PageViewMetric";
import { object, ObjectSchema } from "yup";
import { useAuth } from "oidc-react";

interface FormikStepperProps extends Pick<FormikConfig<FormikValues>, 'initialValues'> {
    onSubmit: (initialValues: FormikValues, values: FormikValues, helpers: FormikHelpers<FormikValues>, navigate: NavigateFunction) => any;
    children?: React.ReactNode
}

let formikAvailable = false;

export function isFormikAvailable() {
    return formikAvailable;
}

export function FormikStepper(props: FormikStepperProps) {
    const initialChildrenArray = React.useMemo(() => React.Children.toArray(props.children) as React.ReactElement<FormikStepProps>[], [props.children]);
    const [childrenArray, setChildrenArray] = React.useState(initialChildrenArray);
    const [step, setStep] = React.useState(0);
    const currentChild = childrenArray[step];
    const navigate = useNavigate();
    const formikRef = useRef(null) as React.RefObject<FormikProps<any>>;
    const auth = useAuth();
    const userData = auth.userData;
    const signedIn = !!userData;

    useEffect(
        () => {
            setActiveStep(0)
            formikAvailable = true;
            return () => {
                formikAvailable = false;
            };
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEffect(() => {
        const values = formikRef.current?.values
        const filteredChildrenArray = initialChildrenArray.filter((child: ReactElement) => !child.props.hideStep?.(values));
        setChildrenArray(filteredChildrenArray)
    }, [initialChildrenArray, formikRef.current?.initialValues, formikRef.current?.values])

    function findStepLabelFor(childrenArray: React.ReactElement<FormikStepProps>[], stepIndex: number) {
        return childrenArray[stepIndex].props.label;
    }

    function setActiveStep(stepIndex: number) {
        setStep(stepIndex);
        formikRef.current?.setFieldValue('currentStep', findStepLabelFor(childrenArray, stepIndex));
        putStepIndexMetric(props, stepIndex);
        formikRef.current?.setErrors({});
    }

    function isSecondToLastStep() {
        return step === childrenArray.length - 2;
    }

    function isLastStep() {
        return step === childrenArray.length - 1;
    }

    function putStepIndexMetric(props: FormikStepperProps, stepIndex: number) {
        addPageView(findStepLabelFor(childrenArray, stepIndex))
    }

    function removeUninitializedFieldsFromValidationFor(validationSchema: ObjectSchema) {
        let newValidationSchema;
        if(validationSchema) {
            const fields = validationSchema.fields!! as any;
            const initialValueKeys = Object.keys(props.initialValues);
            const filteredFields = Object.keys(fields)
                .filter(key => initialValueKeys.includes(key))
                .reduce((obj, key) => {
                    return {
                        ...obj,
                        [key]: fields[key]
                    };
                }, {});
            newValidationSchema = object(filteredFields);
        }
        return newValidationSchema;
    }

    return (
        <Formik {...props}
                enableReinitialize={!signedIn}
                validateOnChange={false}
                validateOnBlur={false}
                validate={currentChild.props.validate}
                validationSchema={removeUninitializedFieldsFromValidationFor(currentChild.props.validationSchema)}
                onSubmit={async (values, helpers) => {
                    if (isSecondToLastStep()) {
                        await props.onSubmit(formikRef?.current?.initialValues, values, helpers, navigate);
                    }
                    if (!isLastStep()) {
                        setActiveStep(step + 1);
                    }
                }}
                innerRef={formikRef}>
            {(props: FormikProps<FormikValues>) => {
                const map = new Map(Object.entries(props.errors));
                let errorArray :ReactElement[] = [];
                map.forEach((errors) => {
                    if (errors && !((errors as FormikErrors<any>).hidden)) {
                        const errorsArray = errors instanceof Array ? errors : [{ error: errors }];
                        for(const errors of errorsArray) {
                            if(!errors) {
                                continue;
                            }
                            for (const error of Object.values(errors)) {
                                errorArray.push(<AlertBanner message={'' + error}/>);
                            }
                        }
                    }
                });
                const showBreadcrumbsAndBackButton = step > 0 && !isLastStep();
                return <Form autoComplete="off">
                    { showBreadcrumbsAndBackButton && <Breadcrumbs steps={childrenArray} currentStep={step} stepChangeCallback={setActiveStep}/> }
                    { errorArray }
                    { React.cloneElement(currentChild, { steps: childrenArray, stepChangeCallback: setActiveStep, ...props }) }
                    <PreviousNext previous={showBreadcrumbsAndBackButton ? () => setActiveStep(step - 1) : undefined}
                                  next={step < childrenArray.length - 2 ? () => {} : undefined}
                                  {...props}
                    />
                </Form>
            }}
        </Formik>
    );
}