import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthCookies } from "../enums/AuthCookies";
import { BenefitType, findFullNameFor } from "../enums/BenefitType";
import { useCookies } from "react-cookie";
import { useAuth } from "oidc-react";
import { getSessionDetails, parseCookies } from '@pds/sessionTimer/sessionTimer.min'
import { DateTime } from "luxon";
import {
    PdsHeading, PdsLayoutContainer,
    PdsPrimaryNavigation,
} from '@principal/design-system-react';
import { GlobalHeader } from "@principal/individual-header-component";
import { Environment } from "@principal/aws-types";
import { findEnterpriseIdFor } from "../../service/EnterpriseIdService";

const _ = require('lodash');

const Header = () => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [, setCookie, ] = useCookies([AuthCookies.REDIRECT_URI]);

    const pathname = location.pathname;
    const claimType = Object.values(BenefitType).find(c => pathname.includes(c));
    if(!['/', '/error'].includes(pathname) && !claimType) {
        navigate('/')
    }
    const navigationPage = claimType ? `${titleCase(findFullNameFor(claimType))} / ` : "";
    const pageHeader = `${navigationPage}Claim Submission`;
    const user = auth?.userData;
    const signedIn = !!user;
    const authLoading = auth?.isLoading;
    const hasLogin = !pathname.includes("physician");
    const hasEnterpriseHeader = pathname.includes('employee') && signedIn;

    const authHandler = handleAuth();

    useEffect(() => {
        const updateSession = async () => {
            const notExpired = await isSessionNotExpired();
            if (notExpired) {
                authHandler()
            }
        };
        const isSessionNotExpired = async (): Promise<boolean> => {
            const expirationCookie = parseCookies(document.cookie);
            const sessionDetails = getSessionDetails(expirationCookie);
            const systemTime = sessionDetails?.systemTime;
            if(!systemTime || typeof sessionDetails.systemTime !== 'number') {
                return false;
            }
            const systemTimeMS = sessionDetails.systemTime * 1000;
            const TEN_MINUTES_IN_MS = 600000;
            return systemTimeMS + TEN_MINUTES_IN_MS > DateTime.now().toMillis();
        };
        if(!signedIn && !authLoading) {
            updateSession();
        }
    }, [signedIn, authLoading, authHandler]);

    return (
        <>
            {hasEnterpriseHeader
                ? <GlobalHeader eid={findEnterpriseIdFor(user)}
                                env={process.env.REACT_APP_ENVIRONMENT === 'prod' ? Environment.Prod : Environment.Staging}
                                getAccessToken={() => user?.access_token}
                                logger={console}/>
                : <PdsPrimaryNavigation variant="inverted"
                                        logoHref="#"
                                        loginLink={hasLogin ? (signedIn ? "logout" : "login") : "none"}
                                        loginLinkVariant="primary-inverted"
                                        loginLinkHref="#"
                                        layoutContainerVariant="default"
                                        layoutContainerRemovePadding="md"
                                        onHandleClick={authHandler}/>}
            <PdsLayoutContainer className="pds-u-padding-top-20">
                <PdsHeading headingTag="h2">{pageHeader}</PdsHeading>
            </PdsLayoutContainer>
        </>
    )

    function handleAuth() {
        return () => {
            const location = window.location;
            const href = location.href;
            const redirectUri = href.substring(location.origin.length, href.length);
            setCookie(AuthCookies.REDIRECT_URI, signedIn ? '' : redirectUri)
            if (signedIn) {
                auth.signOut();
            } else {
                auth.signIn();
            }
        };
    }
};

function titleCase(value: string | undefined) {
    return _.startCase(_.toLower(value)).replace(' Or ', ' or ').replace(' And ', ' and ');
}

export default Header;