import { defaultEmployeeFormValues, EmployeeFormValues } from "../common/EmployeeFormValues";
import { defaultPfmlFormValues, PfmlFormValues } from "./PfmlFormValues";
import _ from "lodash";

export type PfmlEmployeeFormValues = Omit<EmployeeFormValues, 'workRelated'> & PfmlFormValues & {
    childRelationship: string,
    militaryLeaveReason: string,
    militaryRelationship: string,
    leaveTimeframe: string,
    claimDates: [{
        startDate: string,
        endDate: string
    }],
}

export const defaultPfmlEmployeeFormValues = {
    ..._.omit(defaultEmployeeFormValues, ['workRelated']),
    ...defaultPfmlFormValues,
    childRelationship: '',
    militaryLeaveReason: '',
    militaryRelationship: '',
    leaveTimeframe: '',
    claimDates: [{
        startDate: '',
        endDate: ''
    }],
}