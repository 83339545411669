import React from 'react';
import "@principal/design-system/pds-styles.min.css";
import Header from "./component/navigation/Header";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import './App.css';
import PageViewMetric from "./component/navigation/PageViewMetric";
import { AuthProvider, UserManager } from "oidc-react";
import { useCookies } from "react-cookie";
import { AuthCookies } from "./component/enums/AuthCookies";
import Employee from "./component/common/Employee";
import Employer from "./component/common/Employer";
import Physician from "./component/common/Physician";
import PartyChoice from "./component/page/PartyChoice";
import ProductChoice from "./component/ProductChoice";
import Beneficiary from "./component/common/Beneficiary";
import { PdsErrorPage, PdsFooter, PdsLayoutContainer } from "@principal/design-system-react";
import axiosRetry from "axios-retry";
import axios from "axios";

function App() {
    const [cookies, ,] = useCookies([AuthCookies.REDIRECT_URI])

    axiosRetry(axios, {
        retries: 10,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (error) => {
            if (!error.code || error.response || (error.config?.method === 'post')) {
                return false
            }
            return ['ECONNABORTED', 'ERR_CANCELED', 'ECONNRESET', 'ERR_NETWORK', 'ETIMEDOUT', 'ECONNREFUSED'].includes(error.code)
        },
        shouldResetTimeout: true
    })

    return (
        <AuthProvider userManager={buildUserManager()}
                      autoSignIn={false}
                      onSignIn={() => {
                          window.location.href = `${window.location.origin}${cookies[AuthCookies.REDIRECT_URI]}`
                      }}>
            <HashRouter>
                <div>
                    <Header/>
                    <PdsLayoutContainer>
                        <Routes>
                            {process.env.REACT_APP_MAINTENANCE_TOGGLE === 'true' && <Route path={'/'} element={<PdsErrorPage errorCode="503"/>}/>}
                            <Route path={'/error'} element={<PdsErrorPage/>}/>
                            <Route path={'/:coverage/:claimId?/employee'} element={<Employee/>}/>
                            <Route path={'/:coverage/:claimId?/employer'} element={<Employer/>}/>
                            <Route path={'/:coverage/:claimId?/physician'} element={<Physician/>}/>
                            <Route path={'/:coverage/:claimId?/beneficiary'} element={<Beneficiary/>}/>
                            <Route path={'/:coverage'} element={<PartyChoice/>}/>
                            <Route path={'/'} element={<ProductChoice/>}/>
                        </Routes>
                    </PdsLayoutContainer>
                    <PdsFooter className="pds-u-margin-top-20"/>
                </div>
                <PageViewMetric/>
            </HashRouter>
            <BrowserRouter>
                <Routes>
                    <Route path="/redirect" element={<></>}/>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export function buildUserManager() {
    return new UserManager({
        authority: process.env.REACT_APP_AUTHORITY as string,
        client_id: process.env.REACT_APP_CLIENT_ID as string,
        redirect_uri: process.env.REACT_APP_FORMS_REDIRECT_URL as string,
        scope: "openid profile api://ebs/individual-experience/header.read",
        response_type: "code",
        metadata: {
            issuer: process.env.REACT_APP_AUTHORITY,
            authorization_endpoint: `${process.env.REACT_APP_AUTHORITY}/v1/authorize`,
            userinfo_endpoint: `${process.env.REACT_APP_FORMS_SERVICE_URL}/cors?url=${process.env.REACT_APP_AUTHORITY}/v1/userinfo`,
            end_session_endpoint: `${process.env.REACT_APP_FORMS_SERVICE_URL}/cors?url=${process.env.REACT_APP_AUTHORITY}/v1/logout`,
            jwks_uri: `${process.env.REACT_APP_FORMS_SERVICE_URL}/cors?url=${process.env.REACT_APP_AUTHORITY}/v1/keys`,
            token_endpoint: `${process.env.REACT_APP_FORMS_SERVICE_URL}/cors?url=${process.env.REACT_APP_AUTHORITY}/v1/token`
        }
    });
}

export default App;
