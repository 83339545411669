import React from 'react';
import ClaimType from "../common/ClaimType";
import DateRange from "../../input/DateRange";
import {findClaimDateFor} from "../../enums/BenefitType";
import {LifeBeneficiaryFormValues} from "../../life/LifeBeneficiaryFormValues";
import SelectDropdown from "../../input/SelectDropdown";
import {findPossessiveFor, PartOfSpeech} from "../../common/PersonalLanguage";
import {BeneficiaryMakingClaimTo} from "../../enums/BeneficiaryMakingClaimTo";

export type BeneficiaryClaimTypeProps = {
    values?: LifeBeneficiaryFormValues,
    setFieldValue?: Function
}

const BeneficiaryClaimType = (props: BeneficiaryClaimTypeProps) => {
    const values = props.values;

    return (
        <>
            <ClaimType headerText="What type of claim is being requested?"
                       {...props}>
                <SelectDropdown
                    id="making-claim-to"
                    label={`What will ${findPossessiveFor(values, true, true, PartOfSpeech.PRONOUN)} be making claim to?`}
                    required={true}
                    options={Object.keys(BeneficiaryMakingClaimTo).map(b => ({
                        id: b.replace(/_/g, '-').toLowerCase(),
                        text: (BeneficiaryMakingClaimTo as any)[b]
                    }))}/>
                <DateRange idPrefix="claim-dates" index={0} startLabel={findClaimDateFor(props.values?.benefit).startDateLabel} startRequired={false}/>
            </ClaimType>
        </>
    )
};
export default BeneficiaryClaimType;