import React from 'react';
import NextButton from "../button/NextButton";
import PreviousButton from "../button/PreviousButton";
import { PdsSidebar } from "@principal/design-system-react";
import { FormikValues } from "formik";

export type PreviousNextProps = {
    previous?: (e: Event) => void,
    next?: (e: Event) => void,
    values?: FormikValues,
    isSubmitting?: boolean,
    submitForm?: Function,
    children?: React.ReactNode,
}

const PreviousNext = (props: PreviousNextProps) => {
    return (
        <PdsSidebar gap="none">
            <div className="pds-u-padding-horizontal-16 pds-u-flex" style={{marginLeft: 'auto'}}>
                {props.previous ? <PreviousButton onClick={props.previous}/> : null}
                {props.next ? <NextButton onClick={props.next}
                                          isSubmitting={props.isSubmitting}
                                          submitForm={props.submitForm}
                                          values={props.values}
                    /> : null}
            </div>
            <div slot="right-sidebar" className="pds-u-padding-horizontal-16"/>
        </PdsSidebar>
    )
};
export default PreviousNext;