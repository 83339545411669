export enum BeneficiaryPages {
    START = 'Start',
    BENEFICIARY_INFO = 'Your info',
    CONTACT_INFO = 'Contact info',
    RELATIONSHIP = 'Relationship',
    CLAIM_TYPE = 'Claim type',
    FINANCIAL = 'Financial',
    DOCUMENT = 'Document',
    SUMMARY = 'Summary',
    FINISH = 'Finish'
}