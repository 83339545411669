import React from 'react';
import EConsentModal from "../../structure/EConsentModal";
import axios from 'axios';
import { PDFDocument, PDFPage } from "pdf-lib";
import QuestionPanel from "../../structure/QuestionPanel";
import LoadingSpinner from '../../structure/LoadingSpinner';
import { PhysicianFormValues } from "../../common/PhysicianFormValues";
import { BenefitType } from "../../enums/BenefitType";
import { webAnalytics } from "@principal/web-analytics";
import IconWithContent from "../../structure/IconWithContent";
import {
    PdsIconAlertTriangle,
    PdsIconClock,
    PdsIconDownload,
    PdsIconFile,
    PdsIconPhone
} from "@principal/design-system-icons-react";
import HorizontalRule from "../../structure/HorizontalRule";
import Button from "../../button/Button";

export type PhysicianStartProps = {
    values?: PhysicianFormValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

enum FormType { SERIOUS_HEALTH_CONDITION = 'GP62714', ACTIVE_DUTY_MILITARY_EVENTS = 'GP62715'}

function addClaimIdTo(page: PDFPage, claimId: string) {
    page.drawText(`Claim ID: ${claimId}`, {
        x: page.getSize().width - 250,
        y: 20,
        size: 10,
    })
}

async function downloadClaimForm(formType: FormType) {
    return await axios.request({
        method: 'get',
        url: `${process.env.REACT_APP_FORMS_SERVICE_URL}/form?formNumber=${formType}`,
        responseType: 'arraybuffer'
    });
}

async function generateDownloadableFileFor(pdfDocument: PDFDocument, claimId: string) {
    const blob = new Blob([await pdfDocument.save()], { type: "application/pdf" });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Provider Claim Form (${claimId}).pdf`;
    link.click();
}

const PhysicianStart = (props: PhysicianStartProps) => {
    function setFormFlag(formType: FormType, value: boolean) {
        if (formType === FormType.SERIOUS_HEALTH_CONDITION) {
            setDownloadingSerious(value);
        } else if (formType === FormType.ACTIVE_DUTY_MILITARY_EVENTS) {
            setDownloadingMilitary(value);
        }
    }

    async function createClaimPdf(claimId: string | undefined, formType: FormType) {
        if(!claimId) {
            return;
        }
        setFormFlag(formType, true);
        const claimForm = await downloadClaimForm(formType);
        // @ts-ignore
        window.QPDF.path = "scripts/qpdf.js/src/"
        // @ts-ignore
        window.QPDF({
            ready: function (qpdf: any) {
                qpdf.save('input.pdf', claimForm.data);
                qpdf.execute(['--decrypt', 'input.pdf', 'output.pdf']);
                qpdf.load('output.pdf', async function (err: any, arrayBuffer: any) {
                    if (err) {
                        console.error(err.message);
                    } else {
                        const pdfDocument = await PDFDocument.load(Buffer.from(arrayBuffer));
                        for(const page of pdfDocument.getPages()) {
                            addClaimIdTo(page, claimId);
                        }
                        await generateDownloadableFileFor(pdfDocument, claimId);
                        setFormFlag(formType, false);
                    }
                });
            }
        });
    }

    function buildFormButtonFor(flag: boolean, props: PhysicianStartProps, iconText: string, formType: FormType) {
        return <>
            {flag
                ? <LoadingSpinner spinnerText="Downloading"/>
                : <Button
                    buttonContent={<>
                        <PdsIconDownload slot="icon-left" data-gtm="call-to-action-text"/>
                        <span data-gtm="call-to-action-text">{iconText}</span>
                    </>}
                    buttonProps={{ link: "icon-left", size: "sm", removeLinkPadding: true }}
                    onClick={async () => {
                        webAnalytics.trackEvent('pageNavigationClick', {
                            clicktext: iconText,
                            navigationsection: 'text-link',
                        })
                        await createClaimPdf(props.values?.claimId, formType)
                    }}/>
            }
        </>;
    }

    const [downloadingSerious, setDownloadingSerious] = React.useState(false);
    const [downloadingMilitary, setDownloadingMilitary] = React.useState(false);

    return (
        <QuestionPanel headerText="What to expect on the form for physicians"
                       detailsText={
                           BenefitType.PFML === props.values?.benefit && <>
                               <div className="pds-u-padding-bottom-8">
                                   <span>A paper version of this form is also available, which you can mail/email/fax to us:</span>
                               </div>
                               <div className="pds-u-padding-bottom-8">
                                   {buildFormButtonFor(downloadingSerious, props,
                                       'Leave for serious health condition / maternity', FormType.SERIOUS_HEALTH_CONDITION)}
                               </div>
                               <div>
                                   {buildFormButtonFor(downloadingMilitary, props,
                                       'Leave for active duty / military events', FormType.ACTIVE_DUTY_MILITARY_EVENTS)}
                               </div>
                           </>}
                       panelContent={
                           <>
                               <IconWithContent icon={PdsIconClock} content={<span>Allow 10-15 minutes to complete the form.</span>}/>
                               <HorizontalRule/>
                               <IconWithContent icon={PdsIconAlertTriangle} content={<span>Your work isn't saved, and we wouldn't want you to have to start over.</span>}/>
                               <HorizontalRule/>
                               <IconWithContent icon={PdsIconPhone} content={
                                   <span>
                                       If you have any questions about this claim, please call (800) 245-1522 between the hours of 7:30 am and 5:00 pm CST.
                                   </span>}/>
                               <HorizontalRule/>
                               <IconWithContent icon={PdsIconFile} content={
                                   <>
                                       <span style={{ fontWeight: 700 }}>What you'll need to complete this form</span>
                                       <ul>
                                           <li className="pds-u-padding-top-10">The patient's job functions they are unable to perform</li>
                                           <li className="pds-u-padding-top-10">The patient's diagnosis and associated dates</li>
                                           <li className="pds-u-padding-top-10">If the claim is for the caretaker, we need basic information about that person</li>
                                       </ul>
                                   </>}/>
                               <EConsentModal {...props}/>
                           </>}
        >
        </QuestionPanel>
    )
};

export default PhysicianStart;