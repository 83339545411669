export enum AffiliatePostalAddressType {
    PURCHASED_MAILING = 'A',
    PRIMARY_MAILING = '1',
    BUSINESS_ONE = '2',
    MAILING_TWO = '3',
    BUSINESS_TWO = '4',
    RESIDENTIAL = '5',
    PURCHASED_ADDRESS = '6',
    PURCHASED_RESIDENTIAL = '8',
    PURCHASED_BUSINESS = '9'
}