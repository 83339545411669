import React, { ReactElement } from 'react';
import TextInput from "./TextInput";
import { FieldValidator } from "formik";
import { Options } from "inputmask";

type NumericInputProps = {
    id: string,
    name?: string,
    label?: string,
    required: boolean,
    labelContent?: ReactElement,
    leftContent?: ReactElement,
    rightContent?: ReactElement,
    maxLength?: number,
    inputMask?: Options,
    changeCallback?: Function,
    validate?: FieldValidator,
    children?: React.ReactNode
}

const NumericInput = (props: NumericInputProps) => {
    const propsMaxLength = props.maxLength;
    const maxLength = (propsMaxLength && propsMaxLength >= 0) ? propsMaxLength : 16;
    return (
        <TextInput id={props.id} name={props.name} label={props.label} required={props.required} changeCallback={props.changeCallback}
                   inputMask={props.inputMask ? props.inputMask : { mask: `9{1,${maxLength}}` }}
                   labelContent={props.labelContent}
                   leftContent={props.leftContent}
                   rightContent={props.rightContent}
                   maxLength={maxLength}
                   validate={props.validate}/>
    )
};
export default NumericInput;