import React, { ReactElement } from 'react';
import TextInput from "./TextInput";
import { date } from "yup";
import { PdsDatePicker, PdsDatePickerInput } from "@principal/design-system-react";

export type DateInputProps = {
    id: string,
    name?: string,
    label: string,
    required: boolean,
    labelContent?: ReactElement,
    changeCallback?: Function,
    children?: React.ReactNode
}

const DateInput = (props: DateInputProps) => {
    return <TextInput id={props.id} name={props.name} label={props.label} required={props.required} labelContent={props.labelContent}
                      inputComponent={PdsDatePickerInput as React.FunctionComponent<React.ComponentProps<'input'>>}
                      inputProps={{ children: <PdsDatePicker slot="datepicker" />, helpText: '' }}
                      changeCallback={props.changeCallback}
                      inputMask={{ alias: 'datetime', inputFormat: 'mm/dd/yyyy' }}
                      maxLength={10}
                      validate={validateDate}/>

    function validateDate(value: string) {
        return date().isValidSync(value) ? '' : 'You must provide a valid date.'
    }
};

export default DateInput;