import { defaultPfmlFormValues, PfmlFormValues } from "./PfmlFormValues";
import { defaultPhysicianFormValues, PhysicianFormValues } from "../common/PhysicianFormValues";

export type PfmlPhysicianFormValues = PhysicianFormValues & PfmlFormValues & {
    patientRelationship: string,
    patientRequiresCare: string,
    permanentOrLongTermCondition: boolean,
    multipleTreatmentsAndRecovery: boolean,
    chronic: boolean,
    pregnancy: boolean,
    incapacityAndTreatment: boolean,
    patientMilitaryService: string,
    visitFrequency: string,
    substanceAbuseDates: [],
    recommendedTimeOff: string,
    recommendedTimeOffDays: string,
    recommendedTimeOffHours: string
    patientWorkRestrictions: string,
    returnToWorkDays: string,
}

export const defaultPfmlPhysicianFormValues = {
    ...defaultPhysicianFormValues,
    ...defaultPfmlFormValues,
    patientRelationship: '',
    patientRequiresCare: '',
    permanentOrLongTermCondition: false,
    multipleTreatmentsAndRecovery: false,
    chronic: false,
    pregnancy: false,
    incapacityAndTreatment: false,
    patientMilitaryService: '',
    visitFrequency: '',
    substanceAbuseDates: [],
    recommendedTimeOff: '',
    recommendedTimeOffDays: '',
    recommendedTimeOffHours: '',
    patientWorkRestrictions: '',
    returnToWorkDays: '',
}