import React from 'react';
import { Field, FieldHelperProps, useField } from "formik";
import { FieldProps } from "formik/dist/Field";
import { FieldInputProps } from "formik/dist/types";
import { PdsRadio } from "@principal/design-system-react";

export type RadioProps = {
    id: string,
    name: string,
    text: string,
    changeCallback?: Function,
    field?: FieldInputProps<any>,
    children?: React.ReactNode
}

const Radio = (props: RadioProps) => {
    const [, meta, helpers] = useField(props.name);

    return (meta.initialValue === undefined ? null :
        <div>
            { createInput(props, helpers) }
        </div>
    )
};

function createInput(props: RadioProps, helpers: FieldHelperProps<any>) {
    return <Field component={Input} {...props} helpers={helpers}/>
}

const Input = ({ field, form, helpers, ...props }: FieldProps & RadioProps & {helpers: FieldHelperProps<any>}) => {
    return <PdsRadio {...field} id={props.id} fieldId={props.id} label={props.text} checked={field?.value === props.text} value={props.text}
                  onChange={(event) => {
        helpers.setError(undefined)
        if (field) {
            field.onChange(event);
        }
        if (props.changeCallback) {
            props.changeCallback(event.target)
        }
    }}/>
};

export default Radio;