import { defaultEmployeeFormValues, EmployeeFormValues } from "../common/EmployeeFormValues";
import { defaultHospitalIndemnityFormValues, HospitalIndemnityFormValues } from "./HospitalIndemnityFormValues";
import {Language} from "../enums/Language";
import _ from "lodash";

export type HospitalIndemnityEmployeeFormValues = Omit<EmployeeFormValues, 'workState' | 'workStateSameAsResidencyState'> & HospitalIndemnityFormValues & {
    textPermission: boolean,
    preferredLanguage: string,
    otherLanguage: string,
    claimDates: [{
        startDate: string
    }],
    dependents: string,
    conditionDetails: string,
    firstVisit: string,
    diagnosisDate: string,
    policeReport: string,
    timeOfInjury: string,
    locationOfInjury: string,
    hospitalization: string[],
    surgeryBenefits: string[],
    medicalTests: string[],
    otherBenefits: string[],
    typeOfDelivery: string[],
    pregnancyCare: string[],
    emergencyCare: string[],
    outpatientBenefits: string[],
    substanceAbuse: string[],
    mentalDisorder: string[]
}

export const defaultHospitalIndemnityEmployeeFormValues = {
    ..._.omit(defaultEmployeeFormValues, ['workState', 'workStateSameAsResidencyState']),
    ...defaultHospitalIndemnityFormValues,
    textPermission: false,
    preferredLanguage: Language.ENGLISH,
    otherLanguage: '',
    claimDates: [{
        startDate: ''
    }],
    dependents: '',
    conditionDetails: '',
    firstVisit: '',
    diagnosisDate: '',
    policeReport: '',
    timeOfInjury: '',
    locationOfInjury: '',
    hospitalization: [],
    surgeryBenefits: [],
    medicalTests: [],
    otherBenefits: [],
    typeOfDelivery: [],
    pregnancyCare: [],
    emergencyCare: [],
    outpatientBenefits: [],
    substanceAbuse: [],
    mentalDisorder: []
}
