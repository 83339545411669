export enum EmployerPages {
    START = 'Start',
    EMPLOYER = 'Employer',
    EMPLOYEE = 'Employee',
    RELATIONSHIP = 'Relationship',
    CONTACT = 'Contact',
    OCCUPATION = 'Occupation',
    EMPLOYMENT = 'Employment',
    CLAIM_TYPE = 'Claim type',
    DOCUMENT = 'Document',
    CONDITION = 'Condition',
    FINANCIAL = 'Financial',
    PAYMENTS = 'Payments',
    SUMMARY = 'Summary',
    FINISH = 'Finish'
}