import React from 'react';
import EConsentModal from "../../structure/EConsentModal";
import QuestionPanel from "../../structure/QuestionPanel";
import Tooltip from "../../input/Tooltip";
import { EmployerFormValues } from "../../common/EmployerFormValues";
import { PdsIconAlertTriangle, PdsIconClock, PdsIconFile } from "@principal/design-system-icons-react";
import IconWithContent from "../../structure/IconWithContent";
import HorizontalRule from "../../structure/HorizontalRule";

export type StartProps = {
    values?: EmployerFormValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

const Start = (props: StartProps) => {
    // @ts-ignore
    window.globalVariable = 'EMPLOYER';
    return (
        <QuestionPanel headerText="What to expect on the form for employers"
                       detailsText={<>
                           Need to submit a different claim form?<br/>
                           <a href="https://www.principal.com/help/help-businesses/forms-businesses" rel="noopener noreferrer" target="_blank">
                               Visit Forms for employers
                           </a>.<br/><br/>
                           Sign in with your Principal.com credentials, and we will fill in what we know about you. Along the way, you can review and make changes as needed.
                       </>}
                       panelContent={<>
                           <IconWithContent icon={PdsIconClock} content={<span>Allow 10-15 minutes to complete the form.</span>}/>
                           <HorizontalRule/>
                           <IconWithContent icon={PdsIconAlertTriangle} content={
                               <span>Your work isn't saved, and we wouldn't want you to have to start over.</span>}/>
                           <HorizontalRule/>
                           <IconWithContent icon={PdsIconFile} content={
                               <>
                                   <span style={{ fontWeight: 700 }}>What you'll need to complete this form</span>
                                   <ul>
                                       <li className="pds-u-padding-top-10">Employment details and records for the employee</li>
                                       <li className="pds-u-padding-top-10">Personal and contact information for the employee</li>
                                   </ul>
                                   <span style={{ fontWeight: 700 }}>Nice to have</span>
                                   <ul>
                                       <li className="pds-u-padding-top-10">Your Principal plan ID number
                                           <Tooltip text="You can find this 7-digit number in your policy booklet."/> and unit number
                                           <Tooltip text="You can find this 5 digit number in your policy booklet, depending on what unit your employee is in."/></li>
                                   </ul>
                               </>}/>
                           <EConsentModal {...props}/>
                       </>}
        >
        </QuestionPanel>
    )
};

export default Start;