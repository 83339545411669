import { Party } from "./Party";
import { PaymentFrequency } from "./PaymentFrequency";
import { Relationship } from "./Relationship";
import { getKeyByValue } from "./KeyFinder";
import { DocumentType } from "./DocumentType";
import { ClaimPlan } from "../domain/ClaimPlan";
import { TypeOfClaim } from "./TypeOfClaim";
import { Medical } from "./Medical";
import { ScreeningType } from "./ScreeningType";
import { MannerOfDeath } from "./MannerOfDeath";
import { AffiliateBusinessUnit } from "./AffiliateBusinessUnit";

export enum BenefitType {
    PFML = "pfml",
    DISABILITY = "disability",
    HOSPITAL_INDEMNITY = "hospitalIndemnity",
    WELLNESS = "wellness",
    LIFE = "life"
}

export function findFullNameFor(benefitType: BenefitType): string {
    if(BenefitType.PFML === benefitType) {
        return "paid family and medical leave";
    } else if (BenefitType.DISABILITY === benefitType) {
        return "short- or long-term disability";
    } else if (BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        return "hospital indemnity";
    } else if (BenefitType.WELLNESS === benefitType) {
        return "wellness/health screening";
    } else if (BenefitType.LIFE === benefitType) {
        return "life insurance";
    }
    return "";
}

export function findTitleFor(benefitType: BenefitType): string {
    if(BenefitType.PFML === benefitType) {
        return "Paid Family and Medical Leave";
    } else if (BenefitType.DISABILITY === benefitType) {
        return "Short- or Long-Term Disability";
    } else if (BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        return "Hospital Indemnity";
    } else if (BenefitType.WELLNESS === benefitType) {
        return "Wellness/Health Screening";
    } else if (BenefitType.LIFE === benefitType) {
        return "Life";
    }
    return "";
}

export function findAbbreviationFor(benefitType: BenefitType) {
    if (BenefitType.PFML === benefitType) {
        return "PFML";
    } else if (BenefitType.DISABILITY === benefitType) {
        return "Disability";
    } else if (BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        return "Hospital Indemnity";
    } else if (BenefitType.WELLNESS === benefitType) {
        return "Wellness";
    } else if (BenefitType.LIFE === benefitType) {
        return "Life";
    }
    return "";
}

export function findHelpUrlFor(benefitType: BenefitType) {
    const url = "https://www.principal.com/businesses/protect-my-employees/"
    if (BenefitType.PFML === benefitType) {
        return url + "group-benefits/paid-family-medical-leave-state-disability-insurance";
    } else if (BenefitType.DISABILITY === benefitType) {
        return url + "group-benefits/disability-insurance";
    } else if (BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        return url + "group-hospital-indemnity-insurance";
    } else if (BenefitType.WELLNESS === benefitType) {
        return "";
    } else if (BenefitType.LIFE === benefitType) {
        return url + "group-benefits/life-insurance";
    }
    return "";
}

export function findPartyChoicesFor(benefitType: BenefitType): Party[] {
    if ([BenefitType.PFML, BenefitType.DISABILITY].includes(benefitType)) {
        return [Party.EMPLOYEE, Party.EMPLOYER, Party.PHYSICIAN];
    } else if ([BenefitType.HOSPITAL_INDEMNITY, BenefitType.WELLNESS].includes(benefitType)) {
        return [Party.EMPLOYEE];
    } else if (BenefitType.LIFE === benefitType) {
        return [Party.BENEFICIARY, Party.EMPLOYER];
    }
    return [];
}

export function findRequiredPartiesFor(benefitType: BenefitType): Party[] {
    if ([BenefitType.PFML, BenefitType.DISABILITY].includes(benefitType)) {
        return [Party.EMPLOYEE, Party.EMPLOYER, Party.PHYSICIAN];
    } else if (BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        return [Party.EMPLOYEE, Party.EMPLOYER];
    } else if (BenefitType.WELLNESS === benefitType) {
        return [Party.EMPLOYEE];
    } else if (BenefitType.LIFE === benefitType) {
        return [Party.BENEFICIARY, Party.EMPLOYER];
    }
    return [];
}

export function findPaymentFrequencyFor(benefitType: BenefitType): PaymentFrequency {
    if ([BenefitType.PFML, BenefitType.DISABILITY].includes(benefitType)) {
        return PaymentFrequency.RECURRING;
    } else if ([BenefitType.HOSPITAL_INDEMNITY, BenefitType.WELLNESS].includes(benefitType)) {
        return PaymentFrequency.ADHOC;
    }
    return PaymentFrequency.RECURRING;
}

export function findIncludesStateNoticesFor(benefitType: BenefitType): boolean {
    return benefitType !== BenefitType.PFML;
}

export function findClaimTypeLabelFor(benefitType: BenefitType): string {
    if (BenefitType.PFML === benefitType) {
        return "Leave type";
    } else if ([BenefitType.DISABILITY, BenefitType.HOSPITAL_INDEMNITY].includes(benefitType)) {
        return "Condition type";
    } else if (BenefitType.WELLNESS === benefitType) {
        return "Screening type";
    } else if (BenefitType.LIFE === benefitType) {
        return "Manner of death";
    }
    return "Type of claim";
}

type claimDateText = {
    headerText: string,
    detailsText: string,
    startDateLabel: string
}

export function findClaimDateFor(benefit: string | undefined): claimDateText {
    if (BenefitType.PFML === benefit) {
        return {
            headerText: "When would you like to take your leave?",
            detailsText: "Your leave can be taken all at once without breaks (continuous) or off-and-on (periodic). Add as many date ranges as needed.",
            startDateLabel: "Start date"
        };
    } else if (BenefitType.DISABILITY === benefit) {
        return {
            headerText: "When did/will your medical leave begin?",
            detailsText: "This is the first day you were/will be unable to work due to your medical condition.",
            startDateLabel: "Date"
        };
    } else if (BenefitType.HOSPITAL_INDEMNITY === benefit) {
        return {
            headerText: "Please provide the date your sickness started, injury happened, or date of delivery.",
            detailsText: "This should be the date of service from your bill, itemized receipt, or medical carrier's explanation of benefits.",
            startDateLabel: "Date"
        };
    } else if (BenefitType.WELLNESS === benefit) {
        return {
            headerText: "Please provide the date of service, test, or procedure.",
            detailsText: "This should be the date your physical, test, or procedure was performed.",
            startDateLabel: "Date"
        }
    } else if (BenefitType.LIFE === benefit) {
        return {
            headerText: "Please provide the date of death.",
            detailsText: "This should be the same date from the death certificate.",
            startDateLabel: "Date of death"
        }
    }
    return {headerText: "", detailsText: "", startDateLabel: ""};
}

export function findRelationshipsFor(benefitType: BenefitType) {
    if(!getKeyByValue(BenefitType, benefitType)) {
        return [];
    } else if(BenefitType.PFML === benefitType) {
        return [Relationship.EMPLOYEE, Relationship.FAMILY_MEMBER];
    } else if(BenefitType.DISABILITY === benefitType) {
        return [Relationship.EMPLOYEE];
    } else if(BenefitType.LIFE === benefitType) {
        return [Relationship.EMPLOYEE, Relationship.SPOUSE, Relationship.CHILD]
    }
    return [Relationship.EMPLOYEE, Relationship.COVERED_DEPENDENT];
}

export function findEmailToFor(benefitType: BenefitType): string {
    if(BenefitType.LIFE === benefitType) {
        return process.env.LIFE_EMAIL_TO as string
    }
    return process.env.DISABILITY_EMAIL_TO as string
}

export function hasRequiredDocumentsFor(benefitType: BenefitType): boolean {
    return BenefitType.HOSPITAL_INDEMNITY === benefitType;
}

export function findEmailConfirmationFor(benefitType: BenefitType): string {
    const employerEmailConfirmation = findRequiredPartiesFor(benefitType).includes(Party.EMPLOYER)
        ? " which will include a link to forward your employer with the information they'll need to complete your claim"
        : "";
    return `You'll receive an email confirmation from Principal${employerEmailConfirmation}.`;
}

export function findDocumentTypesFor(benefitType: BenefitType): DocumentType[] {
    if(BenefitType.PFML === benefitType) {
        return [DocumentType.PHYSICIAN_STATEMENT, DocumentType.BIRTH_CERTIFICATE, DocumentType.ADOPTION_FOSTER_PLACEMENT,
            DocumentType.ACTIVE_DUTY_ORDERS, DocumentType.LETTER_OF_IMPENDING_ACTIVATION, DocumentType.COPY_OF_CIVIL_COURT_APPOINTMENTS_OR_SERVICES];
    } else if(BenefitType.DISABILITY === benefitType) {
        return [DocumentType.PHYSICIAN_STATEMENT];
    } else if(BenefitType.LIFE === benefitType) {
        return [DocumentType.DEATH_CERTIFICATE, DocumentType.INCIDENT, DocumentType.AUTOPSY, DocumentType.NEWSPAPER,
            DocumentType.POLICE_DEPARTMENT_INFO, DocumentType.TRANSPORTATION_BILL, DocumentType.HIPAA_AUTH]
    } else if(BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        return [DocumentType.BILLS, DocumentType.RECEIPTS, DocumentType.UNIFORM_BILL,
            DocumentType.EXPLANATION_OF_BENEFITS, DocumentType.ADMISSION, DocumentType.INCIDENT];
    }
    return [];
}

export function findDefaultLeaveTypesFor(benefitType: BenefitType): ClaimPlan[] {
    if (benefitType === BenefitType.PFML) {
        return [{
            claimType: TypeOfClaim.SERIOUS_HEALTH_CONDITION,
            relationship: [Relationship.EMPLOYEE, Relationship.FAMILY_MEMBER],
            medical: [Medical.MEDICAL]
        }, {
            claimType: TypeOfClaim.MATERNITY,
            relationship: [Relationship.EMPLOYEE],
            medical: [Medical.MEDICAL]
        }, {
            claimType: TypeOfClaim.CHILD_BONDING,
            relationship: [Relationship.FAMILY_MEMBER],
            medical: [Medical.NON_MEDICAL]
        }, {
            claimType: TypeOfClaim.MILITARY,
            relationship: [Relationship.FAMILY_MEMBER],
            medical: [Medical.MEDICAL, Medical.NON_MEDICAL]
        }, {
            claimType: TypeOfClaim.PERSONAL,
            relationship: [Relationship.EMPLOYEE],
            medical: [Medical.MEDICAL, Medical.NON_MEDICAL]
        }] as ClaimPlan[];
    } else if (benefitType === BenefitType.DISABILITY) {
        return [{
            claimType: TypeOfClaim.INJURY,
            relationship: [Relationship.EMPLOYEE],
            medical: [Medical.MEDICAL]
        }, {
            claimType: TypeOfClaim.PHYSICAL_ILLNESS,
            relationship: [Relationship.EMPLOYEE],
            medical: [Medical.MEDICAL]
        }, {
            claimType: TypeOfClaim.MENTAL_HEALTH,
            relationship: [Relationship.EMPLOYEE],
            medical: [Medical.MEDICAL]
        }, {
            claimType: TypeOfClaim.PREGNANCY,
            relationship: [Relationship.EMPLOYEE],
            medical: [Medical.MEDICAL]
        }] as ClaimPlan[];
    } else if (benefitType === BenefitType.HOSPITAL_INDEMNITY) {
        return [{
            claimType: TypeOfClaim.SICKNESS,
            relationship: [Relationship.EMPLOYEE, Relationship.COVERED_DEPENDENT],
            medical: [Medical.MEDICAL]
        }, {
            claimType: TypeOfClaim.INJURY,
            relationship: [Relationship.EMPLOYEE, Relationship.COVERED_DEPENDENT],
            medical: [Medical.MEDICAL]
        }, {
            claimType: TypeOfClaim.PREGNANCY,
            relationship: [Relationship.EMPLOYEE, Relationship.COVERED_DEPENDENT],
            medical: [Medical.MEDICAL]
        }] as ClaimPlan[];
    } else if (benefitType === BenefitType.WELLNESS) {
        return Object.values(ScreeningType).map((screeningType: ScreeningType) => {
            return {
                claimType: screeningType,
                relationship: [Relationship.EMPLOYEE, Relationship.COVERED_DEPENDENT],
                medical: [Medical.MEDICAL]
            };
        }) as ClaimPlan[];
    } else if (benefitType === BenefitType.LIFE) {
        return Object.values(MannerOfDeath).map((mannerOfDeath: MannerOfDeath) => {
            return {
                claimType: mannerOfDeath,
                relationship: [Relationship.EMPLOYEE, Relationship.SPOUSE, Relationship.CHILD, '' as Relationship],
                medical: [Medical.NON_MEDICAL]
            };
        })
    }
    return [];
}

export function findAffiliateBusinessUnitCodesFor(benefitType: BenefitType): AffiliateBusinessUnit[] {
    let businessUnitCodes = [] as AffiliateBusinessUnit[];
    if(BenefitType.PFML === benefitType) {
        businessUnitCodes = [AffiliateBusinessUnit.PAID_FAMILY_LEAVE, AffiliateBusinessUnit.PAID_MEDICAL_LEAVE]
    } else if(BenefitType.DISABILITY === benefitType) {
        businessUnitCodes = [AffiliateBusinessUnit.SHORT_TERM_DISABILITY, AffiliateBusinessUnit.VOLUNTARY_SHORT_TERM_DISABILITY,
            AffiliateBusinessUnit.SELF_FUNDED_SHORT_TERM_DISABILITY, AffiliateBusinessUnit.LONG_TERM_DISABILITY, AffiliateBusinessUnit.VOLUNTARY_LONG_TERM_DISABILITY,];
    } else if(BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        businessUnitCodes = [AffiliateBusinessUnit.HOSPITAL_INDEMNITY, AffiliateBusinessUnit.VOLUNTARY_HOSPITAL_INDEMNITY,
            AffiliateBusinessUnit.VOLUNTARY_HOSPITAL_INDEMNITY_THREE, AffiliateBusinessUnit.VOLUNTARY_HOSPITAL_INDEMNITY_TWO]
    } else if(BenefitType.WELLNESS === benefitType) {
        businessUnitCodes = [AffiliateBusinessUnit.ACCIDENT, AffiliateBusinessUnit.CRITICAL_ILLNESS, AffiliateBusinessUnit.HOSPITAL_INDEMNITY,
            AffiliateBusinessUnit.VOLUNTARY_HOSPITAL_INDEMNITY, AffiliateBusinessUnit.VOLUNTARY_HOSPITAL_INDEMNITY_THREE,
            AffiliateBusinessUnit.VOLUNTARY_HOSPITAL_INDEMNITY_TWO]
    } else if(BenefitType.LIFE === benefitType) {
        businessUnitCodes = [AffiliateBusinessUnit.GROUP_TERM_LIFE, AffiliateBusinessUnit.VOLUNTARY_TERM_LIFE]
    }
    businessUnitCodes.push(AffiliateBusinessUnit.SBD_COMPASS);
    return businessUnitCodes;
}
