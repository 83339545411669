import React, {ReactElement} from 'react';
import NumericInput from "./NumericInput";

export type DecimalInputProps = {
    id: string,
    name?: string,
    label: string,
    required: boolean,
    maxLength?: number
    labelContent?: ReactElement,
    changeCallback?: Function,
    children?: React.ReactNode,
}

const DecimalInput = (props: DecimalInputProps) => {
    return <NumericInput id={props.id} name={props.name} label={props.label} required={props.required} maxLength={props.maxLength}
                         labelContent={props.labelContent}
                         changeCallback={props.changeCallback}
                         inputMask={{ regex: '^[0-9]+(.[0-9]{1,2})?$' }}/>
};
export default DecimalInput;