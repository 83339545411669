export enum ScreeningType {
    ANNUAL_PHYSICAL = 'Annual Physical',
    ANY_OTHER_CANCER_SCREENING = 'Any other cancer screening test approved by the Federal Food and Drug Administration',
    BONE_DENSITY_SCREENING = 'Bone density screening',
    BONE_MARROW_CANCER_SCREENING = 'Bone marrow cancer screening (serum protein electrophoresis)',
    BREAST_CANCER_SCREENING = 'Breast cancer screening (CA 15-3, clinical breast exam, mammogram, MRI, thermography, ultrasound)',
    CARDIAC_STRESS_TEST = 'Cardiac stress test or electrocardiogram (EKG/ECG)',
    CERVICAL_CANCER_SCREENING = 'Cervical cancer screening (pap smear)',
    CHEST_X_RAY = 'Chest x-ray',
    COLORECTAL_CANCER_SCREENING = 'Colorectal cancer screening (CEA, colonoscopy, double contrast barium enema, fecal occult blood test, sigmoidoscopy)',
    COMPLETION_OF_A_SMOKING_CESSATION_PROGRAM = 'Completion of a smoking cessation program',
    COMPLETION_OF_A_WEIGHT_REDUCTION_PROGRAM = 'Completion of a weight reduction program',
    COVID_TESTING = 'Covid testing',
    DOPPLER_SCREENING = 'Doppler screening or carotid doppler ultrasound',
    GENETIC_SCREENING_TEST = 'Genetic Screening testing',
    HUMAN_PAPILLOMAVIRUS_SCREENING = 'Human Papillomavirus (HPV) screening test',
    HUMAN_PAPILLOMAVIRUS_VACCINE = 'Human Papillomavirus (HPV) vaccine',
    IMMUNIZATION = 'Immunization',
    MENTAL_HEALTH_ASSESSMENT = 'Mental Health assessment',
    OVARIAN_CANCER_SCREENING = 'Ovarian cancer screening (CA 125)',
    PROSTATE_CANCER_SCREENING = 'Prostate cancer screening (digital rectal exam, PSA blood test)',
    SKIN_CANCER_SCREENING = 'Skin cancer screening',
    STANDARD_BLOOD_CHEMISTRY_PROFILE = 'Standard blood chemistry profile or lipid panel (cholesterol, triglycerides, HDL, LDL, fasting blood glucose, hemoglobin A1c)',
    TEST_FOR_GENETIC_SUSCEPTIBILITY_FOR_THE_RISK_OF_CANCER = 'Test for genetic susceptibility for the risk of cancer',
    ULTRASOUND_SCREENING_OF_ABDOMINAL_AORTA = 'Ultrasound screening of the abdominal aorta',
    VISION_TESTING = 'Vision testing',
}
