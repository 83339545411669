import { defaultDisabilityPhysicianFormValues, DisabilityPhysicianFormValues } from "./DisabilityPhysicianFormValues";
import { TypeOfClaim } from "../enums/TypeOfClaim";
import _ from "lodash";

export type PregnancyDisabilityPhysicianFormValues = Omit<DisabilityPhysicianFormValues, 'patientCompetent' | 'nextVisit' | 'restrictionOrCareEndDate'
    | 'inpatientCare' | 'facilityName' | 'inpatientCareStartDate' | 'inpatientCareEndDate'
    | 'workRelated' | 'conditionStartDate' | 'treatments' | 'restrictingPermanently' | 'returnToWork' | 'returnToWorkHours'> & {
    deliveryDate: string,
    expectedDelivery: boolean,
    bedConfined: boolean,
    bedConfinedStartDate: string,
    bedConfinedEndDate: string,
}

export const defaultPregnancyDisabilityPhysicianFormValues = {
    ..._.omit(defaultDisabilityPhysicianFormValues, ['patientCompetent', 'nextVisit', 'restrictionOrCareEndDate',
        'inpatientCare', 'facilityName', 'inpatientCareStartDate', 'inpatientCareEndDate',
        'workRelated', 'conditionStartDate', 'treatments', 'restrictingPermanently', 'returnToWork', 'returnToWorkHours']),
    claimType: TypeOfClaim.PREGNANCY,
    deliveryDate: '',
    expectedDelivery: false,
    bedConfined: false,
    bedConfinedStartDate: '',
    bedConfinedEndDate: '',
}