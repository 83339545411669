import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import Tooltip from "../../input/Tooltip";
import NumericInput from "../../input/NumericInput";
import CenteredFullWidthDiv from "../../structure/CenteredFullWidthDiv";
import ZipCodeInput from "../../input/ZipCodeInput";
import EmailInput from "../../input/EmailInput";
import { WellnessEmployeeFormValues } from "../../wellness/WellnessEmployeeFormValues";
import PercentageInput from "../../input/PercentageInput";
import RadioGroup from "../../structure/RadioGroup";
import { Taxation } from "../../enums/Taxation";
import DateInput from "../../input/DateInput";
import Checkbox from "../../input/Checkbox";
import { BenefitType, findEmailConfirmationFor } from "../../enums/BenefitType";

export type EmployerProps = {
    initialValues?: WellnessEmployeeFormValues
    values?: WellnessEmployeeFormValues
    setFieldValue?: Function
}

const Employer = (props: EmployerProps) => {
    const premiumTaxationPostTax = props.values?.premiumTaxation === Taxation.POST_TAX;
    const activelyAtWork = props.values?.activelyAtWork;
    const setFieldValue = props.setFieldValue;

    return (
        <QuestionPanel headerText="Tell us about where you work."
                       detailsText={findEmailConfirmationFor(props.values?.benefit as BenefitType)}
                       panelContent={
                           <>
                               <div>
                                   <NumericInput id="policy-number" label="Employer's Principal account ID" required={false}
                                                 maxLength={7}
                                                 labelContent={<Tooltip text="You can usually find this 7-digit number by asking an HR representative of your employer."/>}/>
                               </div>
                               <CenteredFullWidthDiv divContent={
                                   <h4>
                                       or
                                   </h4>}/>
                               <div>
                                   <TextInput id="employer-name" label="Employer name" required={false} maxLength={1024}/>
                                   <ZipCodeInput id="employer-zip-code" label="Employer ZIP code" required={false}/>
                                   <EmailInput id="employer-email-address" label="Employer email address" required={false}/>
                               </div>
                               <div>
                                   <RadioGroup id="premium-taxation"
                                               label="Is your portion of premium paid with:"
                                               labelContent={props.initialValues?.premiumTaxation !== undefined ? <Tooltip
                                                   text="You can find whether your premium is paid with pre- or post-tax dollars on your paystub."/> : undefined}
                                               required={true}
                                               radioProps={[{
                                                   id: "pre-tax",
                                                   text: Taxation.PRE_TAX,
                                                   changeCallback: employeeTaxationChange
                                               },
                                                   { id: "post-tax", text: Taxation.POST_TAX, changeCallback: employeeTaxationChange }]}/>
                                   {premiumTaxationPostTax &&
                                       <PercentageInput id="employee-contribution-percentage" label="Percent of premium paid by you"
                                                        required={false}/>}
                                   <Checkbox id="actively-at-work" label="You are currently working" changeCallback={employeeActivelyAtWorkChange}/>
                                   { !activelyAtWork && <DateInput id="date-last-worked" label="Date last worked" required={true}/> }
                               </div>
                           </>}
        >
        </QuestionPanel>
    )

    function employeeTaxationChange(radio: HTMLInputElement) {
        if (radio.value !== 'Post-tax' && setFieldValue) {
            setFieldValue('employeeContributionPercentage', '')
        }
    }

    function employeeActivelyAtWorkChange(target: HTMLInputElement) {
        if(JSON.parse(target.value) && setFieldValue) {
            setFieldValue('dateLastWorked', '')
        }
    }
};
export default Employer;