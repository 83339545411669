import React from 'react';
import { IconSize, ValidIconColor } from "@principal/design-system-icons-react/utils/icon-utilities";
import { themedefault } from '@principal/design-system-tokens';

export type IconWithContentProps = {
    icon: React.FunctionComponent<{ size?: IconSize; color?: ValidIconColor; className?: string | undefined; }>,
    content: React.ReactElement
}

const IconWithContent = (props: IconWithContentProps) => {
    return <div className="pds-u-flex pds-u-padding-vertical-8">
        <span>
            <props.icon size="xxl" color={themedefault.SemanticBorderInteractiveDefault} className="pds-u-inline-block"/>
        </span>
        <span className="pds-u-padding-left-20">
            {props.content}
        </span>
    </div>;
}
export default IconWithContent;