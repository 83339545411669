export enum Mood {
    EUTHYMIC = 'Euthymic',
    EUPHORIC = 'Euphoric',
    DYSPHORIC = 'Dysphoric',
    DEPRESSED = 'Depressed',
    ELEVATED = 'Elevated',
    EXPANSIVE = 'Expansive',
    IRRITABLE = 'Irritable',
    ANXIOUS = 'Anxious',
    DEMANDING = 'Demanding',
    LABILE = 'Labile'
}