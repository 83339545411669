import React, { useEffect } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import { Relationship } from "../../enums/Relationship";
import { PfmlPhysicianFormValues } from "../../pfml/PfmlPhysicianFormValues";
import { PhysicianFormValues } from "../../common/PhysicianFormValues";
import OccupationRequirements from "../../structure/OccupationRequirements";
import RadioGroup from "../../structure/RadioGroup";
import { ReturnToWork } from "../../enums/ReturnToWork";
import NumericInput from "../../input/NumericInput";
import MentalHealthLimitations from "../../structure/MentalHealthLimitations";

export type PhysicianEmploymentProps = {
    initialValues?: PhysicianFormValues
    values?: PhysicianFormValues
    setFieldValue?: Function
}

const PhysicianEmployment = (props: PhysicianEmploymentProps) => {
    const initialValues = props.initialValues as PfmlPhysicianFormValues;
    const values = props.values as PfmlPhysicianFormValues;
    const returnToWorkPartTime = (values as any)?.returnToWork === ReturnToWork.YES_PART_TIME;
    const setFieldValue = props.setFieldValue;

    function returnToWorkChange(radio: HTMLInputElement) {
        if(radio.value !== ReturnToWork.YES_PART_TIME && setFieldValue) {
            setFieldValue('returnToWorkHours', '');
            setFieldValue('returnToWorkDays', '');
        }
    }

    const relationshipIsFamilyMember = Relationship.FAMILY_MEMBER === values?.relationship;

    useEffect(() => {
        if(relationshipIsFamilyMember && setFieldValue) {
            setFieldValue('patientMissWork', 'N/A');
            setFieldValue('patientWorkRestrictions', '');
        }
    }, [setFieldValue, values, relationshipIsFamilyMember])

    return (
        <QuestionPanel headerText="Is the patient's condition preventing them from being able to work?"
                       panelContent={
                           <>
                               <TextInput id="patient-work-restrictions" label="In a typical work day, what are your patient's medical restrictions (sitting, standing, lifting, etc.)?" required={true}/>
                               <MentalHealthLimitations initialValues={initialValues}/>
                               <OccupationRequirements label={initialValues && Object.keys(initialValues).includes("restrictedSittingFrequency")
                                   ? "In a typical work day, your patient is medically restricted to only:" : undefined} idPrefix="restricted" required={false}/>
                               <TextInput id="work-for-different-employer" label="Please explain if your patient can perform their job for a different employer/supervisor."
                                          required={false} maxLength={1024}/>
                               <TextInput id="return-to-work-treatment-plan" label="Please outline how the treatment plan will help return the patient to their work."
                                          required={true} maxLength={1024}/>
                               <RadioGroup id="return-to-work" label="Do you support return to work (with any limitations) at this time?" required={true}
                                           radioProps={Object.keys(ReturnToWork).map(returnToWork => ({
                                               id: returnToWork.replace(/_/g, '-').toLowerCase(),
                                               text: (ReturnToWork as any)[returnToWork],
                                               changeCallback: returnToWorkChange
                                           }))}/>
                               { returnToWorkPartTime && <>
                                   <NumericInput id="return-to-work-hours" label="Hours per day" required={true} maxLength={2}/>
                                   <NumericInput id="return-to-work-days" label="Days per week" required={true} maxLength={1}/>
                               </>}
                           </>}>
        </QuestionPanel>
    )
};
export default PhysicianEmployment;