export function findEnterpriseIdFor(context?: any): string | null {
    if(!context) {
        return null;
    }
    const jwtClaims = context.profile || context.requestContext.authorizer.jwt.claims;
    const enterpriseId = jwtClaims.impersonationBusinessKey || jwtClaims.impersonatedBusinessKey || jwtClaims.pfgenterpriseid;
    if (!/^[A-Z0-9]{9}$/g.test(enterpriseId)) {
        return null;
    }
    return enterpriseId;
}
