import React from 'react';
import Panel from "../../structure/Panel";
import ContactSupport from "../../support/ContactSupport";
import { FormValues } from "../../common/FormValues";
import DownloadClaim from "../../support/DownloadClaim";

export type FinishedProps = {
    values?: FormValues,
}

const Finished = (props: FinishedProps) => {
    const claimNumber = props.values?.claimNumber;
    return (
        <Panel headerText="You made it!"
               panelContent={
                   <div>
                       {claimNumber && <h4>Your claim number is {claimNumber}.</h4>}
                       <h4>You have submitted the employee portion of the claim form. Medical information and information from your employer will need to be provided to complete your claim review. Additional information will be sent to the email address you provided. If you don't see our communication, please check your junk or spam folder.</h4>
                       <DownloadClaim values={props.values}/>
                       <ContactSupport/>
                   </div>}
        >
        </Panel>
    )
};
export default Finished;