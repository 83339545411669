import React from 'react';
import Button from "./Button";
import { PdsIconChevronRight } from "@principal/design-system-icons-react";
import { FormikValues } from "formik";

type NextButtonProps = {
    values?: FormikValues,
    isSubmitting?: boolean,
    submitForm?: Function,
    onClick?: (e: Event) => void,
    children?: React.ReactNode
}

const NextButton = (props: NextButtonProps) => {
    return (
        <Button buttonContent={<><span>Next</span><PdsIconChevronRight slot="icon-right"/></>}
                onClick={async (event) => {
                    await props.submitForm?.()
                    props.onClick?.(event)
                }}
                buttonProps={{ disabled: (props.isSubmitting || props.values?.loading) ? true : undefined }}
        />
    )
};
export default NextButton;