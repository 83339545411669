import { defaultDisabilityPhysicianFormValues, DisabilityPhysicianFormValues } from "./DisabilityPhysicianFormValues";

export type PhysicalIllnessOrInjuryDisabilityPhysicianFormValues = DisabilityPhysicianFormValues & {
    restrictedSittingFrequency: string,
    restrictedStandingFrequency: string,
    restrictedWalkingFrequency: string,
    restrictedKeyboardingFrequency: string,
    restrictedFineManipulationFrequency: string,
    restrictedLiftingContinuouslyPounds: string,
    restrictedLiftingFrequentlyPounds: string,
    restrictedLiftingOccasionallyPounds: string,
}

export const defaultPhysicalIllnessOrInjuryDisabilityPhysicianFormValues = {
    ...defaultDisabilityPhysicianFormValues,
    restrictedSittingFrequency: '',
    restrictedStandingFrequency: '',
    restrictedWalkingFrequency: '',
    restrictedKeyboardingFrequency: '',
    restrictedFineManipulationFrequency: '',
    restrictedLiftingContinuouslyPounds: '',
    restrictedLiftingFrequentlyPounds: '',
    restrictedLiftingOccasionallyPounds: '',
}