import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import Checkbox from "../../input/Checkbox";
import AddListElementButton from "../../button/AddListElementButton";
import DiagnosisCode from "../../input/DiagnosisCode";
import { PhysicianFormValues } from '../../common/PhysicianFormValues';
import { PfmlPhysicianFormValues } from "../../pfml/PfmlPhysicianFormValues";
import RadioGroup from "../../structure/RadioGroup";
import { PhysicianWorkRelated } from "../../enums/PhysicianWorkRelated";
import { TypeOfClaim } from "../../enums/TypeOfClaim";
import MiniMentalStateExamination from "../../structure/MiniMentalStateExamination";
import DateRange from "../../input/DateRange";
import HorizontalRule from "../../structure/HorizontalRule";

export type ConditionProps = {
    initialValues?: PhysicianFormValues
    values?: PhysicianFormValues
    setFieldValue?: Function
}

const Condition = (props: ConditionProps) => {
    const initialValues = props.initialValues;

    return (
        <QuestionPanel headerText="What is your patient's condition?"
                       detailsText="If you begin typing the ICD 10 code, options will be presented to auto-fill the diagnosis."
                       panelContent={<>
                           <AddListElementButton {...props} idPrefix="diagnoses" componentType={DiagnosisCode}
                                                 elementInitialValue={{diagnosisCode: '', diagnosis: ''}}
                                                 label="Please add all known diagnoses, symptoms, and complications."
                                                 buttonText="Add Diagnosis"/>
                           <MiniMentalStateExamination initialValues={initialValues}/>
                           <RadioGroup id="work-related" label="Your patient's employment caused their condition:"
                                       required={true}
                                       radioProps={Object.keys(PhysicianWorkRelated).map(workRelated => ({
                                           id: workRelated.replace(/_/g, '-').toLowerCase(),
                                           text: (PhysicianWorkRelated as any)[workRelated]
                                       }))}/>
                           <HorizontalRule/>
                           {initialValues && Object.keys(initialValues).includes("permanentOrLongTermCondition")
                               && <div className="pds-u-margin-bottom-16">
                                   <label className="is-required">Serious health condition type</label>
                           </div>}
                           <Checkbox id="inpatient-care"
                                     label="Hospitalized or received inpatient care."
                                     changeCallback={(target: HTMLInputElement) => {
                                         if (!target.checked && props.setFieldValue) {
                                             props.setFieldValue("facilityName", "")
                                             props.setFieldValue("inpatientCareStartDate", "")
                                             props.setFieldValue("inpatientCareEndDate", "")
                                         }
                                     }}/>
                           {
                               (props.values as PfmlPhysicianFormValues)?.inpatientCare &&
                                   <>
                                       <TextInput id="facility-name" label="Facility name" required={true}/>
                                       <DateRange idPrefix="inpatient-care"/>
                                   </>
                           }
                           <Checkbox id="permanent-or-long-term-condition"
                                     label="Permanent or long-term condition for which the patient is under continuing supervision of a health care provider but for which treatment may not be effective (e.g., Alzheimer’s, a severe stroke)."/>
                           <Checkbox id="multiple-treatments-and-recovery"
                                     label="Out of work to undergo multiple treatments and related recovery for one of the following: (1) restorative surgery after an accident or other injury or (2) a condition that would likely result in a period of incapacity of more than three (3) full, consecutive calendar days in the absence of such treatment."/>
                           <Checkbox id="chronic"
                                     label="A chronic health condition that continues over an extended period of time and BOTH: (1) requires periodic visits for treatment by a health care provider (at least two (2) visits per year) and (2) may cause episodic incapacity or flare-ups or would cause periods of reoccurrence without treatment (e.g. asthma, diabetes, epilepsy, etc.)."/>
                           <Checkbox id="pregnancy" label="Pregnancy or prenatal care."/>
                           <Checkbox id="incapacity-and-treatment"
                                     label="Incapacity and Treatment: treatment 2 or more times within 30 calendar days of the first day of incapacity; or treatment on at least one occasion that results in a regimen of continuing treatment."/>
                           {props.values?.claimType === TypeOfClaim.MILITARY
                               && <RadioGroup id="patient-military-service" label="Is the patient's condition connected to their military service?" required={true}
                                              radioProps={[{id: "yes", text: "Yes"}, {id: "no", text: "No"}]}/>
                           }
                           <TextInput id="suicidal" label="Explain any suicidal ideation, plan, intent, or attempts:" required={false} maxLength={1024}/>
                           <HorizontalRule/>
                           <RadioGroup id="patient-competent" label="Is patient competent to endorse checks and direct the use of those proceeds?" required={true}
                                       radioProps={[{id: "yes", text: "Yes"}, {id: "no", text: "No"}]}/>
                       </>}
        >
        </QuestionPanel>
    )
};
export default Condition;