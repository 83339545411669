import React from 'react';
import { PhysicianFormValues } from "../common/PhysicianFormValues";
import { MentalHealthDisabilityPhysicianFormValues } from "../disability/MentalHealthDisabilityPhysicianFormValues";
import TextInput from "../input/TextInput";
import HorizontalRule from "./HorizontalRule";

export type MentalHealthLimitationsProps = {
    initialValues?: PhysicianFormValues
}

const MentalHealthLimitations = (props: MentalHealthLimitationsProps) => {
    return <div>
        {(props.initialValues as MentalHealthDisabilityPhysicianFormValues)?.restrictedJobDuties !== undefined && <>
            <HorizontalRule/>
            <label>Please describe all of your patient's functional limitations in each area and how they are affected:</label>
        </>}
        <TextInput id="restricted-job-duties" label="Job duties" required={false} maxLength={1024}/>
        <TextInput id="restricted-physical" label="Physical" required={false} maxLength={1024}/>
        <TextInput id="restricted-family" label="Family" required={false} maxLength={1024}/>
        <TextInput id="restricted-social" label="Social" required={false} maxLength={1024}/>
        <TextInput id="restricted-daily-living" label="Activities of daily living" required={false} maxLength={1024}/>
    </div>
};

export default MentalHealthLimitations;
