import { FormValues, defaultFormValues } from "./FormValues";
import { DateTime } from "luxon";

export type BeneficiaryFormValues = FormValues & {
    personFilingIsBeneficiary: string,
    beneficiaryFirstName: string,
    beneficiaryLastName: string,
    beneficiaryDateOfBirth: string,
    personFilingFirstName: string,
    personFilingLastName: string,
    personFilingPhoneNumber: string,
    beneficiaryEmailAddress: string,
    beneficiaryPhoneNumber: string,
    beneficiaryStreetAddress: string,
    beneficiaryCity: string,
    beneficiaryState: string,
    beneficiaryZipCode: string,
    deceasedFirstName: string,
    deceasedLastName: string,
    deceasedDateOfBirth: string,
    deceasedRelationship: string,
    deceasedOtherRelationship: string,
    deceasedIsEmployee: string,
    makingClaimTo: string,
    isUnitedStatesPerson: string,
    hasBackupWithholding: string,
    benefitClaimant: string,
    taxIdentificationNumber: string,
    taxPayerDisclaimerAcknowledged: boolean,
    beneficiarySignature: string,
    beneficiarySignatureDate: string,
    fraud: boolean,
    fraudContinue: boolean
}

export const defaultBeneficiaryFormValues = {
    ...defaultFormValues,
    personFilingIsBeneficiary: '',
    beneficiaryFirstName: '',
    beneficiaryLastName: '',
    beneficiaryDateOfBirth: '',
    personFilingFirstName: '',
    personFilingLastName: '',
    personFilingPhoneNumber: '',
    beneficiaryEmailAddress: '',
    beneficiaryPhoneNumber: '',
    beneficiaryStreetAddress: '',
    beneficiaryCity: '',
    beneficiaryState: '',
    beneficiaryZipCode: '',
    deceasedFirstName: '',
    deceasedLastName: '',
    deceasedDateOfBirth: '',
    deceasedRelationship: '',
    deceasedOtherRelationship: '',
    deceasedIsEmployee: '',
    makingClaimTo: '',
    isUnitedStatesPerson: '',
    hasBackupWithholding: '',
    benefitClaimant: '',
    taxIdentificationNumber: '',
    taxPayerDisclaimerAcknowledged: false,
    beneficiarySignature: '',
    beneficiarySignatureDate: DateTime.now().toFormat('MM/dd/yyyy'),
    fraud: false,
    fraudContinue: false
}
