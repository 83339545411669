import React from 'react';
import { FormikConfig, FormikValues } from "formik";

export interface FormikStepProps
    extends Pick<FormikConfig<FormikValues>, 'validationSchema' | 'validate'> {
    label: string;
    values?: FormikValues;
    steps?: React.ReactElement<FormikStepProps>[];
    stepChangeCallback?: Function;
    hideStep?: (values?: FormikValues) => boolean;
    children: React.ReactElement;
}

export function FormikStep(props: FormikStepProps) {
    return <>{React.cloneElement(props.children, {...props})}</>;
}