export enum PregnancyDiagnosis {
    VAGINAL = 'O80',
    C_SECTION = 'O82'
}

export function findPregnancyDiagnosisDescriptionFor(pregnancyDiagnosis: PregnancyDiagnosis) {
    if(pregnancyDiagnosis === PregnancyDiagnosis.VAGINAL) {
        return 'Encounter for full-term uncomplicated delivery'
    } else if(pregnancyDiagnosis === PregnancyDiagnosis.C_SECTION) {
        return 'Encounter for cesarean delivery without indication'
    }
    return null;
}
