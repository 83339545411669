import React from 'react';
import { Relationship } from "../../enums/Relationship";
import ClaimType from "../common/ClaimType";
import { PhysicianFormValues } from "../../common/PhysicianFormValues";
import { TypeOfClaim } from "../../enums/TypeOfClaim";
import { defaultPregnancyDisabilityPhysicianFormValues } from "../../disability/PregnancyDisabilityPhysicianFormValues";
import _ from "lodash";
import {
    defaultPhysicalIllnessOrInjuryDisabilityPhysicianFormValues
} from "../../disability/PhysicalIllnessOrInjuryDisabilityPhysicianFormValues";
import { defaultMentalHealthDisabilityPhysicianFormValues } from "../../disability/MentalHealthDisabilityPhysicianFormValues";

export type PhysicianClaimTypeProps = {
    values?: PhysicianFormValues,
    setFieldValue?: Function,
    resetForm?: Function,
}

const PhysicianClaimType = (props: PhysicianClaimTypeProps) => {
    const values = props.values;
    const setFieldValue = props.setFieldValue;
    const resetForm = props.resetForm;

    const claimType = values?.claimType as TypeOfClaim;
    React.useEffect(() => {
        if(claimType === TypeOfClaim.PREGNANCY) {
            resetFormFor(defaultPregnancyDisabilityPhysicianFormValues);
        } else if([TypeOfClaim.PHYSICAL_ILLNESS, TypeOfClaim.INJURY].includes(claimType)) {
            resetFormFor(defaultPhysicalIllnessOrInjuryDisabilityPhysicianFormValues);
        } else if(claimType === TypeOfClaim.MENTAL_HEALTH) {
            resetFormFor(defaultMentalHealthDisabilityPhysicianFormValues);
        }
        // eslint-disable-next-line
    }, [claimType, resetForm])

    const patientIsEmployee = values?.relationship === Relationship.EMPLOYEE;
    const patientFirstName = values?.patientFirstName;
    const patientLastName = values?.patientLastName;
    React.useEffect(() => {
        if(patientIsEmployee && setFieldValue && patientFirstName) {
            setFieldValue('firstName', patientFirstName);
            setFieldValue('lastName', patientLastName);
            setFieldValue('patientRelationship', 'The patient themselves');
            setFieldValue('patientRequiresCare', 'Yes');
        }
    }, [setFieldValue, patientIsEmployee, patientFirstName, patientLastName])

    return (
        <ClaimType {...props}
                   headerText="What type of claim is being requested?"
                   onlyShowMedicalRelatedLeaveTypes={true}
                   skipLeaveTypeServiceCall={true}
                   children={<></>}
        />
    )

    function resetFormFor(defaultValues: any) {
        resetForm?.({
            values: leftJoin(defaultValues, values)
        })
    }

    function leftJoin(a: any, b: any) {
        return {
            ...a,
            ..._.pick(b, Object.keys(b).filter(k => Object.keys(a).includes(k)))
        };
    }
};
export default PhysicianClaimType;