import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import DateInput from "../../input/DateInput";
import NumericInput from "../../input/NumericInput";
import { EmployerFormValues } from "../../common/EmployerFormValues";
import { WorkSchedule } from "../../enums/WorkSchedule";
import Checkbox from "../../input/Checkbox";
import { DisabilityEmployerFormValues } from "../../disability/DisabilityEmployerFormValues";
import RadioGroup from "../../structure/RadioGroup";
import DecimalInput from "../../input/DecimalInput";
import {LifeEmployerFormValues} from "../../life/LifeEmployerFormValues";
import SelectDropdown from "../../input/SelectDropdown";
import { Relationship } from "../../enums/Relationship";
import { WorkScheduleFrequency } from "../../enums/WorkScheduleFrequency";
import { DayOfWeek } from "../../enums/DayOfWeek";

export type EmploymentProps = {
    values?: EmployerFormValues,
    setFieldValue?: Function
}

const Employment = (props: EmploymentProps) => {
    const values = props.values;
    const setFieldValue = props.setFieldValue;
    const isCoveredDependent = Relationship.COVERED_DEPENDENT === values?.relationship;
    const notActivelyAtWork = !isCoveredDependent || ["No", undefined].includes((values as any as LifeEmployerFormValues)?.activelyAtWork);
    const rotatingWorkSchedule = values?.workSchedule === WorkSchedule.ROTATING;

    return (
        <QuestionPanel headerText="When does your employee work?"
                       detailsText="Your employee's work schedule will determine their benefit payment, and their employment dates determine the eligibility and length of their claim."
                       panelContent={
                           <>
                               <div>
                                   <RadioGroup id="work-schedule" label="My employee works:" required={true} radioProps={[
                                       { id: "forty-hour", text: WorkSchedule.FORTY_HOUR, changeCallback: defaultFortyHourWorkWeek },
                                       { id: "regular", text: WorkSchedule.REGULAR, changeCallback: defaultBlankWorkWeek },
                                       { id: "rotating", text: WorkSchedule.ROTATING, changeCallback: defaultTwoBlankWorkWeeks },
                                       { id: "alternative", text: WorkSchedule.ALTERNATIVE, changeCallback: defaultEmptyWorkWeek }
                                   ]}/>
                                   {[WorkSchedule.REGULAR, WorkSchedule.ROTATING].includes(values?.workSchedule as WorkSchedule) && <>
                                       <label>Please enter the number of hours the employee works each day{rotatingWorkSchedule ? ' for each week (odd weeks on first row, even weeks on second row)' : ''}.</label>
                                       <table className="pds-u-margin-bottom-0">
                                           <tbody>
                                               {buildDayInputs()}
                                               {rotatingWorkSchedule && buildDayInputs(1)}
                                           </tbody>
                                       </table>
                                   </>}
                                   {WorkSchedule.ALTERNATIVE === values?.workSchedule && <>
                                       <SelectDropdown id="alternative-schedule-frequency"
                                                    label="Is your employee provided their work schedule:"
                                                    required={true}
                                                    options={Object.keys(WorkScheduleFrequency).map(frequency => ({
                                                        id: frequency.replace(/_/g, '-').toLowerCase(),
                                                        text: (WorkScheduleFrequency as any)[frequency]
                                                    }))}/>
                                       <DecimalInput id="average-hours-worked-per-week" label="Average hours worked per week" required={true} maxLength={5}/>
                                       <SelectDropdown id="average-days-worked-per-week"
                                                       label="Which days does your employee work the average hours above per week?"
                                                       required={true}
                                                       multiple={true}
                                                       options={Object.keys(DayOfWeek).map(day => ({
                                                           id: day.replace(/_/g, '-').toLowerCase(),
                                                           text: (DayOfWeek as any)[day]
                                                       }))}/>
                                   </>}
                                   <DecimalInput id="hours-worked-per-week" label="Hours worked per week" required={true} maxLength={5}/>
                                   <DateInput id="date-of-hire" label="Date of hire" required={true}/>
                                   {isCoveredDependent &&
                                       <RadioGroup id="actively-at-work" label="Is your employee currently working?" required={true}
                                                   radioProps={[
                                                       {
                                                           id: "yes", text: "Yes", changeCallback: () => {
                                                               props.setFieldValue?.('reasonCeasedActiveWork', '')
                                                               props.setFieldValue?.('dateLastWorked', '')
                                                           }
                                                       },
                                                       { id: "no", text: "No" }]}/>}
                                   {notActivelyAtWork &&
                                       <>
                                           <SelectDropdown id="reason-ceased-active-work" label="Reason employee ceased active work"
                                                           required={true} options={[
                                               { id: "death", text: "Death" },
                                               { id: "sickness", text: "Sickness" },
                                               { id: "accident", text: "Accident" },
                                               { id: "pto/vacation", text: "PTO/Vacation" },
                                               { id: "terminated", text: "Terminated" },
                                               { id: "layoff", text: "Layoff" },
                                               { id: "retired", text: "Retired" },
                                               { id: "absence", text: "Leave of absence" },
                                               { id: "fmla", text: "FMLA" },
                                               { id: "resignation", text: "Resignation" },
                                               { id: "sabbatical", text: "Sabbatical" },
                                               { id: "other", text: "Other" }]}/>
                                           <DateInput id="date-last-worked" label="Date last worked" required={true}/>
                                       </>}
                                   <Checkbox id="normal-schedule-on-date-last-worked" label="Employee worked a normal schedule on date last worked"
                                             changeCallback={(target: HTMLInputElement) => {
                                                 if(target.checked) {
                                                     props.setFieldValue?.('hoursWorkedOnDateLastWorked', '')
                                                 }
                                             }}/>
                                   {(values as DisabilityEmployerFormValues)?.normalScheduleOnDateLastWorked === false
                                       && <NumericInput id="hours-worked-on-date-last-worked" label="Hours worked on date last worked" maxLength={2} required={true}/>}
                                   <DateInput id="date-returned-to-work" label="Date returning to work" required={false}/>
                                   {values?.dateReturnedToWork
                                       && <Checkbox id="normal-schedule-on-returned-to-work" label="Employee returned to their normal work schedule after returning to work"/>}
                               </div>
                           </>}
        >
        </QuestionPanel>
    )

    function defaultFortyHourWorkWeek() {
        setFieldValue?.('weeklyWorkSchedule', [{
            sunday: '0',
            monday: '8',
            tuesday: '8',
            wednesday: '8',
            thursday: '8',
            friday: '8',
            saturday: '0',
        }]);
        defaultNonAlternativeWorkWeek()
    }

    function defaultTwoBlankWorkWeeks() {
        setFieldValue?.('weeklyWorkSchedule', [{
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
        }, {
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
        }]);
        defaultNonAlternativeWorkWeek()
    }

    function defaultBlankWorkWeek() {
        setFieldValue?.('weeklyWorkSchedule', [{
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
        }]);
        defaultNonAlternativeWorkWeek()
    }

    function defaultEmptyWorkWeek() {
        setFieldValue?.('weeklyWorkSchedule', [])
    }

    function defaultNonAlternativeWorkWeek() {
        setFieldValue?.('alternativeScheduleFrequency', '')
        setFieldValue?.('averageHoursWorkedPerWeek', '')
        setFieldValue?.('averageDaysWorkedPerWeek', '')
    }
};

function buildDayInputs(index: number = 0) {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const labels = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
    let dayInputs = [];
    for (let i = 0; i < labels.length; ++i) {
        const day = days[i]
        const abbreviation = labels[i]
        dayInputs.push(
            <td>
                <DecimalInput id={`weekly-work-schedule-${day}-${index}`} name={`weeklyWorkSchedule[${index}].${day}`}
                              required={false} maxLength={5} label={abbreviation}/>
            </td>)
    }
    return <>
        <tr>
            {dayInputs}
        </tr>
    </>;
}

export default Employment;