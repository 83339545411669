import {FormValues} from "./FormValues";
import {BeneficiaryFormValues} from "./BeneficiaryFormValues";
import _ from "lodash";

export enum PartOfSpeech {
    NOUN,
    ADJECTIVE,
    PRONOUN,
    OBJECT_PRONOUN
}

export function findPossessiveFor(values?: FormValues, lowercase: boolean = false, includeDefiniteArticle: boolean = false, partOfSpeech: PartOfSpeech | undefined = undefined) {
    if(!values) {
        return null;
    }
    let possessive = includeDefiniteArticle ? `the ${values.party}` : values.party;
    const beneficiaryFormValues = values as BeneficiaryFormValues;
    if(beneficiaryFormValues.personFilingIsBeneficiary === 'Yes') {
        possessive = 'your';
        if(PartOfSpeech.PRONOUN === partOfSpeech) {
            possessive = 'you';
        } else if(PartOfSpeech.OBJECT_PRONOUN === partOfSpeech) {
            possessive = 'me';
        }
    } else if(PartOfSpeech.ADJECTIVE === partOfSpeech) {
        possessive = 'their';
    } else if(PartOfSpeech.NOUN === partOfSpeech) {
        possessive += `'s`;
    }
    return lowercase ? possessive : _.capitalize(possessive);
}

export function findPossessiveWithStartingVerbFor(values?: FormValues, lowercase: boolean = false, partOfSpeech: PartOfSpeech | undefined = undefined) {
    if(!values) {
        return null;
    }
    const beneficiaryFormValues = values as BeneficiaryFormValues;
    const possessive = findPossessiveFor(values, lowercase, true, partOfSpeech) ?? '';

    const startingVerb = beneficiaryFormValues.personFilingIsBeneficiary === 'Yes' ? 'are' : 'is';
    return lowercase ? `${startingVerb} ${possessive}` : _.capitalize(`${startingVerb} ${possessive}`);
}
