import React, { useEffect } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import { FormikErrors } from "formik";
import PhoneInput from "../../input/PhoneInput";
import EmailInput from "../../input/EmailInput";
import { findPossessiveFor, PartOfSpeech } from "../../common/PersonalLanguage";
import { BeneficiaryFormValues } from "../../common/BeneficiaryFormValues";
import TextInput from "../../input/TextInput";
import StateDropdown from "../../input/StateDropdown";
import ZipCodeInput from "../../input/ZipCodeInput";
import TwoColumnDiv from "../../structure/TwoColumnDiv";
import { ValidateAddressResponse } from "../../../service/AddressService";
import AddressModal from "../../structure/AddressModal";
import HorizontalRule from "../../structure/HorizontalRule";

export type ContactBeneficiaryProps = {
    values?: BeneficiaryFormValues,
    errors?: FormikErrors<any>,
    setFieldValue?: Function,
    validateForm?: Function,
    submitForm?: Function,
    children?: React.ReactNode;
}

const ContactBeneficiary = (props: ContactBeneficiaryProps) => {
    const setFieldValue = props.setFieldValue;
    useEffect(() => {
        if(setFieldValue) {
            setFieldValue('useEnteredAddress', false, false)
        }
    }, [setFieldValue])

    const enteredAddress = {
        streetAddress: props.values?.beneficiaryStreetAddress,
        city: props.values?.beneficiaryCity,
        state: props.values?.beneficiaryState,
        zipCode: props.values?.beneficiaryZipCode
    } as ValidateAddressResponse;

    const nounPossessiveWithArticle = findPossessiveFor(props.values, true, true, PartOfSpeech.NOUN);
    const adjectivePossessive = findPossessiveFor(props.values, true, undefined, PartOfSpeech.ADJECTIVE);
    const capitalPossessive = findPossessiveFor(props.values);
    return (
        <>
            <AddressModal {...props} enteredAddress={enteredAddress}
                          correctedAddress={(props.errors?.beneficiaryStreetAddress as any)?.message as any}
                          streetAddressField="beneficiaryStreetAddress" cityField="beneficiaryCity" stateField="beneficiaryState"
                          zipCodeField="beneficiaryZipCode"/>
            <QuestionPanel headerText={`What is ${nounPossessiveWithArticle} contact information?`}
                           detailsText={`Your privacy is important to us. We'll only use ${nounPossessiveWithArticle} email for official communication regarding ${adjectivePossessive} claim.`}
                           panelContent={
                               <div>
                                   <EmailInput id="beneficiary-email-address" label={`${capitalPossessive} email`} required={true}/>
                                       <PhoneInput id="beneficiary-phone-number" label={`${capitalPossessive} phone number`} required={true}/>
                                       <HorizontalRule/>
                                       <TextInput id="beneficiary-street-address" label={`${capitalPossessive} street address`} required={true}/>
                                       <TwoColumnDiv leftContent={<TextInput id="beneficiary-city" label={`${capitalPossessive} city`} required={true}/>}
                                                     rightContent={<TwoColumnDiv
                                                        leftContent={<StateDropdown id="beneficiary-state" label={`${capitalPossessive} state`} required={true}/>}
                                                        rightContent={<ZipCodeInput id="beneficiary-zip-code" label={`${capitalPossessive} ZIP`} required={true}/>}>
                                                 </TwoColumnDiv>}/>
                                   </div>
                               }
            >
            </QuestionPanel>
        </>
    )
};

export default ContactBeneficiary;