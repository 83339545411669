import { defaultLifeFormValues, LifeFormValues } from "./LifeFormValues";
import { defaultEmployerFormValues, EmployerFormValues } from "../common/EmployerFormValues";
import _ from "lodash";

export type LifeEmployerFormValues = Omit<EmployerFormValues, 'phoneNumber' | 'workState' | 'workStateSameAsResidencyState' | 'jobTitle' | 'workSchedule' | 'dateReturnedToWork' | 'workRelated' | 'payAfterDateLastWorked' | 'fullPayAfterDateLastWorked' | 'fullPayAfterDateLastWorkedEndDate' | 'payAfterDateLastWorkedAmount'> & LifeFormValues & {
    deceasedFirstName: string,
    deceasedLastName: string,
    contactName: string,
    contactPhoneNumber: string,
    contactRelationship: string,
    premiumsPaidThroughDeath: string,
    datePremiumsPaidThrough: string,
    hoursWorkedPerWeek: string,
    activelyAtWork: string,
    reasonCeasedActiveWork: string,
    employeeOwnership: boolean,
    employeeOwnershipPercentage: string,
    benefits: [{
        claimed: string,
        amount: string,
    }],
    beneficiaryDesignationStatus: string,
    thirdPartyContactName: string,
    thirdPartyContactEmailAddress: string
}

export const defaultLifeEmployerFormValues = {
    ..._.omit(defaultEmployerFormValues, ['phoneNumber', 'workState', 'workStateSameAsResidencyState', 'jobTitle', 'workSchedule','dateReturnedToWork', 'workRelated', 'payAfterDateLastWorked', 'fullPayAfterDateLastWorked', 'fullPayAfterDateLastWorkedEndDate', 'payAfterDateLastWorkedAmount']),
    ...defaultLifeFormValues,
    deceasedFirstName: '',
    deceasedLastName: '',
    contactName: '',
    contactPhoneNumber: '',
    contactRelationship: '',
    premiumsPaidThroughDeath: '',
    datePremiumsPaidThrough: '',
    hoursWorkedPerWeek: '',
    activelyAtWork: '',
    reasonCeasedActiveWork: '',
    employeeOwnership: false,
    employeeOwnershipPercentage: '',
    benefits: [{
        claimed: '',
        amount: ''
    }],
    beneficiaryDesignationStatus: '',
    thirdPartyContactName: '',
    thirdPartyContactEmailAddress: ''
}