import React from 'react';
import { BeneficiaryFormValues } from "../../common/BeneficiaryFormValues";
import Document from "../common/Document";
import { DocumentType } from "../../enums/DocumentType";
import { MannerOfDeath } from "../../enums/MannerOfDeath";

export type BeneficiaryDocumentProps = {
    initialValues?: BeneficiaryFormValues,
    values?: BeneficiaryFormValues
    setFieldValue?: Function,
    setFieldError?: Function
}

const BeneficiaryDocument = (props: BeneficiaryDocumentProps) => {
    const isAccident = props.values?.claimType === MannerOfDeath.ACCIDENT;
    let documentDescription;
    let documentTypeOptions = [
        DocumentType.DEATH_CERTIFICATE
    ];
    let documentHeader = 'a death certificate';
    if (isAccident) {
        const accidentDocumentTypeOptions = [
            DocumentType.INCIDENT,
            DocumentType.AUTOPSY,
            DocumentType.NEWSPAPER,
            DocumentType.POLICE_DEPARTMENT_INFO,
            DocumentType.TRANSPORTATION_BILL,
            DocumentType.HIPAA_AUTH
        ]
        documentTypeOptions = documentTypeOptions.concat(accidentDocumentTypeOptions)
        documentDescription = <>
            If accidental death/personal loss benefits are being claimed, in addition to the death certificate, one or more of the following may be needed:
            <ul>
                {accidentDocumentTypeOptions.map(d => (
                    <li>{d}</li>
                ))}
            </ul><br/>
            For a transportation bill, please upload the bill showing the incurred cost for preparation and transportation of the body if the death occurs more than 100 miles from permanent residence.
        </>
        documentHeader += ' or any supporting accident documents';
    }

    return (
        <Document {...props} documentTypeOptions={documentTypeOptions} documentHeader={documentHeader} documentDescription={documentDescription}/>
    )
};

export default BeneficiaryDocument;