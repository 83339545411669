import React from 'react';
import SelectDropdown from "../input/SelectDropdown";
import { PhysicianFormValues } from "../common/PhysicianFormValues";
import { MentalHealthDisabilityPhysicianFormValues } from "../disability/MentalHealthDisabilityPhysicianFormValues";
import { Mood } from "../enums/Mood";
import { Affect } from "../enums/Affect";
import { PsychomotorActivity } from "../enums/PsychomotorActivity";
import { Orientation } from "../enums/Orientation";
import { Concentration } from "../enums/Concentration";
import { Memory } from "../enums/Memory";
import HorizontalRule from "./HorizontalRule";

export type MiniMentalStateExaminationProps = {
    initialValues?: PhysicianFormValues
}

const MiniMentalStateExamination = (props: MiniMentalStateExaminationProps) => {
    return <div>
        {(props.initialValues as MentalHealthDisabilityPhysicianFormValues)?.mood !== undefined && <>
            <HorizontalRule/>
            <label>Mental status examination</label>
        </>}
        <SelectDropdown id="mood" label="Mood" required={true} options={Object.keys(Mood).map(p => ({
            id: p.replace(/_/g, '-').toLowerCase(),
            text: (Mood as any)[p]
        }))}/>
        <SelectDropdown id="affect" label="Affect" required={true} options={Object.keys(Affect).map(p => ({
            id: p.replace(/_/g, '-').toLowerCase(),
            text: (Affect as any)[p]
        }))}/>
        <SelectDropdown id="psychomotor-activity" label="Psychomotor activity" required={true} options={Object.keys(PsychomotorActivity).map(p => ({
            id: p.replace(/_/g, '-').toLowerCase(),
            text: (PsychomotorActivity as any)[p]
        }))}/>
        <SelectDropdown id="orientation" label="Orientation" required={false} multiple={true} options={Object.keys(Orientation).map(p => ({
            id: p.replace(/_/g, '-').toLowerCase(),
            text: (Orientation as any)[p]
        }))}/>
        <SelectDropdown id="concentration" label="Concentration" required={true} options={Object.keys(Concentration).map(p => ({
            id: p.replace(/_/g, '-').toLowerCase(),
            text: (Concentration as any)[p]
        }))}/>
        <SelectDropdown id="memory" label="Memory" required={true} options={Object.keys(Memory).map(p => ({
            id: p.replace(/_/g, '-').toLowerCase(),
            text: (Memory as any)[p]
        }))}/>
    </div>
};

export default MiniMentalStateExamination;
