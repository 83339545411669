import React from 'react';
import { PhysicianFormValues } from "../../common/PhysicianFormValues";
import Document from "../common/Document";
import { DocumentType } from "../../enums/DocumentType";

export type PhysicianDocumentProps = {
    values?: PhysicianFormValues
    setFieldValue?: Function,
    setFieldError?: Function
}

const PhysicianDocument = (props: PhysicianDocumentProps) => {
    return (
        <Document {...props} documentTypeOptions={[DocumentType.PHYSICIAN_STATEMENT, DocumentType.MEDICAL_RECORDS]}/>
    )
};

export default PhysicianDocument;