import React, { useEffect } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import SelectDropdown from "../../input/SelectDropdown";
import { addSuperscriptTo, TypeOfClaim } from "../../enums/TypeOfClaim";
import TextInput from "../../input/TextInput";
import DateInput from "../../input/DateInput";
import { ChildRelationship } from "../../enums/ChildRelationship";
import { MilitaryLeaveReason } from "../../enums/MilitaryLeaveReason";
import { EmployeeFormValues } from "../../common/EmployeeFormValues";
import { PfmlEmployerFormValues } from "../../pfml/PfmlEmployerFormValues";
import { PfmlPhysicianFormValues } from "../../pfml/PfmlPhysicianFormValues";
import axios from "axios";
import { Medical } from "../../enums/Medical";
import { ClaimPlan } from "../../domain/ClaimPlan";
import Checkbox from "../../input/Checkbox";
import LoadingSpinner from "../../structure/LoadingSpinner";
import { BenefitType, findClaimTypeLabelFor, findDefaultLeaveTypesFor, findHelpUrlFor } from "../../enums/BenefitType";
import { EmployerFormValues } from "../../common/EmployerFormValues";
import { DisabilityProduct } from "../../enums/DisabilityProduct";
import { PhysicianFormValues } from "../../common/PhysicianFormValues";
import { PregnancyDeliveryType } from "../../enums/PregnancyDeliveryType";
import Tooltip from "../../input/Tooltip";
import { DateTime } from "luxon";
import TimeInput from "../../input/TimeInput";
import RadioGroup from "../../structure/RadioGroup";
import { ScreeningType } from "../../enums/ScreeningType";
import PhoneInput from "../../input/PhoneInput";
import { getKeyByValue } from "../../enums/KeyFinder";
import { Party } from '../../enums/Party';
import { MannerOfDeath } from "../../enums/MannerOfDeath";
import {BeneficiaryFormValues} from "../../common/BeneficiaryFormValues";
import { Footnote } from "../../enums/Footnote";

export type ClaimTypeProps = {
    values?: EmployeeFormValues | EmployerFormValues | PhysicianFormValues | BeneficiaryFormValues,
    setFieldValue?: Function,
    isSubmitting?: boolean,
    headerText: string,
    detailsText?: string | React.ReactElement,
    showAdditionalInformation?: boolean,
    onlyShowMedicalRelatedLeaveTypes?: boolean,
    skipLeaveTypeServiceCall?: boolean,
    children?: React.ReactNode
}

const ClaimType = (props: ClaimTypeProps) => {
    const values = props.values as any;
    const setFieldValue = props.setFieldValue;
    const onlyShowMedicalRelatedLeaveTypes = props.onlyShowMedicalRelatedLeaveTypes;
    const claimType = values?.claimType;
    const benefit = values?.benefit;
    const party = values?.party;
    const relationship = values?.relationship;

    const defaultLeavePlans = findDefaultLeaveTypesFor(benefit);
    const [leavePlans, setLeavePlans] = React.useState(defaultLeavePlans);
    const [leavePlansLoaded, setLeavePlansLoaded] = React.useState(false);

    const policyNumber = values?.policyNumber;
    const employerName = values?.employerName;
    const employerZipCode = values?.employerZipCode;
    const employerEmailAddress = values?.employerEmailAddress;
    const residencyState = values?.state;
    const workState = values?.workState;
    const claimDates = values?.claimDates;
    const dateLastWorked = values?.dateLastWorked;
    const skipLeaveTypeServiceCall = props.skipLeaveTypeServiceCall;
    const isWellnessBenefit = BenefitType.WELLNESS === benefit;
    const isSummary = props.values?.currentStep === 'Summary';
    const loadLeavePlans = (workState && !skipLeaveTypeServiceCall && BenefitType.PFML === benefit)
        || (residencyState && !skipLeaveTypeServiceCall && BenefitType.WELLNESS === benefit);
    const loading = loadLeavePlans && !leavePlansLoaded && !isSummary

    React.useEffect(() => {
        async function findLeavePlans() {
            const url = `${process.env.REACT_APP_FORMS_SERVICE_URL}/leavePlans`;
            if (loadLeavePlans) {
                try {
                    setLeavePlansLoaded(false);
                    const response = await axios.post(url, {
                        benefit: benefit,
                        policyNumber: policyNumber,
                        employerName: employerName,
                        employerZipCode: employerZipCode,
                        employerEmailAddress: employerEmailAddress,
                        residencyState: residencyState,
                        workState: workState,
                        claimDates: claimDates,
                        dateLastWorked: dateLastWorked
                    });

                    const leavePlans = response.data;
                    if (Array.isArray(leavePlans) && leavePlans.length) {
                        setLeavePlans(leavePlans);
                        setLeavePlansLoaded(true);
                    }
                } catch(ignored) {
                    setLeavePlansLoaded(true);
                }
            }
        }
        findLeavePlans();
    }, [skipLeaveTypeServiceCall, policyNumber, employerName, employerZipCode,
        employerEmailAddress, workState, claimDates, dateLastWorked, benefit, residencyState, isWellnessBenefit, loadLeavePlans]);

    React.useEffect(() => {
        defaultClaimTypeFields(leavePlans, claimType, setFieldValue, onlyShowMedicalRelatedLeaveTypes)
    }, [leavePlans, claimType, setFieldValue, onlyShowMedicalRelatedLeaveTypes]);

    useEffect(() => {
        setFieldValue?.('loading', loading);
        return () => setFieldValue?.('loading', false);
    }, [loading, setFieldValue]);

    const filteredLeavePlans = leavePlans.filter(leavePlan => leavePlan.relationship.includes(relationship)
        && (props.onlyShowMedicalRelatedLeaveTypes ? leavePlan.medical.includes(Medical.MEDICAL) : true))
    const claimTypeOptions = buildClaimTypeOptions(filteredLeavePlans);
    const hasStateSpecificClaimTypes = claimTypeOptions.some(o => o.text.includes("¹"));

    const detailsText = isWellnessBenefit
    ? <>Please specify the product(s) you are claiming wellness/health screening benefits under. (Hospital indemnity, critical illness, or accident)
            <br/><br/>Select one test or procedure performed.
            <br/><br/>We ask this because certain types of claims require different information/documentation.</>
    : props.detailsText;
    return (
        loading
            ? <LoadingSpinner spinnerText="Loading"/>
            : <QuestionPanel headerText={props.headerText}
                             detailsText={detailsText}
                             footnotes={hasStateSpecificClaimTypes ? [Footnote.ONLY_AVAILABLE_IN_SELECT_STATES] : []}
                             panelContent={
                                 <div>
                                     {buildProductOptions(benefit)}
                                     <SelectDropdown id="claim-type" label={findClaimTypeLabelFor(benefit)} required={true}
                                                     changeCallback={(target: HTMLSelectElement) => {
                                                         resetClaimTypeFields(setFieldValue);
                                                         defaultClaimTypeFields(leavePlans, target.value as TypeOfClaim,
                                                             setFieldValue, onlyShowMedicalRelatedLeaveTypes);
                                                     }}
                                                     options={claimTypeOptions}/>
                                     {getDetailedQuestions(setFieldValue, leavePlans, claimType,
                                         onlyShowMedicalRelatedLeaveTypes, benefit, party)}
                                     {props.showAdditionalInformation && buildInformationLink(values)}
                                     {isWellnessBenefit && <>
                                         <TextInput id="facility-name" label="Facility name" required={false}/>
                                         <PhoneInput id="facility-phone-number" label="Facility phone number" required={false}/>
                                     </>}
                                     {props.children}
                                 </div>}
        >
        </QuestionPanel>
    )
};

function defaultClaimTypeFields(leavePlans: ClaimPlan[], claimType: TypeOfClaim,
                                setFieldValue: Function | undefined, onlyShowMedicalRelatedLeaveTypes: undefined | boolean) {
    const leavePlan = leavePlans.filter(plan => plan.claimType === claimType)?.[0];
    if (claimType === TypeOfClaim.MATERNITY) {
        setFieldValue?.('childRelationship', ChildRelationship.BIOLOGICAL_CHILD)
    }
    if (!(leavePlan?.medical?.length > 1 && !onlyShowMedicalRelatedLeaveTypes)) {
        setFieldValue?.('medicalRelated', leavePlan?.medical?.[0] === Medical.MEDICAL)
    }
    if (onlyShowMedicalRelatedLeaveTypes) {
        setFieldValue?.('medicalRelated', true)
    }
}

function buildProductOptions(benefit: BenefitType) {
    const isDisabilityBenefit = BenefitType.DISABILITY === benefit;
    if(!isDisabilityBenefit) {
        return null;
    }
    return <SelectDropdown id="product" label="Product" required={true}
                           options={Object.keys(DisabilityProduct).map(product => ({
                               id: product.replace(/_/g, '-').toLowerCase(),
                               text: (DisabilityProduct as any)[product]
                           }))}/>;
}

function buildInformationLink(values: EmployeeFormValues | PfmlEmployerFormValues | PfmlPhysicianFormValues) {
    const helpUrl = findHelpUrlFor(values?.benefit as BenefitType)
    return helpUrl ? <div>
        <a id="leaveDescription" target="_blank" rel="noopener noreferrer"
           href={helpUrl}>More information</a>
    </div> : null;
}

function buildClaimTypeOptions(claimPlans: ClaimPlan[]) {
    return claimPlans
        .map(plan => {
            const claimType = plan.claimType;
            let claimTypeEnum;
            if (Object.values(TypeOfClaim).includes(claimType as TypeOfClaim)) {
                claimTypeEnum = TypeOfClaim
            } else if (Object.values(ScreeningType).includes(claimType as ScreeningType)) {
                claimTypeEnum = ScreeningType
            } else if (Object.values(MannerOfDeath).includes(claimType as MannerOfDeath)) {
                claimTypeEnum = MannerOfDeath
            }
            return {
                id: getKeyByValue(claimTypeEnum, claimType).replace(/_/g, '-').toLowerCase(),
                text: addSuperscriptTo(plan.displayedClaimType ?? claimType),
                value: claimType
            };
        }) ?? [];
}

function getDetailedQuestions(setFieldValue: Function | undefined, claimPlans: ClaimPlan[],
                              claimType: string | undefined, onlyShowMedicalRelatedLeaveTypes: boolean | undefined, benefit: BenefitType,
                              party: Party) {
    if(!setFieldValue) {
        return <>{[]}</>;
    }
    const leavePlan = claimPlans.filter(plan => plan.claimType === claimType)?.[0];
    const isMaternity = claimType === TypeOfClaim.MATERNITY;
    let questions = [];
    if(leavePlan?.medical?.length > 1 && !onlyShowMedicalRelatedLeaveTypes) {
        questions.push(buildMedicalRelatedQuestions(setFieldValue));
    }
    if (claimType === TypeOfClaim.CHILD_BONDING || isMaternity) {
        questions.push(buildChildQuestions(isMaternity, setFieldValue))
    } else if (claimType === TypeOfClaim.MILITARY) {
        questions.push(buildMilitaryQuestions(setFieldValue))
    } else if (claimType === TypeOfClaim.INJURY && BenefitType.HOSPITAL_INDEMNITY !== benefit) {
        questions.push(buildMotorVehicleQuestion())
    } else if (claimType === TypeOfClaim.PREGNANCY) {
        questions.push(buildPregnancyQuestions(setFieldValue))
    } else if (claimType === TypeOfClaim.SICKNESS) {
        questions.push(buildSicknessQuestions())
    }
    if((claimType === TypeOfClaim.INJURY
        || claimType === TypeOfClaim.PHYSICAL_ILLNESS
        || claimType === TypeOfClaim.MENTAL_HEALTH)
        && party === Party.EMPLOYEE) {
        questions.push(buildEmploymentRelatedQuestion(benefit))
    }
    return <>{questions}</>;
}

function buildChildQuestions(isMaternity: boolean, setFieldValue: Function) {
    return <>
        {!isMaternity && <SelectDropdown id="child-relationship" label="Relationship to child" required={true}
                                         options={Object.keys(ChildRelationship).map(relationship => ({
                                             id: relationship.replace(/_/g, '-').toLowerCase(),
                                             text: (ChildRelationship as any)[relationship]
                                         }))}
                                         changeCallback={() => {
                                             removeDocumentTypeField(setFieldValue);
                                         }}/>}
    </>;
}

function buildMilitaryQuestions(setFieldValue: Function) {
    return <>
        <SelectDropdown id="military-leave-reason" label="Leave reason" required={true}
                        options={Object.keys(MilitaryLeaveReason).map(relationship => ({
                            id: relationship.replace(/_/g, '-').toLowerCase(),
                            text: (MilitaryLeaveReason as any)[relationship]
                        }))}
                        changeCallback={() => {
                            removeDocumentTypeField(setFieldValue);
                        }}
        />
        <TextInput id="military-relationship" label="Relationship to service member" required={true} maxLength={1024}/>
    </>;
}

function buildMotorVehicleQuestion() {
    return <Checkbox id="motor-vehicle-accident" label="Was a motor vehicle involved in the accident?"/>;
}

function buildConditionDetailsQuestion() {
    return <TextInput id="condition-details" label="What was the condition being treated?" required={true}
                      maxLength={1024}/>;
}

function buildEmploymentRelatedQuestion(benefit: BenefitType) {
    return <>
        {buildConditionDetailsQuestion()}
        <TextInput id="location-of-injury" label="Where did the injury happen?" required={false} maxLength={1024}/>
        <TimeInput id="time-of-injury" label="What time of day did your injury happen?" required={false}/>
        <RadioGroup id="police-report"
                    label="Was a police report filed? If yes, please attach a copy."
                    required={false}
                    radioProps={[{id: "yes", text: "Yes"}, {id: "no", text: "No"}]}/>
        <Checkbox id="work-related"
                  label={`Is the ${BenefitType.HOSPITAL_INDEMNITY === benefit ? "injury" : "injury/illness"} related to employment?`}/>
    </>
}

function buildMedicalRelatedQuestions(setFieldValue: Function) {
    return <Checkbox id="medical-related" label="Medical related"
                  changeCallback={() => {
                      removeDocumentTypeField(setFieldValue);
                  }}/>
}

function buildPregnancyQuestions(setFieldValue: Function) {
    return <>
        <DateInput id="delivery-date" label="Date of delivery" required={true}
                   labelContent={<Tooltip text="If you have not already delivered, enter the expected delivery date."/>}
                   changeCallback={(target: HTMLInputElement) => {
                       const deliveryDate = DateTime.fromFormat(target.value, 'MM/dd/yyyy')
                       setFieldValue?.('expectedDelivery', DateTime.now() < deliveryDate);
                   }}/>
        <SelectDropdown id="delivery-type" label="Type of delivery" required={true}
                   labelContent={<Tooltip text="If you have not already delivered, enter the expected delivery type."/>}
                   options={Object.keys(PregnancyDeliveryType).map(type => ({
                       id: type.replace(/_/g, '-').toLowerCase(),
                       text: (PregnancyDeliveryType as any)[type]
                   }))}/>
    </>
}

function buildSicknessQuestions() {
    return <>
        {buildConditionDetailsQuestion()}
        <DateInput id="first-visit" label="Date of first visit" required={false}/>
        <DateInput id="diagnosis-date" label="Date of diagnosis" required={false}/>
    </>
}

function resetClaimTypeFields(setFieldValue?: Function) {
    setFieldValue?.('childRelationship', '');
    setFieldValue?.('militaryRelationship', '');
    setFieldValue?.('militaryLeaveReason', '');
    setFieldValue?.('motorVehicleAccident', false);
    setFieldValue?.('workRelated', false);
    setFieldValue?.('expectedDelivery', false);
    setFieldValue?.('deliveryType', "");
    setFieldValue?.('deliveryDate', "");
    setFieldValue?.('firstVisit', "");
    setFieldValue?.('diagnosisDate', "");
    setFieldValue?.('locationOfInjury', "");
    setFieldValue?.('timeOfInjury', "");
    setFieldValue?.('policeReport', "");
    setFieldValue?.('facilityName', "");
    setFieldValue?.('facilityPhoneNumber', "");
    removeDocumentTypeField(setFieldValue);
}

function removeDocumentTypeField(setFieldValue?: Function) {
    setFieldValue?.('documentType', '');
}

export default ClaimType;