import SignatureCanvas from "react-signature-canvas";
import Button from "../button/Button";
import TextInput from "./TextInput";
import React, { useEffect, useRef } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { FormikValues } from "formik";
import _ from "lodash";

type SignatureProps = {
    initialValues?: FormikValues,
    values?: FormikValues,
    setFieldValue?: Function,
    id: string,
    label: string,
    required?: boolean
}

const Signature = (props: SignatureProps) => {
    const signatureName = _.camelCase(props.id);
    const signatureField = props.values?.[signatureName];
    const signatureCanvas = useRef(null as ReactSignatureCanvas | null);

    useEffect(() => {
        if(signatureCanvas.current?.isEmpty() && signatureField) {
            signatureCanvas.current.fromDataURL(signatureField, { width: 300, height: 44 })
        }
    }, [signatureCanvas, signatureField]);

    return (
        <div>
            <label className={props.required ? "is-required" : ""}>{props.label}</label>
            <div className="pds-u-flex-align-start">
                <div>
                    <SignatureCanvas canvasProps={{ width: 300, height: 44, style: { border: '1px solid #c7c7c7' } }}
                                     ref={(ref) => signatureCanvas.current = ref}
                                     onEnd={() => saveSignature()}/>
                </div>
                <div className="pds-u-padding-left-12">
                    <Button buttonContent="Clear" onClick={() => {
                        signatureCanvas.current?.clear()
                        saveSignature()
                    }}/>
                </div>
            </div>
            <TextInput id={`${props.id}-date`} label="Date" required={false} inputProps={{ readOnly: true }}/>
        </div>
    );

    function saveSignature() {
        props.setFieldValue?.(signatureName, signatureCanvas.current?.toDataURL('image/png'));
    }
}

export function isEmpty(signatureImage: string) {
    return signatureImage === '' || signatureImage === 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAAsCAYAAADfCoXBAAAABmJLR0QA/wD/AP+gvaeTAAAASklEQVR4nO3BAQ0AAADCoPdPbQ8HFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwJ8BzmwAAZ9ospwAAAAASUVORK5CYII=';
}

export default Signature;