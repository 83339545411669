import React, { ReactElement } from 'react';
import { FormikStepProps } from "./FormikStep";
import { PdsStepIndicator, PdsStepIndicatorItem } from "@principal/design-system-react";

type BreadcrumbsProps = {
    steps: Array<ReactElement<FormikStepProps>>,
    currentStep: number,
    stepChangeCallback: Function,
    children?: React.ReactNode
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
    return createBreadcrumbs(props);
};

function createBreadcrumbs(props: BreadcrumbsProps) {
    let pages = props.steps;
    return (<div className="pds-u-padding-top-20">
        <PdsStepIndicator className="hidden-lg">
            {buildBreadcrumbs(props, pages, true)}
        </PdsStepIndicator>
        <PdsStepIndicator className="hidden-sm">
            {buildBreadcrumbs(props, pages, false)}
        </PdsStepIndicator>
    </div>);
}

function buildBreadcrumbs(props: BreadcrumbsProps, pages: Array<React.ReactElement<FormikStepProps>>, smallDevice: boolean) {
    let breadcrumbs = [];
    let currentPage = props.currentStep;
    const startPage = smallDevice ? Math.max(currentPage - 1, 0) : 0;
    const endPage = smallDevice ? Math.min(currentPage + 1, pages.length - 1) : pages.length - 1
    for (let pageNumber = 0; pageNumber < pages.length; ++pageNumber) {
        const page = pages[pageNumber];
        let status: "current" | "completed" | "incomplete" = 'completed';
        if(pageNumber === currentPage) {
            status = 'current'
        } else if(pageNumber > currentPage) {
            status = 'incomplete'
        }
        const outsidePageRange = pageNumber < startPage || pageNumber > endPage;
        breadcrumbs.push(
            <PdsStepIndicatorItem key={pageNumber}
                    id={'page' + pageNumber}
                    status={status}
                    style={outsidePageRange ? { visibility: 'hidden', width: 0, height: 0 } : {}}
                    href={"completed" === status ? window.location.href : undefined}
                    onClick={() => props.stepChangeCallback(pageNumber)}
                    active={"current" === status}>
                {page.props.label}
            </PdsStepIndicatorItem>)
    }
    return breadcrumbs;
}
export default Breadcrumbs;