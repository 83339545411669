import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import AddListElementButton from "../../button/AddListElementButton";
import DateRange from "../../input/DateRange";
import SelectDropdown from "../../input/SelectDropdown";
import { EmployeeFormValues } from "../../common/EmployeeFormValues";
import {findClaimDateFor} from "../../enums/BenefitType";

type ClaimDateProps = {
    values?: EmployeeFormValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

const ClaimDates = (props: ClaimDateProps) => {
    const leaveTimeframe = SelectDropdown({
        id: "leave-timeframe",
        label: "Leave timeframe",
        required: true,
        options: [
            {id: "continuous", text: "Continuous"},
            {id: "periodic", text: "Periodic"}
        ]
    });

    const claimDateText = findClaimDateFor(props.values?.benefit);
    const noLeaveTimeframe = leaveTimeframe === null;
    return (
        <QuestionPanel headerText={claimDateText.headerText}
                       detailsText={claimDateText.detailsText}
                       panelContent={
                           <div>
                               {leaveTimeframe}
                               {noLeaveTimeframe
                                   ? <DateRange idPrefix="claim-dates" index={0} startLabel={claimDateText.startDateLabel}/>
                                   : <AddListElementButton {...props} idPrefix="claim-dates" componentType={DateRange}
                                                           elementInitialValue={{ startDate: '', endDate: '' }}
                                                           label=""
                                                           buttonText="Add Date Range"/>
                               }
                           </div>}
        >
        </QuestionPanel>
    )
};
export default ClaimDates;