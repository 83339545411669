import axios from "axios";

export async function findEmployerReimbursementFor(workState: string): Promise<boolean> {
    const employerReimbursement = await sendGetRequest(`${process.env.REACT_APP_FORMS_SERVICE_URL}/reimbursement?workState=${workState}`);
    return employerReimbursement.data?.employerReimbursement
}

async function sendGetRequest(url: string) {
    return await axios.get(
        url
    )
}

