import React from 'react';
import { FormikValues } from "formik";
import { findPossessiveFor, findPossessiveWithStartingVerbFor, PartOfSpeech } from "../../common/PersonalLanguage";
import { FormValues } from "../../common/FormValues";
import { BenefitClaimant } from "../../enums/BenefitClaimant";
import { BeneficiaryFormValues } from "../../common/BeneficiaryFormValues";
import QuestionPanel from "../../structure/QuestionPanel";
import RadioGroup from "../../structure/RadioGroup";
import SelectDropdown from "../../input/SelectDropdown";
import { DateTime } from "luxon";
import SsnInput from "../../input/SsnInput";
import { getKeyByValue } from "../../enums/KeyFinder";
import Checkbox from "../../input/Checkbox";
import HorizontalRule from "../../structure/HorizontalRule";

export type BeneficiaryFinancialProps = {
    initialValues?: FormikValues,
    values?: FormikValues,
    setFieldValue?: Function
}

const BeneficiaryFinancial = (props: BeneficiaryFinancialProps) => {
    const values = props.values as BeneficiaryFormValues;
    const nounPossessiveWithArticle = findPossessiveFor(values as FormValues, true, true, PartOfSpeech.NOUN);
    const nounPossessiveWithoutArticle = findPossessiveFor(values as FormValues, false, false, PartOfSpeech.NOUN);
    const possessiveWithVerb = findPossessiveWithStartingVerbFor(values as FormValues, false, PartOfSpeech.PRONOUN);
    const isUnitedStatesPerson = values?.isUnitedStatesPerson === 'Yes';
    const doesNotHaveBackupWithholding = values?.hasBackupWithholding === 'No';

    function buildIrsFormLinks(filesToLink: any[]) {
        return <div>
            <h5>Please complete and provide the appropriate form:</h5>
            <ul>
                {filesToLink.map(({label, fileName}) => (
                    <li>
                        <a href={`https://www.irs.gov/pub/irs-pdf/${fileName}`} rel="noopener noreferrer"
                           target="_blank">
                            {label}
                        </a>
                    </li>
                ))}
            </ul>
        </div>;
    }

    return (
        <QuestionPanel headerText={`Tell us about ${nounPossessiveWithArticle} settlement information.`}
                       detailsText={<div>
                           <div>Please upload any applicable forms to this form (on a later step) or send it to one of the following:</div>
                           <div>Principal Life Insurance Company<br/>ATTN: Group Life and Disability Claims Department<br/>Des
                               Moines, IA 50392-0002</div>
                           <div>Fax: 1-800-255-6609</div>
                           <div>Email: SBDClaims@principal.com</div>
                       </div>}
                       panelContent={
                           <div>
                               <RadioGroup id="is-united-states-person"
                                       label={`${possessiveWithVerb} a U.S. citizen or other U.S. person including a resident alien, or domestic trust or estate with a Social Security number or Tax Identification Number?`}
                                       required={true}
                                       radioProps={[
                                           { id: "yes", text: "Yes" },
                                           { id: "no", text: "No" }
                                       ]}/>
                               {isUnitedStatesPerson && <>
                                   <SelectDropdown
                                       id="benefit-claimant"
                                       label="Who is claiming the benefits?"
                                       required={true}
                                       options={Object.keys(BenefitClaimant).filter((b) => {
                                           const isMinor = DateTime.now()
                                               .diff(DateTime.fromFormat(values?.beneficiaryDateOfBirth, 'MM/dd/yyyy'), 'year')
                                               .years < 18;
                                           return !(isMinor && getKeyByValue(BenefitClaimant, BenefitClaimant.BENEFICIARY) === b);
                                       }).map((b) => ({
                                           id: b.replace(/_/g, '-').toLowerCase(),
                                           text: (BenefitClaimant as any)[b]
                                       }))}
                                   />
                                   <SsnInput label={BenefitClaimant.TRUSTEE === values?.benefitClaimant ? "Tax Identification Number" : `${nounPossessiveWithoutArticle} Social Security number or Tax Identification Number`} id="tax-identification-number"
                                              required={true}/>
                                   <RadioGroup id="has-backup-withholding"
                                               label={`${possessiveWithVerb} subject to backup tax withholding?`}
                                               required={true}
                                               radioProps={[
                                                   { id: "yes", text: "Yes" },
                                                   { id: "no", text: "No" }
                                               ]}/>
                                   {values?.hasBackupWithholding === 'Yes' &&
                                       buildIrsFormLinks([
                                           {
                                               label: 'Form W-9',
                                               fileName: 'fw9.pdf'
                                           }
                                       ])
                                   }
                                   {doesNotHaveBackupWithholding && <>
                                       <HorizontalRule/>
                                       <div>
                                           <label>Under penalties of perjury I certify that:</label>
                                           <ol>
                                               <label>
                                                   <li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me), and</li>
                                               </label>
                                               <label>
                                                   <li>I am not subject to backup withholding because: (a) I am exempt from backup withholding,
                                                   or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a
                                                   failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding, and</li>
                                               </label>
                                               <label>
                                                   <li>I am a U.S. citizen or other U.S. person (as defined in the instructions), and</li>
                                               </label>
                                               <label>
                                                   <li>I am exempt from FATCA reporting.</li>
                                               </label>
                                           </ol>
                                           <Checkbox id="tax-payer-disclaimer-acknowledged" label="I certify"/>
                                       </div>
                               </>}
                           </>}
                           {values?.isUnitedStatesPerson === 'No' &&
                               buildIrsFormLinks([{
                                   label: 'Form W-8BEN (foreign individuals)',
                                   fileName: 'fw8ben.pdf'
                               }, {
                                   label: 'Form W-BEN-E (foreign entities)',
                                   fileName: 'fw8bene.pdf'
                               }])
                           }
                       </div>
                   }>
        </QuestionPanel>
    )
};
export default BeneficiaryFinancial;