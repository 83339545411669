import { ValidateAddressResponse } from "../../service/AddressService";
import Modal from "./Modal";
import TwoColumnDiv from "./TwoColumnDiv";
import React from "react";
import Button from "../button/Button";
import HorizontalRule from "./HorizontalRule";

export type AddressModalProps = {
    enteredAddress: ValidateAddressResponse,
    correctedAddress?: ValidateAddressResponse,
    streetAddressField: string,
    cityField: string,
    stateField: string,
    zipCodeField: string,
    setFieldValue?: Function,
    validateForm?: Function,
    submitForm?: Function,
    children?: React.ReactNode;
}

const AddressModal = (props: AddressModalProps) => {
    const correctedAddress = props.correctedAddress;
    if(!correctedAddress) {
        return null;
    }
    const enteredAddress = props.enteredAddress;
    return <Modal id="address-validation"
                  headerText="Address Verification"
                  modalContent={
                      <div>
                          We are unable to verify your address as entered, but found a close match. Please confirm the correct address.
                          <HorizontalRule/>
                          <h5>Entered Address:</h5>
                          <TwoColumnDiv leftContent={<>
                              <div>{enteredAddress.streetAddress}</div>
                              <div>{enteredAddress.city + ', ' + enteredAddress.state + ' ' + enteredAddress.zipCode}</div>
                          </>} rightContent={<Button type="submit"
                                                     buttonContent="Select Address"
                                                     onClick={async () => {
                                                         setFieldValue(props, 'useEnteredAddress', true, false);
                                                         await validateAndSubmitForm(props);
                                                     }}/>}>
                          </TwoColumnDiv>
                          <HorizontalRule/>
                          <h5>Suggested Address:</h5>
                          <TwoColumnDiv leftContent={<>
                              <div>{correctedAddress.streetAddress}</div>
                              <div>{correctedAddress.city + ', ' + correctedAddress.state + " " + correctedAddress.zipCode}</div>
                          </>} rightContent={<Button type="submit"
                                                     buttonContent="Select Address"
                                                     onClick={async () => {
                                                         setFieldValue(props, props.streetAddressField, correctedAddress.streetAddress, false);
                                                         setFieldValue(props, props.cityField, correctedAddress.city, false);
                                                         setFieldValue(props, props.stateField, correctedAddress.state, false);
                                                         setFieldValue(props, props.zipCodeField, correctedAddress.zipCode, false);
                                                         await validateAndSubmitForm(props);
                                                     }}/>}>
                          </TwoColumnDiv>
                      </div>}
                  closeable={false}/>;
}

function setFieldValue(props: AddressModalProps, field: string, value: any, shouldValidate?: boolean) {
    if(props.setFieldValue) {
        props.setFieldValue(field, value, shouldValidate)
    }
}

async function validateAndSubmitForm(props: AddressModalProps) {
    if (props.validateForm) {
        await props.validateForm();
    }
    if (props.submitForm) {
        await props.submitForm();
    }
}

export default AddressModal;
