import axios from 'axios';
import { XMLParser } from "fast-xml-parser";

export interface ValidateAddressResponse {
    streetAddress: string,
    city: string,
    state: string,
    zipCode: string
}

export interface CityStateSearchResponse {
    city: string,
    state: string
}

export async function validateAddress(streetAddress: string, city: string, state: string, zip: string): Promise<ValidateAddressResponse | null> {
    try {
        const uspsResponse = await axios.get('https://secure.shippingapis.com/ShippingAPI.dll',
            {
                params: {
                    API: 'Verify',
                    XML: '<AddressValidateRequest USERID="179PRINC4281">' +
                        '<Revision>1</Revision>' +
                        '<Address ID="0">' +
                        '<Address1/>' +
                        `<Address2>${streetAddress}</Address2>` +
                        `<City>${city}</City>` +
                        `<State>${state}</State>` +
                        `<Zip5>${zip}</Zip5>` +
                        '<Zip4/>' +
                        '</Address>' +
                        '</AddressValidateRequest>'
                }
            })
        const addressObject = new XMLParser().parse(uspsResponse.data);
        const address = addressObject.AddressValidateResponse.Address;
        return {
            streetAddress: address.Address2.toString(),
            city: address.City.toString(),
            state: address.State.toString(),
            zipCode: address.Zip5.toString()
        };
    } catch(e) {
        console.log(e);
        return null;
    }
}

export async function cityStateSearch(zipCode: string | undefined): Promise<CityStateSearchResponse | null> {
    try {
        const uspsResponse = await axios.get('https://secure.shippingapis.com/ShippingAPI.dll',
            {
                params: {
                    API: 'CityStateLookup',
                    XML: '<CityStateLookupRequest USERID="179PRINC4281">' +
                        '<ZipCode ID="0">' +
                        `<Zip5>${zipCode}</Zip5>` +
                        '</ZipCode>' +
                        '</CityStateLookupRequest>'
                }
            })
        const addressObject = new XMLParser().parse(uspsResponse.data);
        const address = addressObject.CityStateLookupResponse.ZipCode;
        return {
            city: address.City.toString(),
            state: address.State.toString(),
        }
    } catch(e) {
        console.log(e);
        return null;
    }
}