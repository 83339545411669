import React, { ReactElement } from 'react';
import Radio, { RadioProps } from "../input/Radio";
import { useField } from "formik";
import { PdsRadioGroup } from "@principal/design-system-react";

const _ = require("lodash");

export type RadioGroupProps = {
    id: string,
    label?: string,
    labelContent?: ReactElement,
    required?: boolean,
    radioProps: Pick<RadioProps, "id" | "text" | "changeCallback">[]
}

const RadioGroup = (props: RadioGroupProps) => {
    const name = _.camelCase(props.id);
    const [, meta] = useField({
        name: name,
        validate: validateInput
    });
    const fieldRemoved = meta.initialValue === undefined;
    const error = meta.error;

    return (fieldRemoved ? null :
            <div className="pds-u-margin-bottom-16">
                <PdsRadioGroup id={props.id} fieldId={props.id} name={name} label={props.label} required={props.required} errorMessage={error}>
                    <div className="pds-u-padding-left-16">
                        {props.radioProps.map(o => {
                            const radioId = `${props.id}-${o.id}`;
                            return <Radio id={radioId} key={radioId} name={name} text={o.text} changeCallback={o.changeCallback}/>;
                        })}
                    </div>
                    <label slot="label" data-field-name={name} data-field-label={props.label} htmlFor={props.id}>
                        {props.label}{props.labelContent}
                    </label>
                </PdsRadioGroup>
            </div>
    )

    function validateInput(value: string) {
        if (fieldRemoved || !props.required || (props.required && value)) {
            return '';
        }
        return 'Please select an option.'
    }
};
export default RadioGroup;