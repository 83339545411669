import React, { ReactElement } from 'react';

export type UnorderedListProps = {
    elements: (ReactElement | string)[],
} & React.HTMLAttributes<HTMLUListElement>

const UnorderedList = (props: UnorderedListProps) => {
    return (
        <ul id={props.id}>
            {props.elements.map(e =>
                <li>
                    {e}
                </li>)}
        </ul>
    )
};
export default UnorderedList;