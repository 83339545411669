import React, {ReactElement} from 'react';
import NumericInput from "./NumericInput";

export type PercentageInputProps = {
    id: string,
    label: string,
    required: boolean,
    labelContent?: ReactElement,
    changeCallback?: Function,
    children?: React.ReactNode
}

const PercentageInput = (props: PercentageInputProps) => {
    return <NumericInput id={props.id} label={props.label} required={props.required} labelContent={props.labelContent}
                         changeCallback={props.changeCallback}
                         inputMask={{ alias: 'percentage', suffix: '', placeholder: '' }}
                         rightContent={<>%</>}/>
};
export default PercentageInput;