import React, { useEffect, useState } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import DateInput from "../../input/DateInput";
import Tooltip from "../../input/Tooltip";
import NumericInput from "../../input/NumericInput";
import SsnInput from "../../input/SsnInput";
import CenteredFullWidthDiv from "../../structure/CenteredFullWidthDiv";
import PhoneInput from "../../input/PhoneInput";
import EmployeeAddress from "../../structure/EmployeeAddress";
import { FormikErrors } from "formik";
import { useAuth } from "oidc-react";
import * as memberService from "../../../service/MemberService";
import LoadingSpinner from "../../structure/LoadingSpinner";
import Search from "../../input/Search";
import { Member } from "../../../../service/src/domain/Member";
import { findEmployeeFor } from "../../../service/AffiliateService";
import { EmployerFormValues } from "../../common/EmployerFormValues";
import { findMemberFor } from "../../../service/MemberService";
import HorizontalRule from "../../structure/HorizontalRule";

export type AboutEmployeeProps = {
    values?: EmployerFormValues,
    errors?: FormikErrors<any>,
    setFieldValue?: Function,
    isSubmitting?: boolean,
    validateForm?: Function,
    submitForm?: Function,
    children?: React.ReactNode;
}

const AboutEmployee = (props: AboutEmployeeProps) => {
    const [members, setMembers] = useState();
    const [employee, setEmployee] = useState();
    const auth = useAuth();

    const values = props.values;
    const policyNumber = values?.policyNumber;
    const unitNumber = values?.unitNumber;
    const setFieldValue = props.setFieldValue;
    const userData = auth.userData;
    const signedIn = !!userData;
    const token = userData?.access_token;
    const isSummary = props.values?.currentStep === 'Summary';
    const loading = signedIn && !members && !isSummary;

    useEffect(() => {
        async function loadMembers() {
            if (!signedIn || members) {
                return;
            }
            const serviceMembers = await memberService.findMembersFor(policyNumber, unitNumber, token);
            setMembers(serviceMembers as any);
        }
        loadMembers();
    }, [policyNumber, unitNumber, signedIn, token, setFieldValue, members])

    useEffect(() => {
        setFieldValue?.('loading', loading);
        return () => setFieldValue?.('loading', false);
    }, [loading, setFieldValue]);

    if (loading) {
        return <LoadingSpinner spinnerText='Loading'/>
    }

    const sortedMembers = (members as any as Member[] ?? []).sort((a: Member, b: Member) => {
        const result = a.firstName.localeCompare(b.firstName);
        return result !== 0 ? result : a.lastName.localeCompare(b.lastName);
    })

    return (
        <QuestionPanel headerText="Let's gather some information about your employee."
                       detailsText="Your employee's privacy is important to us. We'll only use this contact information for official communication regarding your employee's claim."
                       panelContent={
                           <div>
                               {signedIn && <>
                                   <Search id="employee-search" label="Employee search"
                                           placeholder="Start typing a name or Principal member ID"
                                           maxResults={10}
                                           options={sortedMembers?.map((m, index) => {
                                               return {
                                                   id: String(index),
                                                   text: `${m.firstName} ${m.lastName} (Date of birth: ${m.dateOfBirth}, member ID: ${m.privacyId})`,
                                                   value: `${m.firstName} ${m.lastName} ${m.dateOfBirth} ${m.privacyId}`,
                                                   onSelect: async () => {
                                                       setFieldValue?.('firstName', m.firstName)
                                                       setFieldValue?.('lastName', m.lastName)
                                                       setFieldValue?.('dateOfBirth', m.dateOfBirth)
                                                       setFieldValue?.('privacyId', m.privacyId)
                                                       const [employee, member] = await Promise.all(
                                                           [findEmployeeFor(m.privacyId, token), findMemberFor(policyNumber, m.privacyId, token)]);
                                                       setEmployee(employee as any);
                                                       setFieldValue?.('socialSecurityNumber', employee.socialSecurityNumber);
                                                       setFieldValue?.('phoneNumber', employee.phoneNumber);
                                                       setFieldValue?.('streetAddress', employee.streetAddress);
                                                       setFieldValue?.('city', employee.city);
                                                       setFieldValue?.('state', employee.state);
                                                       setFieldValue?.('zipCode', employee.zipCode);
                                                       setFieldValue?.('dateOfHire', member.dateOfHire);
                                                       setFieldValue?.('hoursWorkedPerWeek', member.hoursWorkedPerWeek);
                                                   }
                                               }
                                           })}/>
                                   <HorizontalRule/>
                               </>}
                               <NumericInput id="privacy-id" label="Employee's Principal member ID" required={false}
                                             maxLength={9}
                                             labelContent={<Tooltip text="You can find this 9-digit number on your principal.com employer account or in billing documents."/>}/>
                               <CenteredFullWidthDiv divContent={
                                   <h4>
                                       or
                                   </h4>}/>
                               <TextInput id="first-name" label="Employee's first name" required={false} maxLength={1024}/>
                               <TextInput id="last-name" label="Employee's last name" required={false} maxLength={1024}/>
                               <DateInput id="date-of-birth" label="Employee's date of birth" required={false}/>
                               {signedIn && props.isSubmitting && !isSummary ? <LoadingSpinner spinnerText='Loading'/> : <>
                                   <HorizontalRule/>
                                   {!(employee as any as EmployerFormValues)?.socialSecurityNumber
                                       && <SsnInput id="social-security-number" label="Employee's Social Security number" required={true}/>}
                                   <PhoneInput id="phone-number" label="Employee's phone number" required={true}/>
                                   <HorizontalRule/>
                                   <EmployeeAddress {...props}/>
                               </>}
                           </div>
                       }
        >
        </QuestionPanel>
    )
};
export default AboutEmployee;