import React, { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from "formik/dist/Field";
import { Field, FieldHelperProps, FieldMetaProps, FieldValidator, FormikErrors, useField } from "formik";
import { PdsSelect } from "@principal/design-system-react";
var _ = require('lodash');

export type SelectDropdownProps = {
    id: string,
    name?: string,
    label: string,
    labelContent?: ReactElement,
    required: boolean,
    options: SelectDropdownOption[],
    multiple?: boolean,
    changeCallback?: Function,
    validate?: FieldValidator,
    children?: React.ReactNode
}

export type SelectDropdownOption = {
    id: string,
    text: string,
    value?: string,
    disabled?: boolean
}

const SelectDropdown = (props: SelectDropdownProps) => {
    const id = props.id;
    const multiple = props.multiple;
    const name = props.name ? props.name : _.camelCase(id);
    const options = props.options;
    const [, meta, helpers] = useField({
        name,
        validate: validateInput
    });
    const setValue = helpers.setValue;
    const [initialized, setInitialized] = useState(false);
    const fieldRemoved = meta.initialValue === undefined;

    useEffect(() => {
        if(!initialized && multiple) {
            initializeSelect2(id, setValue)
        }
        setInitialized(true);
    }, [id, multiple, setValue, initialized]);

    const onlyOneRequiredOption = options.length === 1 && props.required;
    const firstOptionValue = findValueFor(options[0])
    if(onlyOneRequiredOption && meta.value !== firstOptionValue) {
        setValue(firstOptionValue);
    }
    if(onlyOneRequiredOption) {
        return null;
    }

    return (fieldRemoved ? null :
            <div className="pds-u-margin-bottom-16">
                {createSelect(props, name, helpers, meta)}
            </div>
    )

    function validateInput(value: string) {
        if(fieldRemoved || (!props.required && !value)) {
            return '';
        } else if(props.required && !value) {
            return 'Please fill out this field.'
        }
        return props.validate?.(value)
    }
};

const Select = ({ field, form, helpers, meta, ...props }: FieldProps & SelectDropdownProps & {meta: FieldMetaProps<any>, helpers: FieldHelperProps<any>}) => {
    const multiple = props.multiple;
    const error = meta.error
    const Component = multiple ? 'select' : PdsSelect;
    const label =
        <label slot="label" data-field-name={field.name} data-field-label={props.label} htmlFor={props.id}>
            {props.label}{props.labelContent}
        </label>
    return (
        <>
            {multiple && <div>{label}</div>}
            <Component {...field} id={props.id} fieldId={props.id}
                       label={props.label}
                       multiple={multiple}
                       required={props.required}
                       style={{width: '100%'}}
                       errorMessage={(error && !(error as unknown as FormikErrors<any>).hidden) ? error as string : undefined}
                       onChange={(event) => {
                           helpers.setError(undefined)
                           if (field) {
                               field.onChange(event);
                           }
                           if (props.changeCallback) {
                               props.changeCallback(event.target)
                           }
                       }}>
                {!multiple && label}
                {buildOptions(props)}
            </Component>
        </>
    );
};

function createSelect(props: SelectDropdownProps, name: string, helpers: FieldHelperProps<any>, meta:FieldMetaProps<any>) {
    return <Field component={Select} {...props} name={name} helpers={helpers} meta={meta}/>
}

function initializeSelect2(id: string, setValue: (value: any, shouldValidate?: boolean) => void) {
    // @ts-ignore
    let jquerySelect = window.$(`#${id}`);
    jquerySelect.select2({
        "placeholder": "Select all that apply",
        "closeOnSelect": false,
        "multiple": true,
        "selectionCssClass": "multi-select"
    })
    jquerySelect.on('change', () => {
        setValue(jquerySelect.val())
    });
}

function buildOptions(props: SelectDropdownProps) {
    let builtOptions: ReactElement[] = [<option key={props.id + '-blank'} id={props.id + '-blank'} value=''/>];
    props.options.forEach((option) => {
        builtOptions.push(<option key={props.id + '-' + option.id} id={props.id + '-' + option.id}
                                  value={findValueFor(option)} disabled={option.disabled}>{option.text}</option>)
    })
    return builtOptions;
}

function findValueFor(option: SelectDropdownOption): string {
    return option?.value ?? option?.text;
}
export default SelectDropdown;
