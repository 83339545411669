import React from 'react';
import { Link } from "react-router-dom";
import CenteredFullWidthDiv from "./structure/CenteredFullWidthDiv";
import Panel from "./structure/Panel";
import { PdsButton } from "@principal/design-system-react";

const ProductChoice = () => {
    return (
        <Panel headerText="Select a Principal product below to submit a claim."
               panelContent={
                   <CenteredFullWidthDiv divContent=
                         {<>
                             {buildButton('Hospital indemnity', '/hospitalIndemnity')}
                             {buildButton('Life', '/life')}
                             {buildButton('Paid family and medical leave', '/pfml')}
                             {buildButton('Short- or long-term disability', '/disability')}
                             {buildButton('Wellness/health screening', '/wellness')}
                         </>}>
                   </CenteredFullWidthDiv>}
        >
        </Panel>
    )
};

function buildButton(buttonText: string, path: string) {
    return <div className="pds-u-padding-bottom-8">
        <Link to={path}>
            <PdsButton variant="primary">
                <div style={{ width: '280px' }}>
                    {buttonText}
                </div>
            </PdsButton>
        </Link>
    </div>;
}

export default ProductChoice;