import React, {useEffect} from 'react';
import {EmployerFormValues} from "../../common/EmployerFormValues";
import Document from "../common/Document";
import {BenefitType, findPartyChoicesFor} from "../../enums/BenefitType";
import {Party} from "../../enums/Party";
import {DocumentType} from "../../enums/DocumentType";
import RadioGroup from "../../structure/RadioGroup";
import {BeneficiaryDesignationStatus} from "../../enums/BeneficiaryDesignationStatus";
import {LifeEmployerFormValues} from "../../life/LifeEmployerFormValues";
import TextInput from "../../input/TextInput";
import EmailInput from "../../input/EmailInput";
import { PfmlEmployerFormValues } from "../../pfml/PfmlEmployerFormValues";

export type EmployerDocumentProps = {
    initialValues?: EmployerFormValues,
    values?: EmployerFormValues
    setFieldValue?: Function,
    setFieldError?: Function
}

const EmployerDocument = (props: EmployerDocumentProps) => {
    const values = props.values;
    const benefit = values?.benefit as BenefitType;
    const setFieldValue = props.setFieldValue;
    let documentTypeOptions = [DocumentType.EMPLOYEE_STATEMENT] as DocumentType[]
    let documentHeader;
    let documentDescription;
    useEffect(() => {
        if (BeneficiaryDesignationStatus.UPLOADED !== (values as any as LifeEmployerFormValues)?.beneficiaryDesignationStatus
            && values?.documents?.some(document => [DocumentType.BENEFICIARY_DESIGNATION, DocumentType.ENROLLMENT_FORM].includes(document.documentType as DocumentType))) {
            setFieldValue?.('beneficiaryDesignationStatus', BeneficiaryDesignationStatus.UPLOADED);
        }
    }, [values, setFieldValue]);
    if (findPartyChoicesFor(benefit).includes(Party.PHYSICIAN)) {
        documentTypeOptions.push(DocumentType.PHYSICIAN_STATEMENT)
    }
    if (props.initialValues?.baseWage !== undefined || (props.initialValues as any as PfmlEmployerFormValues)?.earningsQuarters !== undefined) {
        documentTypeOptions.push(DocumentType.EARNINGS)
    }
    if (BenefitType.LIFE === benefit) {
        documentTypeOptions = [
            DocumentType.BENEFICIARY_DESIGNATION,
            DocumentType.ENROLLMENT_FORM
        ];
        documentHeader = 'any beneficiary or change in beneficiary forms'
        documentDescription = <>
            For the beneficiary designation, please upload one of the following:<br/>
            A copy of the employee's beneficiary designation for their group life insurance (maintained by you), a
            screen print of an online designation, or a prior life insurance carrier's form.
        </>
    }
    return (
        <Document {...props} documentTypeOptions={documentTypeOptions} documentHeader={documentHeader}
                  documentDescription={documentDescription}
                  panelContent={
                      <>
                          <RadioGroup id="beneficiary-designation-status" label="Beneficiary designation status"
                                      required={true}
                                      radioProps={Object.keys(BeneficiaryDesignationStatus).map(beneficiaryDesignationStatus => ({
                                          id: beneficiaryDesignationStatus.replace(/_/g, '-').toLowerCase(),
                                          text: (BeneficiaryDesignationStatus as any)[beneficiaryDesignationStatus],
                                          changeCallback: (value: string) => {
                                              if(BeneficiaryDesignationStatus.THIRD_PARTY !== value) {
                                                  setFieldValue?.('thirdPartyContactName', '');
                                                  setFieldValue?.('thirdPartyContactEmailAddress', '');
                                              }
                                          }
                                      }))}/>
                          {(values as any as LifeEmployerFormValues)?.beneficiaryDesignationStatus === BeneficiaryDesignationStatus.THIRD_PARTY
                              && <>
                                  <TextInput id="third-party-contact-name" label="Third party contact name"
                                             required={true}/>
                                  <EmailInput id="third-party-contact-email-address" label="Third party contact email"
                                              required={true}/>
                              </>}
                      </>
                  }/>
    )
};

export default EmployerDocument;