import React from 'react';
import Button from "./Button";
import { FieldArray, FormikValues, useField } from "formik";
import Callout from "../structure/Callout";
import { PdsIconPlus } from "@principal/design-system-icons-react";
var _ = require('lodash');

type AddListElementButtonProps = {
    initialValues?: FormikValues,
    values?: FormikValues,
    setFieldValue?: Function,
    setFieldError?: Function,
    otherElementProps?: any,
    idPrefix: string,
    buttonText: string,
    componentType: React.ComponentType<ListElementProps>,
    elementInitialValue: object,
    label?: string,
    required?: boolean,
    children?: React.ReactNode
}

export type ListElementProps = {
    idPrefix: string,
    index?: number,
    initialValues?: FormikValues,
    values?: FormikValues,
    setFieldValue?: Function,
    setFieldError?: Function,
    otherProps?: any,
    closeCallback?: Function,
    children?: React.ReactNode
}

const AddListElementButton = (props: AddListElementButtonProps) => {
    const listId = _.camelCase(props.idPrefix);
    const [, meta] = useField(listId);
    return (meta.initialValue === undefined ? null :
        <div>
            <FieldArray name={listId} render={() => {
                if(!props.values || !props.values[listId]) {
                    return null;
                }
                return (
                    <>
                        {props.label && <div className="pds-u-margin-bottom-16">
                            <label className={props.required ? "is-required" : ""}>{props.label}</label>
                        </div>}
                        <div className="pds-u-margin-bottom-16">
                            <Button buttonContent={<div><PdsIconPlus className="pds-u-inline-block"/> {props.buttonText}</div>}
                                    buttonProps={{size: "sm"}}
                                    onClick={() => {
                                        meta.initialValue.push(props.elementInitialValue)
                                        if(meta.value.length < meta.initialValue.length) {
                                            meta.value.push(props.elementInitialValue)
                                        }
                                        props.setFieldValue?.(listId, meta.value)
                                    }}/>
                        </div>
                        {props.values[listId].map((entry: any, index: number) => (
                            <Callout>
                                <props.componentType key={index} idPrefix={props.idPrefix} index={index}
                                                     closeCallback={() => {
                                                         meta.initialValue.splice(index, 1)
                                                         if(meta.value.length > meta.initialValue.length) {
                                                            meta.value.splice(index, 1)
                                                         }
                                                         props.setFieldValue?.(listId, meta.value)
                                                     }}
                                                     setFieldValue={props.setFieldValue}
                                                     setFieldError={props.setFieldError}
                                                     initialValues={props.initialValues}
                                                     values={props.values}
                                                     otherProps={props.otherElementProps}/>
                            </Callout>
                        ))}
                    </>
                );
            }}/>
        </div>
    )
};

export function buildArrayIdFor(id: string, props: ListElementProps) {
    return props.idPrefix + "-" + id + "-" + props.index;
}

export function buildArrayNameFor(name: string, props: ListElementProps) {
    const namePrefix = _.camelCase(props.idPrefix);
    const indexPrefix = `${namePrefix}[${props.index}].`;
    return `${indexPrefix}${name}`;
}

export default AddListElementButton;