import React, { ReactElement, useEffect, useRef } from 'react';
import { ModalSize } from "../enums/ModalSize";
import { PdsModal } from "@principal/design-system-react";

export type ModalProps = {
    id: string,
    headerText?: string,
    closeable: boolean,
    modalContent?: ReactElement,
    modalFooter?: ReactElement,
    modalSize?: ModalSize,
    children?: React.ReactNode
}

const Modal = (props: ModalProps) => {
    const modalRef = useRef(null) as React.RefObject<any>;

    useEffect(() => {
        const modalRefCurrent = modalRef.current
        return () => {
            modalRefCurrent?.trap?.deactivate();
        };
    }, [modalRef])

    return (
        <PdsModal id={props.id}
                  modalTitle={props.headerText ?? ""}
                  hideCloseButton={!props.closeable}
                  size={props.modalSize}
                  ref={modalRef}
                  openOnLoad={true}>
            <div>
                {props.modalContent}
            </div>
            <div slot="footer">
                {props.modalFooter}
            </div>
        </PdsModal>
    )
};
export default Modal;