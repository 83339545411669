import React from 'react';
import NumericInput from "./NumericInput";

export type ZipCodeInputProps = {
    id: string,
    label: string,
    required: boolean,
    changeCallback?: Function,
    children?: React.ReactNode
}

const ZipCodeInput = (props: ZipCodeInputProps) => {
    return <NumericInput id={props.id} label={props.label} required={props.required}
                         changeCallback={props.changeCallback}
                         maxLength={5}
                         validate={validateZipCode}/>

    function validateZipCode(value: string) {
        return value?.length === 5 ? '' : 'ZIP code must be 5 digits.'
    }
};
export default ZipCodeInput;