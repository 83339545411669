import { defaultFormValues, FormValues } from "./FormValues";

export type PhysicianFormValues = FormValues & {
    patientFirstName: string,
    patientLastName: string,
    patientDateOfBirth: string,
    conditionStartDate: string,
    restrictionOrCareStartDate: string,
    restrictionOrCareEndDate: string,
    diagnoses: [{
        diagnosisCode: string,
        diagnosis: string
    }],
    inpatientCare: boolean,
    facilityName: string,
    inpatientCareStartDate: string,
    inpatientCareEndDate: string,
    patientCompetent: string,
    firstVisit: string,
    lastVisit: string,
    nextVisit: string,
    physicianFirstName: string,
    physicianLastName: string,
    physicianDegree: string,
    physicianPhoneNumber: string,
    physicianStreetAddress: string,
    physicianCity: string,
    physicianState: string,
    physicianZipCode: string,
    returnToWork: string,
    returnToWorkHours: string,
    restrictingPermanently: boolean
}

export const defaultPhysicianFormValues = {
    ...defaultFormValues,
    patientFirstName: '',
    patientLastName: '',
    patientDateOfBirth: '',
    conditionStartDate: '',
    restrictionOrCareStartDate: '',
    restrictionOrCareEndDate: '',
    diagnoses: [{
        diagnosisCode: '',
        diagnosis: ''
    }],
    inpatientCare: false,
    facilityName: '',
    inpatientCareStartDate: '',
    inpatientCareEndDate: '',
    patientCompetent: '',
    firstVisit: '',
    lastVisit: '',
    nextVisit: '',
    physicianFirstName: '',
    physicianLastName: '',
    physicianDegree: '',
    physicianPhoneNumber: '',
    physicianStreetAddress: '',
    physicianCity: '',
    physicianState: '',
    physicianZipCode: '',
    returnToWork: '',
    returnToWorkHours: '',
    restrictingPermanently: false
}