import axios from "axios";
import { Fraud } from "../../service/src/domain/Fraud";

export async function findFraudFor(): Promise<Fraud[]> {
    const frauds = await sendGetRequest(`${process.env.REACT_APP_FORMS_SERVICE_URL}/fraud`);
    return frauds.data?.frauds?.sort((f1: Fraud, f2: Fraud) => f1.state.localeCompare(f2.state)) as Fraud[];
}

async function sendGetRequest(url: string) {
    return await axios.get(
        url
    )
}

