import { ScreeningType } from "./ScreeningType";

export enum TypeOfClaim {
    SERIOUS_HEALTH_CONDITION = 'Serious health condition',
    MATERNITY = 'Maternity leave',
    CHILD_BONDING = 'Bonding with a child',
    MILITARY = 'Active duty or military events',
    PERSONAL = 'Personal / family violence',
    INJURY = 'Injury',
    PHYSICAL_ILLNESS = 'Physical illness',
    MENTAL_HEALTH = 'Mental health',
    PREGNANCY = 'Pregnancy',
    SICKNESS = 'Sickness'
}

export const addSuperscriptTo = (claimType: string) => {
    const exclusions = [
        TypeOfClaim.PERSONAL,
        ScreeningType.ANNUAL_PHYSICAL,
        ScreeningType.ANY_OTHER_CANCER_SCREENING,
        ScreeningType.BONE_DENSITY_SCREENING,
        ScreeningType.HUMAN_PAPILLOMAVIRUS_SCREENING,
        ScreeningType.IMMUNIZATION,
        ScreeningType.ULTRASOUND_SCREENING_OF_ABDOMINAL_AORTA
    ] as string[]
    const text = exclusions.includes(claimType) ? claimType + "¹" : claimType;
    if (!claimType) {
        throw new Error(`Invalid claim type: ${ text }`);
    }
    return text;
};