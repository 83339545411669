import React, { useEffect } from 'react';
import { FormikErrors } from "formik";
import AddressModal from "./AddressModal";
import TwoColumnDiv from "./TwoColumnDiv";
import TextInput from "../input/TextInput";
import { ValidateAddressResponse } from "../../service/AddressService";
import StateDropdown from "../input/StateDropdown";
import { EmployeeFormValues } from "../common/EmployeeFormValues";
import { Party } from "../enums/Party";
import Checkbox from "../input/Checkbox";
import { EmployerFormValues } from "../common/EmployerFormValues";
import ZipCodeInput from "../input/ZipCodeInput";
var _ = require('lodash');

export type EmployeeAddressProps = {
    values?: EmployeeFormValues | EmployerFormValues,
    errors?: FormikErrors<any>,
    setFieldValue?: Function,
    validateForm?: Function,
    submitForm?: Function,
    children?: React.ReactNode;
}

const EmployeeAddress = (props: EmployeeAddressProps) => {
    const workStateSameAsResidencyState = props.values?.workStateSameAsResidencyState;

    const setFieldValue = props.setFieldValue;
    const state = props.values?.state;
    useEffect(() => {
        if(setFieldValue) {
            setFieldValue('useEnteredAddress', false, false)
            if(workStateSameAsResidencyState) {
                setFieldValue('workState', state);
            }
        }
    }, [setFieldValue, workStateSameAsResidencyState, state])

    const values = props.values;
    const enteredAddress = {
        streetAddress: values?.streetAddress,
        city: values?.city,
        state: values?.state,
        zipCode: values?.zipCode
    } as ValidateAddressResponse;

    return <>
            {
                <AddressModal  {...props} enteredAddress={enteredAddress} correctedAddress={(props.errors?.streetAddress as any)?.message as any}
                              streetAddressField="streetAddress" cityField="city" stateField="state" zipCodeField="zipCode"/>
            }
            <div>
                <TextInput id="street-address" label={buildLabel(values, 'Street address')} required={true}/>
                <TwoColumnDiv leftContent={<TextInput id="city" label={buildLabel(values, 'City')} required={true}/>}
                              rightContent={<TwoColumnDiv
                                  leftContent={<StateDropdown id="state" label={buildLabel(values, 'State')} required={true}/>}
                                  rightContent={<ZipCodeInput id="zip-code" label={buildLabel(values, 'ZIP', false)} required={true}/>}>
                              </TwoColumnDiv>}/>
                <Checkbox id="work-state-same-as-residency-state" label={buildLabel(values, 'Work state same as residency state')}
                          changeCallback={(target: HTMLInputElement) => {
                              if(target.checked && props.setFieldValue) {
                                  props.setFieldValue('workState', values?.state);
                              }
                          }}/>
                {!workStateSameAsResidencyState && <StateDropdown id="work-state" label={buildLabel(values, 'Work state')} required={true}/>}
            </div>
        </>
};

function buildLabel(values: EmployeeFormValues | EmployerFormValues | undefined, baseLabel: string, lowercase = true) {
    let label = baseLabel;
    if(values?.party === Party.EMPLOYER){
        label = "Employee's " + (lowercase ? _.lowerCase(label) : label);
    }
    return label;
}

export default EmployeeAddress;