import { FormValues, defaultFormValues } from "./FormValues";
import { PaymentPreference } from "../enums/PaymentPreference";

export type EmployeeFormValues = FormValues & {
    middleName: string,
    socialSecurityNumber: string,
    dateOfBirth: string,
    emailAddress: string,
    phoneNumber: string,
    streetAddress: string,
    workState: string,
    workStateSameAsResidencyState: boolean,
    city: string,
    state: string,
    zipCode: string,
    policyNumber: string,
    employerName: string,
    employerZipCode: string,
    employerEmailAddress: string,
    patientFirstName: string,
    patientLastName: string,
    patientDateOfBirth: string,
    physicianFirstName: string,
    physicianLastName: string,
    physicianPhoneNumber: string,
    otherBenefits: boolean,
    paymentPreference: string,
    accountNumber: string,
    accountNumber2: string,
    routingNumber: string,
    accountType: string,
    eftConsent: boolean,
    eftConsentContinue: boolean,
    workRelated: boolean,
    fraud: boolean,
    fraudContinue: boolean
}

export const defaultEmployeeFormValues = {
    ...defaultFormValues,
    middleName: '',
    socialSecurityNumber: '',
    dateOfBirth: '',
    emailAddress: '',
    phoneNumber: '',
    streetAddress: '',
    workState: '',
    workStateSameAsResidencyState: true,
    city: '',
    state: '',
    zipCode: '',
    policyNumber: '',
    employerName: '',
    employerZipCode: '',
    employerEmailAddress: '',
    patientFirstName: '',
    patientLastName: '',
    patientDateOfBirth: '',
    physicianFirstName: '',
    physicianLastName: '',
    physicianPhoneNumber: '',
    otherBenefits: false,
    paymentPreference: PaymentPreference.EFT,
    accountNumber: '',
    accountNumber2: '',
    routingNumber: '',
    accountType: '',
    eftConsent: false,
    eftConsentContinue: false,
    workRelated: false,
    fraud: false,
    fraudContinue: false
}
