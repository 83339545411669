import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import DateInput from "../../input/DateInput";
import RadioGroup from "../../structure/RadioGroup";
import {findPossessiveFor} from "../../common/PersonalLanguage";
import {BeneficiaryFormValues} from "../../common/BeneficiaryFormValues";
import PhoneInput from "../../input/PhoneInput";

type AboutBeneficiaryProps = {
    values?: BeneficiaryFormValues,
    setFieldValue?: Function
}

const AboutBeneficiary = (props: AboutBeneficiaryProps) => {
    const values = props.values;
    const setFieldValue = props.setFieldValue;
    const capitalizedPossessive = findPossessiveFor(values);
    const personFilingIsNotBeneficiary = values?.personFilingIsBeneficiary === 'No'
    return (
        <QuestionPanel headerText="Let's gather some information about the beneficiary."
                       panelContent={
                               <div>
                                   <RadioGroup id="person-filing-is-beneficiary"
                                               label="Are you (the person filing this form) the beneficiary?"
                                               required={true}
                                               radioProps={[
                                                   { id: "yes", text: "Yes", changeCallback: () => {
                                                       setFieldValue?.('personFilingFirstName', '');
                                                       setFieldValue?.('personFilingLastName', '');
                                                       setFieldValue?.('personFilingPhoneNumber', '');
                                                   }},
                                                   { id: "no", text: "No", changeCallback: () => {
                                                       setFieldValue?.('beneficiaryFirstName', '');
                                                       setFieldValue?.('beneficiaryLastName', '');
                                                       setFieldValue?.('beneficiaryDateOfBirth', '');
                                                   }}
                                               ]}/>
                                   <TextInput id="beneficiary-first-name" label={`${capitalizedPossessive} first name`} required={true} maxLength={1024}/>
                                   <TextInput id="beneficiary-last-name" label={`${capitalizedPossessive} last name`} required={true} maxLength={1024}/>
                                   <DateInput id="beneficiary-date-of-birth" label={`${capitalizedPossessive} date of birth`} required={true}/>
                                   {personFilingIsNotBeneficiary &&
                                       <>
                                            <TextInput id="person-filing-first-name" label="Your first name" required={true} maxLength={1024}/>
                                            <TextInput id="person-filing-last-name" label="Your last name" required={true} maxLength={1024}/>
                                            <PhoneInput id="person-filing-phone-number" label="Your phone number" required={true}/>
                                       </>}
                               </div>}
        >
        </QuestionPanel>
    )
};
export default AboutBeneficiary;