import Modal from "./Modal";
import React from "react";
import Checkbox from "../input/Checkbox";
import { ModalSize } from "../enums/ModalSize";
import CenteredFullWidthDiv from "./CenteredFullWidthDiv";
import Button from "../button/Button";
import { EmployeeFormValues } from "../common/EmployeeFormValues";
import { FormikErrors } from "formik";
import { PfmlEmployerFormValues } from "../pfml/PfmlEmployerFormValues";

export type EftModalProps = {
    values?: EmployeeFormValues | PfmlEmployerFormValues,
    errors?: FormikErrors<any>,
    setFieldValue?: Function,
    submitForm?: Function,
    children?: React.ReactNode
}

const EftModal = (props: EftModalProps) => {
    const values = props.values;
    const errors = props.errors;
    const consent = values?.eftConsent;
    const consentContinue = values?.eftConsentContinue;
    const consentError = errors?.eftConsent;

    if(!consentError && ((!consent && !consentContinue) || (consent && consentContinue))) {
        return null;
    }
    return <Modal id="eft-consent-validation"
                  headerText="EFT authorization agreement"
                  modalContent={
                      <div>
                          I Hereby Authorize:
                          <ul>
                              <li>
                                  The Company to initiate credit entries to my account, at the financial institution named above (herein called Bank).
                              </li>
                              <li>
                                  The Company, if necessary, to initiate debit entries and adjustments to correct any credit entries made in error.
                              </li>
                              <li>
                                  The Bank to credit and/or debit entries to my account.
                              </li>
                          </ul>
                          This Authorization:
                          <ul>
                              <li>
                                  Applies to any payments that hereafter become due and payable to me under the provisions of the contract(s) identified by the above Account Number.
                              </li>
                              <li>
                                  This authorization is to remain in full force and effect until Principal Life Insurance Company has written notice from me of its termination.
                              </li>
                              <li>
                                  I understand and agree that any payment(s) made into an incorrect bank account pursuant to the information reported on this form, will be forfeited by me and that Principal Life Insurance has no obligation to retrieve those funds or make replacement payment(s) to me.
                              </li>
                          </ul>
                          <CenteredFullWidthDiv divContent={<>
                              <Checkbox id="eft-consent" label="I authorize"/>
                              <br/>
                              Note: If you wish to change your payment preference, please authorize this EFT consent, and go back to the payments page.
                          </>}/>
                      </div>}
                  modalFooter={
                      <>
                          { consent && <Button buttonContent="Continue" onClick={async () => {
                              if (props.setFieldValue) {
                                  props.setFieldValue('eftConsentContinue', true);
                              }
                              if (props.submitForm) {
                                  await props.submitForm();
                              }
                          }}/> }
                      </>}
                  modalSize={ModalSize.LARGE}
                  closeable={false}/>;
}

export default EftModal;
