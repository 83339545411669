import React, { useEffect, useRef } from 'react';
import TextInput from "./TextInput";
import { FormikValues } from "formik";
import { PdsFileUpload } from "@principal/design-system-react";

var _ = require('lodash');

type FileInputProps = {
    id: string,
    name?: string,
    label: string,
    required: boolean,
    supportedExtensions: string[],
    values?: FormikValues,
    setFieldValue?: Function,
    setFieldError?: Function,
    children?: React.ReactNode
}

const FileInput = (props: FileInputProps) => {
    const name = props.name;
    const visibleName = name ? name : _.camelCase(props.id);
    const inputId = props.id + '-content';
    const inputName = name ? name + "Content" : _.camelCase(inputId);
    const fileName = _.get(props.values, visibleName)

    const inputRef = useRef(null) as React.MutableRefObject<any>

    useEffect(() => {
        const currentInput = inputRef.current;
        currentInput.uploadedFilesArray = currentInput.uploadedFilesArray.map((uploadedFile: any) => ({
            ...uploadedFile,
            name: fileName
        }))
    }, [fileName])

    return <TextInput id={props.id}
                      name={visibleName}
                      label={props.label}
                      required={props.required}
                      inputComponent={PdsFileUpload as React.FunctionComponent<React.ComponentProps<'input'>>}
                      inputProps={{
                          accept: props.supportedExtensions.join(),
                          id: inputId,
                          name: inputName,
                          ref: inputRef,
                          pdsFileUploadChange: async (event: CustomEvent) => {
                              const file = (event.detail as any)?.summary?.[0];
                              if (!file) {
                                  props.setFieldError?.(inputName, 'File could not be read. Please try again.');
                                  return;
                              }
                              props.setFieldValue?.(visibleName, file.name)
                              props.setFieldValue?.(inputName, await getFileContentsFrom(file))
                          },
                          pdsFileUploadRemove: () => {
                              props.setFieldValue?.(visibleName, '')
                              props.setFieldValue?.(inputName, '')
                          }
                      }}
    />
};

async function getFileContentsFrom(file: File) {
    return new Promise<string|ArrayBuffer|null>((resolve) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });
}

export default FileInput;