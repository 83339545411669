import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import Modal from "../structure/Modal";
import Button from "../button/Button";
import CenteredFullWidthDiv from "../structure/CenteredFullWidthDiv";
import Panel from "../structure/Panel";
import { BenefitType, findAbbreviationFor, findFullNameFor, findPartyChoicesFor } from "../enums/BenefitType";
import { findPartyChoiceTextFor, Party } from "../enums/Party";
import { PdsButton } from "@principal/design-system-react";

export type PartyChoiceProps = {
}

const PartyChoice = (props: PartyChoiceProps) => {
    const params = useParams();
    const benefitType = params?.coverage as BenefitType;
    const fullName = findFullNameFor(benefitType);
    const abbreviation = findAbbreviationFor(benefitType);
    const partyChoices = findPartyChoicesFor(benefitType);
    const [showModal, setShowModal] = useState(true);
    return (
        <>
            <Panel headerText={`Ready to submit a claim for the Principal ${abbreviation} plan? You're in the right place.`}
                   panelContent={
                       <>
                           <div className="pds-u-padding-bottom-24">
                               Are you completing this claim form as a(n):
                           </div>
                           <CenteredFullWidthDiv divContent=
                                                     {<>
                                                         {partyChoices.map((party: Party) => (
                                                             <div className="pds-u-padding-bottom-8">
                                                                 <Link to={`/${benefitType}/${party}`}>
                                                                     <PdsButton variant="primary">
                                                                         <div style={{ width: "280px" }}>
                                                                             {findPartyChoiceTextFor(party)}
                                                                         </div>
                                                                     </PdsButton>
                                                                 </Link>
                                                             </div>
                                                         ))}
                                                     </>}>
                           </CenteredFullWidthDiv>
                       </>}
            >
            </Panel>
            {showModal && <Modal id="getting-started-modal"
                    headerText="Let's get this out of the way..."
                    closeable={true}
                    modalContent={<div id="getting-started-modal-content" className="pds-u-padding-bottom-20">Only individuals whose
                        employers are insured through Principal for {fullName} are eligible to complete this online claim
                        submission.<br/><br/>If you're not sure who handles your employer's {fullName} coverage, please
                        check with your employer prior to proceeding with this online claim submission.<br/><br/>
                        If you don't wish to file a {fullName} claim through Principal, click <a
                            href="https://www.principal.com">here</a> to be
                        returned to our homepage.<br/><br/>If you're eligible to complete this online submission and wish to file
                        a {fullName} claim...</div>}
                    modalFooter={<Button buttonContent="Let's go!" onClick={(e: any) => setShowModal(false)}/>}
            />}
        </>
    )
};
export default PartyChoice;
