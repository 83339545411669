import React, { useEffect, useState } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import DateInput from "../../input/DateInput";
import SelectDropdown from "../../input/SelectDropdown";
import MoneyInput from "../../input/MoneyInput";
import TextInput from "../../input/TextInput";
import PercentageInput from "../../input/PercentageInput";
import RadioGroup from "../../structure/RadioGroup";
import { Taxation } from "../../enums/Taxation";
import { DisabilityEmployerFormValues } from "../../disability/DisabilityEmployerFormValues";
import Checkbox from "../../input/Checkbox";
import AddListElementButton from "../../button/AddListElementButton";
import Pay from "../../input/Pay";
import { PayFrequency } from "../../enums/PayFrequency";
import LoadingSpinner from "../../structure/LoadingSpinner";
import { useAuth } from "oidc-react";
import * as memberService from "../../../service/MemberService";
import { EmployerFormValues } from "../../common/EmployerFormValues";
import { CompassSalaryChangeEffectiveDate } from "../../enums/CompassSalaryChangeEffectiveDate";
import { DateTime } from "luxon";
import { CompassDefinitionOfEarnings } from "../../enums/CompassDefinitionOfEarnings";
import { Member } from "../../../../service/src/domain/Member";
import { findProductFor, Product } from "../../enums/Product";
import { DisabilityProduct } from "../../enums/DisabilityProduct";
import { BenefitType } from "../../enums/BenefitType";
import { Party } from "../../enums/Party";
import { Relationship } from "../../enums/Relationship";
import { LifeEmployerFormValues } from "../../life/LifeEmployerFormValues";
import LifeBenefit from "../../input/LifeBenefit";
import { PfmlEmployerFormValues } from "../../pfml/PfmlEmployerFormValues";
import _ from "lodash";
import { findEmployerReimbursementFor } from "../../../service/EmployerReimbursementService";
import HorizontalRule from "../../structure/HorizontalRule";

export type FinancialProps = {
    initialValues?: EmployerFormValues
    values?: EmployerFormValues
    setFieldValue?: Function
}

const Financial = (props: FinancialProps) => {
    const [stateSupportsReimbursement, setStateSupportsReimbursement] = useState(undefined as any as boolean);
    const [member, setMember] = useState();
    const [wageAsOfDate, setWageAsOfDate] = useState(props.values?.dateLastWorked);
    const auth = useAuth();

    const values = props.values;
    const initialValues = props.initialValues;
    const benefit = values?.benefit as BenefitType;
    const dateLastWorked = values?.dateLastWorked as string;
    const fullPayAfterDateLastWorked = values?.fullPayAfterDateLastWorked;
    const earningsQuarters = (values as any as PfmlEmployerFormValues)?.earningsQuarters;
    const policyNumber = values?.policyNumber;
    const privacyId = values?.privacyId;
    const party = values?.party as Party;
    const disabilityProduct = (values as DisabilityEmployerFormValues)?.product as DisabilityProduct;
    const product = findProductFor(benefit, disabilityProduct) as Product;
    const setFieldValue = props.setFieldValue;
    const userData = auth.userData;
    const signedIn = !!userData;
    const token = userData?.access_token;
    const bonusUpQuestion = findBonusUpQuestionFor((member as any as Member)?.definitionOfEarnings);
    const initialQuarter = DateTime.fromFormat(dateLastWorked, 'MM/dd/yyyy').startOf('quarter')
    const earningsQuartersDates = earningsQuarters?.map((_, index) => {
        const currentStartOfQuarter = initialQuarter.minus({ quarters: earningsQuarters.length - index })
        return {
            startDate: currentStartOfQuarter.toFormat('MM/dd/yyyy'),
            endDate: currentStartOfQuarter.endOf('quarter').toFormat('MM/dd/yyyy')
        }
    })
    const earningsQuartersWithDates = _.merge(earningsQuarters, earningsQuartersDates)
    const workState = values?.workState;
    const employerReimbursement = (values as PfmlEmployerFormValues)?.employerReimbursement;
    const employerReimbursementSupported = (initialValues as any as PfmlEmployerFormValues)?.employerReimbursement !== undefined
        && fullPayAfterDateLastWorked === 'Yes';
    const employerReimbursementLoading = employerReimbursementSupported && !employerReimbursement && stateSupportsReimbursement === undefined;
    const employerReimbursementEnabled = employerReimbursementSupported && (employerReimbursement || stateSupportsReimbursement);
    const memberLoading = signedIn && !member;
    const loading = memberLoading || employerReimbursementLoading

    useEffect(() => {
        async function loadReimbursement() {
            if (!employerReimbursementSupported) {
                return
            }
            setStateSupportsReimbursement(undefined as any as boolean);
            const reimbursement = await findEmployerReimbursementFor(workState as string);
            if(!reimbursement) {
                setFieldValue?.('employerReimbursement', '');
            }
            setStateSupportsReimbursement(reimbursement);
        }

        loadReimbursement();
    }, [workState, employerReimbursementSupported, setFieldValue])

    useEffect(() => {
        async function loadMember() {
            if (!signedIn) {
                return;
            }
            setMember(undefined);
            const memberAsOfDateLastWorked = await memberService.findMemberFor(policyNumber, privacyId, token, party, dateLastWorked, product);
            const wageAsOfDate = calculateWageAsOfDateFor(memberAsOfDateLastWorked.salaryChangeEffectiveDate, dateLastWorked);
            setWageAsOfDate(wageAsOfDate);
            const member = await memberService.findMemberFor(policyNumber, privacyId, token, party, wageAsOfDate, product);
            setFieldValue?.('baseWage', member.baseWage);
            setFieldValue?.('baseWageFrequency', member.baseWageFrequency);
            setFieldValue?.('baseWageEffectiveDate', member.baseWageEffectiveDate);
            setFieldValue?.('employeeContributionPercentage', member.employeeContributionPercentage);
            setFieldValue?.('premiumTaxation', member.premiumTaxation);
            setMember(member as any);
        }

        loadMember();
    }, [policyNumber, privacyId, signedIn, token, setFieldValue, setMember, party, dateLastWorked, product]);

    useEffect(() => {
        if(!earningsQuartersWithDates?.length) {
            return;
        }
        setFieldValue?.('earningsQuarters', earningsQuartersWithDates);
    }, [earningsQuartersWithDates, setFieldValue]);

    useEffect(() => {
        setFieldValue?.('loading', loading);
        return () => setFieldValue?.('loading', false);
    }, [loading, setFieldValue]);

    if (memberLoading) {
        return <LoadingSpinner spinnerText='Loading'/>
    }

    return (
        <QuestionPanel headerText="Tell us about your employee's financial details."
                       detailsText="This information helps determine the benefit amount."
                       panelContent={<>
                           <div>
                               {buildEarningsQuestions()}
                               {buildWageQuestions()}
                               {Relationship.EMPLOYEE === values?.relationship &&
                                   <>
                                       <RadioGroup id="premiums-paid-through-death"
                                                   label="Were premiums paid through the date of death?"
                                                   required={true} radioProps={[
                                           {
                                               id: "yes", text: "Yes", changeCallback: () => {
                                                   props.setFieldValue?.('datePremiumsPaidThrough', '')
                                               }
                                           },
                                           {id: "no", text: "No"}]}/>
                                       {(values as any as LifeEmployerFormValues)?.premiumsPaidThroughDeath === "No" &&
                                           <DateInput id='date-premiums-paid-through'
                                                      label="What date were premiums paid through?"
                                                      required={true}/>}
                                   </>}
                               {(initialValues as any as LifeEmployerFormValues)?.benefits
                                   && <label>Please fill in the dollar amount for each benefit that applies:</label>}
                               <AddListElementButton idPrefix="benefits"
                                                     buttonText="Add Benefit"
                                                     componentType={LifeBenefit}
                                                     elementInitialValue={{
                                                         claimed: '',
                                                         amount: ''
                                                     }}
                                                     values={values}
                                                     setFieldValue={setFieldValue}/>
                               {<>
                                   {employerReimbursementLoading
                                       ? <LoadingSpinner spinnerText='Loading'/>
                                       : (employerReimbursementEnabled
                                           && <>
                                               <RadioGroup id="employer-reimbursement"
                                                           label={`Will you (the policyholder) be requesting reimbursement for wages paid after ${dateLastWorked}?`}
                                                           required={true}
                                                           radioProps={[{id: "yes", text: "Yes"}, {
                                                               id: "no",
                                                               text: "No"
                                                           }]}
                                               />
                                               {'Yes' === (values as any as PfmlEmployerFormValues)?.employerReimbursement
                                                   && <>
                                                       <HorizontalRule/>
                                                       <div>
                                                           <label className='block-label'>
                                                               Policyholder as an agent of Principal® understands and
                                                               acknowledges that it is the
                                                               responsibility of Policyholder to withhold and remit
                                                               accurate taxes from compensation paid to the employee
                                                               representing the PFML benefit.
                                                           </label>
                                                           <label className='block-label'>
                                                               Policyholder as an agent of Principal understands and
                                                               acknowledges that it is the
                                                               responsibility of Policyholder to report compensation
                                                               representing PFML benefits on Form W-2 and/or Form
                                                               1099-MISC.
                                                           </label>
                                                           <label className='block-label'>
                                                               Policyholder agrees to indemnify, hold harmless, and
                                                               release Principal from any liability
                                                               and damages associated with the actions herein
                                                               described perform by Policyholder as an agent of
                                                               Principal.
                                                           </label>
                                                           <label className='block-label'>
                                                               Principal agrees to reimburse the Policyholder for
                                                               benefits paid in advance. Policyholder
                                                               will only be reimbursed for days paid that
                                                               Principal has deemed the employee is eligible for
                                                               benefits and at the amount the
                                                               Principal has calculated is due.
                                                           </label>
                                                           <label className='block-label'>
                                                               The PFML reimbursement will be issued via check
                                                               separately by employee.
                                                           </label>
                                                           <label className='block-label'>
                                                               This agreement may be terminated by Principal anytime.
                                                           </label>
                                                           <Checkbox id="employer-reimbursement-consent"
                                                                     label="I agree"/>
                                                       </div>
                                                   </>}
                                           </>
                                       )
                                   }
                               </>}
                           </div>
                       </>}>
        </QuestionPanel>
    )

    function buildEarningsQuestions() {
        if(!earningsQuartersDates?.length) {
            return null;
        }
        return <>
            {earningsQuartersDates.map((earningsQuarterDates, index) => {
                return (
                    <MoneyInput
                        id={`earnings-quarters-amount-${index}`}
                        name={`earningsQuarters[${index}].amount`}
                        label={`Employee's earnings from ${earningsQuarterDates.startDate} to ${earningsQuarterDates.endDate}`}
                        required={true}
                    />
                )
            })}
        </>
    }

    function buildWageQuestions() {
        const showWageQuestions = benefit !== BenefitType.LIFE || values?.relationship === Relationship.EMPLOYEE;
        if (!showWageQuestions) {
            return null;
        }
        return <>
            <PercentageInput id="employee-contribution-percentage"
                             label={`Percent of premium paid by employee on ${wageAsOfDate}`}
                             required={true}
                             changeCallback={employeeContributionChange}/>
            {parseFloat((props.values as DisabilityEmployerFormValues)?.employeeContributionPercentage) > 0
                && <RadioGroup id="premium-taxation"
                               label="Is your employee's portion of premium paid with:"
                               required={true}
                               radioProps={[{id: "pre-tax", text: Taxation.PRE_TAX}, {
                                   id: "post-tax",
                                   text: Taxation.POST_TAX
                               }]}/>}
            <MoneyInput id="base-wage" label={`Employee's base pay on ${wageAsOfDate}`} required={true}/>
            <SelectDropdown id="base-wage-frequency" label={`Pay frequency on ${wageAsOfDate}`} required={true}
                            options={Object.keys(PayFrequency).map(frequency => ({
                                id: frequency.replace(/_/g, '-').toLowerCase(),
                                text: (PayFrequency as any)[frequency]
                            }))}/>
            <DateInput id="base-wage-effective-date" label={`Effective date of pay rate on ${wageAsOfDate}`}
                       required={true}/>
            <RadioGroup id="pay-after-date-last-worked"
                        label={`Will your employee receive any type of pay for time after ${wageAsOfDate}?`}
                        required={true}
                        radioProps={[
                            {id: "yes", text: "Yes", changeCallback: payAfterDateLastWorkedChange},
                            {id: "no", text: "No", changeCallback: payAfterDateLastWorkedChange}
                        ]}/>
            {props.values?.payAfterDateLastWorked === 'Yes' &&
                <RadioGroup id="full-pay-after-date-last-worked"
                            label={`Is your employee receiving full pay after ${dateLastWorked}?`}
                            required={true}
                            radioProps={[
                                {id: "yes", text: "Yes", changeCallback: fullPayAfterDateLastWorkedChange},
                                {id: "no", text: "No", changeCallback: fullPayAfterDateLastWorkedChange}
                            ]}/>}
            {fullPayAfterDateLastWorked &&
                (fullPayAfterDateLastWorked === 'Yes'
                    ? <>
                        <AddListElementButton {...props} idPrefix="pay-types-after-date-last-worked"
                                              buttonText="Add Pay"
                                              componentType={Pay}
                                              elementInitialValue={{payType: '', endDate: ''}}
                                              label={(initialValues as DisabilityEmployerFormValues)?.payTypesAfterDateLastWorked
                                                  ? "Please provide the employee's pay types and dates paid through."
                                                  : ""}/>
                        <DateInput id="full-pay-after-date-last-worked-end-date" label="Payment end date"
                                   required={true}/>
                    </>
                    : <TextInput id="pay-after-date-last-worked-amount" label="Amount paid" required={true}/>)
            }
            <Checkbox id="employee-ownership" label="Does your employee have any ownership in your organization?"
                      changeCallback={employeeOwnershipChange}/>
            {(props.values as DisabilityEmployerFormValues)?.employeeOwnership
                && <PercentageInput id="employee-ownership-percentage" label="Ownership percentage" required={true}/>}
            {bonusUpQuestion
                && <Checkbox id="bonuses-or-commissions" label={bonusUpQuestion}/>}
        </>;
    }

    function payAfterDateLastWorkedChange(radio: HTMLInputElement) {
        if (radio.value !== 'Yes' && props.setFieldValue) {
            props.setFieldValue('fullPayAfterDateLastWorked', '')
            fullPayAfterDateLastWorkedChange({value: ''} as any)
        }
    }

    function fullPayAfterDateLastWorkedChange(radio: HTMLInputElement) {
        const fullPayAfterDateLastWorked = radio.value === 'Yes';
        if (props.setFieldValue) {
            if (!radio.value || fullPayAfterDateLastWorked) {
                props.setFieldValue('payAfterDateLastWorkedAmount', '')
            }
            if (!radio.value || !fullPayAfterDateLastWorked) {
                props.setFieldValue('fullPayAfterDateLastWorkedEndDate', '')
                props.setFieldValue('employerReimbursement', '')
            }
        }
    }

    function employeeContributionChange(target: HTMLInputElement) {
        if ((!target.value || parseFloat(target.value) === 0) && props.setFieldValue) {
            props.setFieldValue('premiumTaxation', '')
        }
    }

    function employeeOwnershipChange(checkbox: HTMLInputElement) {
        if (!checkbox.checked && props.setFieldValue) {
            props.setFieldValue('employeeOwnershipPercentage', '')
        }
    }
};

function calculateWageAsOfDateFor(salaryChangeEffectiveDate: CompassSalaryChangeEffectiveDate, dateLastWorked?: string) {
    if (!dateLastWorked) {
        return;
    }
    let wageAsOfDate = DateTime.fromFormat(dateLastWorked, 'MM/dd/yyyy');
    if (salaryChangeEffectiveDate === CompassSalaryChangeEffectiveDate._1ST_OF_COINCIDING_FOLLOWING_MONTH) {
        wageAsOfDate = wageAsOfDate.startOf('month')
    } else if (salaryChangeEffectiveDate === CompassSalaryChangeEffectiveDate._15TH_OF_COINCIDING_FOLLOWING_MONTH) {
        wageAsOfDate = (wageAsOfDate.day <= 14 ? wageAsOfDate.minus({months: 1}) : wageAsOfDate).set({day: 15})
    }
    return wageAsOfDate.toFormat('MM/dd/yyyy')
}

function findBonusUpQuestionFor(definitionOfEarnings: CompassDefinitionOfEarnings) {
    if (definitionOfEarnings === CompassDefinitionOfEarnings.BASE_WAGE_WITH_BONUS) {
        return "Does your employee earn bonus(es)?"
    } else if (definitionOfEarnings === CompassDefinitionOfEarnings.BASE_WAGE_WITH_COMMISSION) {
        return "Does your employee earn commission(s)?"
    } else if (definitionOfEarnings === CompassDefinitionOfEarnings.BASE_WAGE_WITH_BONUS_AND_COMMISSION) {
        return "Does your employee earn bonus(es) or commission(s)?"
    }
    return null;
}

export default Financial;