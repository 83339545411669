import axios from "axios";
import { Party } from "../component/enums/Party";
import { EmployerFormValues } from "../component/common/EmployerFormValues";

export async function findEmployeeFor(privacyId: string, token?: string): Promise<EmployerFormValues> {
    if (!token) {
        return {} as EmployerFormValues;
    }
    return sendGetRequest(`${process.env.REACT_APP_FORMS_SERVICE_URL}/affiliate?party=${Party.EMPLOYER}&privacyId=${privacyId}`, token)
        .then(r => {
            return r.data as EmployerFormValues;
        }).catch(e => {
            handleError(e);
            return {} as EmployerFormValues;
        });
}

async function sendGetRequest(url: string, token: string) {
    return await axios.get(
        url,
        await getAuthorizationHeaders(token)
    )
}

async function getAuthorizationHeaders(token: string) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
}

function handleError(e: any) {
    if (e.response) {
        const errorData = JSON.stringify(e.response.data);
        if(!errorData?.includes('not found')) {
            console.log(e.toString().substring(7) + ': ' + errorData)
        }
    } else {
        console.log(e)
    }
}
