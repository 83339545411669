import { defaultDisabilityPhysicianFormValues, DisabilityPhysicianFormValues } from "./DisabilityPhysicianFormValues";
import { TypeOfClaim } from "../enums/TypeOfClaim";

export type MentalHealthDisabilityPhysicianFormValues = DisabilityPhysicianFormValues & {
    mood: string,
    affect: string,
    psychomotorActivity: string,
    orientation: string[],
    concentration: string,
    memory: string,
    suicidal: string,
    visitFrequency: string,
    restrictedJobDuties: string,
    restrictedPhysical: string,
    restrictedFamily: string,
    restrictedSocial: string,
    restrictedDailyLiving: string,
    workForDifferentEmployer: string,
    returnToWorkTreatmentPlan: string
}

export const defaultMentalHealthDisabilityPhysicianFormValues = {
    ...defaultDisabilityPhysicianFormValues,
    claimType: TypeOfClaim.MENTAL_HEALTH,
    mood: '',
    affect: '',
    psychomotorActivity: '',
    orientation: [],
    concentration: '',
    memory: '',
    suicidal: '',
    visitFrequency: '',
    restrictedJobDuties: '',
    restrictedPhysical: '',
    restrictedFamily: '',
    restrictedSocial: '',
    restrictedDailyLiving: '',
    workForDifferentEmployer: '',
    returnToWorkTreatmentPlan: ''
}