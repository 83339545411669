import React, { useState } from 'react';
import SelectDropdown from "../../input/SelectDropdown";
import { Relationship } from "../../enums/Relationship";
import LoadingSpinner from "../../structure/LoadingSpinner";
import { DisabilityProduct } from "../../enums/DisabilityProduct";
import { useAuth } from "oidc-react";
import * as memberService from "../../../service/MemberService";
import { findProductFor, Product } from "../../enums/Product";
import { Dependent } from '../../../../service/src/domain/Dependent';
import { Member } from '../../../../service/src/domain/Member';
import { FormikValues } from "formik";
import { Party } from "../../enums/Party";

export type DependentChoiceProps = {
    values?: FormikValues,
    setFieldValue?: Function,
}

const DependentChoice = (props: DependentChoiceProps) => {
    const values = props.values as any;
    const setFieldValue = props.setFieldValue;
    const benefit = values?.benefit;
    const relationship = values?.relationship;
    const policyNumber = values?.policyNumber;
    const privacyId = values?.privacyId;
    const claimDates = values?.claimDates;
    const party = values?.party;
    const effectiveDate = claimDates?.[0]?.startDate;
    const disabilityProduct = values?.product as DisabilityProduct;
    const product = findProductFor(benefit, disabilityProduct) as Product;
    const auth = useAuth();

    const userData = auth.userData;
    const signedIn = !!userData;
    const token = userData?.access_token;

    const [member, setMember] = useState(undefined as any as Member);

    const loading = signedIn && !member;

    React.useEffect(() => {
        async function loadMember() {
            if (!signedIn || member) {
                return;
            }
            const serviceMember = await memberService.findMemberFor(policyNumber, privacyId, token, party, effectiveDate, product)
            setMember(serviceMember as any);
        }
        loadMember();
    }, [policyNumber, privacyId, signedIn, token, setFieldValue, member, party, effectiveDate, product]);

    React.useEffect(() => {
        setFieldValue?.('loading', loading);
        return () => setFieldValue?.('loading', false);
    }, [loading, setFieldValue])

    const employeeFiling = Party.EMPLOYEE === party
    const dependentRelationship = Relationship.COVERED_DEPENDENT === relationship;
    if(!employeeFiling || !dependentRelationship || !signedIn) {
        return null;
    } else if(!member) {
        return <LoadingSpinner spinnerText="Loading"/>
    } else if(!member.dependents?.length) {
        return null;
    }
    return <SelectDropdown id="dependents" label="Dependents" required={false}
                           options={member?.dependents?.map((d: Dependent, index: number) => {
                               return ({
                                   id: "" + index,
                                   text: `${(buildFullNameFor(d))} (Date of birth: ${d.dateOfBirth})`,
                                   value: d.nameId
                               });
                           })}
                           changeCallback={(target: HTMLInputElement) => {
                               const dependent = member.dependents.find(d => d.nameId === target.value);
                               setFieldValue?.("patientFirstName", dependent?.firstName ?? "");
                               setFieldValue?.("patientLastName", dependent?.lastName ?? "")
                               setFieldValue?.("patientDateOfBirth", dependent?.dateOfBirth ?? "");
                           }}/>
};

function buildFullNameFor(d?: Dependent) {
    if(!d) {
        return ''
    }
    return `${d.firstName} ${d.middleName ? `${d.middleName} ` : ''}${d.lastName}`;
}

export default DependentChoice;