import React, { ReactElement } from 'react';
import TextInput from "./TextInput";
import { PdsPhoneNumberInput } from "@principal/design-system-react";

type PhoneInputProps = {
    id: string,
    name?: string,
    label: string,
    required: boolean,
    labelContent?: ReactElement,
    children?: React.ReactNode
}

const PhoneInput = (props: PhoneInputProps) => {
    return (
        <TextInput id={props.id} name={props.name} label={props.label} required={props.required}
                   inputComponent={PdsPhoneNumberInput as React.FunctionComponent<React.ComponentProps<'input'>>}
                   inputMask={{ mask: '(999) 999-9999' }}
                   labelContent={props.labelContent}
                   maxLength={14}
                   validate={validatePhoneNumber}/>
    )

    function validatePhoneNumber(value: string) {
        return value?.match(/^\(\d{3}\) ?\d{3}-?\d{4}$/gi) ? '' : 'Phone number must be in the format (XXX) XXX-XXXX.'
    }
};

export default PhoneInput;