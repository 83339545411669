import React, { ReactElement } from 'react';
import { Field, FieldHelperProps, useField } from "formik";
import { FieldProps } from "formik/dist/Field";
import { FieldInputProps } from "formik/dist/types";
import { PdsCheckbox } from "@principal/design-system-react";
var _ = require('lodash');

export type CheckboxProps = {
    id: string,
    name?: string,
    label: string,
    labelContent?: ReactElement,
    changeCallback?: Function,
    field?: FieldInputProps<any>,
    children?: React.ReactNode
}

const Checkbox = (props: CheckboxProps) => {
    const name = _.camelCase(props.id);
    const [, meta, helpers] = useField(name);

    return (meta.initialValue === undefined ? null :
        <div>
            <div className="pds-u-inline-block pds-u-margin-bottom-8">
                {createInput(props, name, helpers)}
                {props.labelContent}
            </div>
        </div>
    )
};

function createInput(props: CheckboxProps, name: string, helpers: FieldHelperProps<any>) {
    return <Field component={Input} {...props} name={name} helpers={helpers}/>
}

const Input = ({ field, form, helpers, ...props }: FieldProps & CheckboxProps & {helpers: FieldHelperProps<any>}) => {
    return <PdsCheckbox {...field} id={props.id} fieldId={props.id} label={props.label} checked={field?.value} onChange={(event) => {
        helpers.setError(undefined)
        if (field) {
            field.onChange(event);
        }
        if (props.changeCallback) {
            props.changeCallback(event.target)
        }
    }}>
        <label slot="label" data-field-name={field.name} data-field-label={props.label} htmlFor={props.id}>
            {props.label}{props.labelContent}
        </label>
    </PdsCheckbox>
};

export default Checkbox;