import React, { ReactElement } from 'react';

type CenteredFullWidthDivProps = {
    divContent?: string | ReactElement,
    children?: React.ReactNode
}

const CenteredFullWidthDiv = (props: CenteredFullWidthDivProps) => {
    return (
        <div className="pds-u-text-align-center">
            {props.divContent}
        </div>
    )
};
export default CenteredFullWidthDiv;