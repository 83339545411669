import {Party} from "../enums/Party";
import {FormikStepper} from "../navigation/FormikStepper";
import {defaultPfmlEmployeeFormValues} from "../pfml/PfmlEmployeeFormValues";
import {FormikStep} from "../navigation/FormikStep";
import {EmployeePages} from "../enums/EmployeePages";
import EmployeeStart from "../page/employee/EmployeeStart";
import { object, string } from "yup";
import {
    accountNumberValidator,
    addressValidator,
    dateRangeArrayValidator,
    eftConsentValidator,
    requiredIfFieldNotPresent,
    validateDocumentContent, validateDocumentsTotalSize,
    validateFieldNotEqualTo, validateHasRequiredDocuments,
    validatePolicyNumber,
    validateTwoFieldsNotEqualTo,
    validRoutingNumber
} from "../../form/Validators";
import AboutYou from "../page/employee/AboutYou";
import ContactYou from "../page/employee/ContactYou";
import Employer from "../page/employee/Employer";
import ClaimDates from "../page/employee/ClaimDates";
import ClaimType from "../page/employee/EmployeeClaimType";
import EmployeeDocument from "../page/employee/EmployeeDocument";
import Benefits from "../page/employee/Benefits";
import Payments from "../page/common/Payments";
import Summary from "../page/employee/Summary";
import React, {useEffect} from "react";
import {gatherInitialValues, submitHandler} from "../../service/FormService";
import { BenefitType, findRelationshipsFor } from "../enums/BenefitType";
import {defaultDisabilityEmployeeFormValues} from "../disability/DisabilityEmployeeFormValues";
import {useAuth} from "oidc-react";
import {defaultHospitalIndemnityEmployeeFormValues} from "../indemnity/HospitalIndemnityEmployeeFormValues";
import Finished from "../page/employee/Finished";
import { FormikValues } from "formik";
import {defaultWellnessEmployeeFormValues} from "../wellness/WellnessEmployeeFormValues";
import AboutRelationship from "../page/common/AboutRelationship";
import { useParams } from "react-router-dom";
import Spinner from "../structure/Spinner";

const Employee = () => {
    const auth = useAuth();
    const [formInitialValues, setFormInitialValues] = React.useState();

    const params = useParams();
    const coverage = params.coverage as BenefitType;
    const claimId = params.claimId;
    const token = auth?.userData?.access_token;
    const authLoading = auth.isLoading;

    useEffect(() => {
        async function findInitialValues() {
            const formValues = await gatherInitialValues(claimId, coverage, Party.EMPLOYEE, token);
            setFormInitialValues(formValues)
        }

        if (!authLoading) {
            findInitialValues();
        }
    }, [coverage, claimId, token, authLoading])

    const initialValues = {
        ...findEmployeeValuesFor(coverage),
        ...(formInitialValues ?? {}),
    } as FormikValues;

    if (authLoading || (auth.userData && !formInitialValues)) {
        return <Spinner spinnerText="Loading"/>;
    }
    return <FormikStepper initialValues={initialValues}
                          onSubmit={submitHandler}>
        <FormikStep label={EmployeePages.START}>
            <EmployeeStart/>
        </FormikStep>
        <FormikStep label={EmployeePages.YOUR_INFO} hideStep={() => initialValues.firstName === undefined}>
            <AboutYou/>
        </FormikStep>
        <FormikStep label={EmployeePages.CONTACT_INFO}
                    hideStep={() => initialValues.phoneNumber === undefined}
                    validate={(values) => addressValidator(values, "streetAddress", values.streetAddress, values.city, values.state, values.zipCode)}>
            <ContactYou/>
        </FormikStep>
        <FormikStep label={EmployeePages.EMPLOYER}
                    hideStep={() => initialValues.policyNumber === undefined}
                    validationSchema={object({
                        policyNumber: validatePolicyNumber(),
                        employerName:
                            requiredIfFieldNotPresent('policyNumber', 'If you don\'t specify your employer\'s Principal account ID, please specify your employer\'s name.')
                                .concat(validateTwoFieldsNotEqualTo('firstName', 'lastName', "Your employer's details must not be the same as your personal details.")),
                        employerZipCode: requiredIfFieldNotPresent('policyNumber', 'If you don\'t specify your employer\'s Principal account ID, please specify your employer\'s ZIP code.'),
                        employerEmailAddress:
                            requiredIfFieldNotPresent('policyNumber', 'If you don\'t specify your employer\'s Principal account ID, please specify your employer\'s email address.')
                                .concat(validateFieldNotEqualTo('emailAddress', "Your employer's details must not be the same as your personal details."))
                                .concat(string().email("Please specify a valid email address."))
                    })}>
            <Employer/>
        </FormikStep>
        <FormikStep label={EmployeePages.RELATIONSHIP} hideStep={() => findRelationshipsFor(initialValues.benefit).length < 2}>
            <AboutRelationship/>
        </FormikStep>
        <FormikStep label={EmployeePages.CLAIM_DATES} hideStep={() => initialValues.claimDates === undefined}
                    validationSchema={object({
                        claimDates: dateRangeArrayValidator()
                    })}>
            <ClaimDates/>
        </FormikStep>
        <FormikStep label={EmployeePages.CLAIM_TYPE} hideStep={() => initialValues.claimType === undefined}>
            <ClaimType/>
        </FormikStep>
        <FormikStep label={EmployeePages.BENEFITS}
                    hideStep={() => initialValues.hospitalization === undefined && initialValues.otherBenefits === undefined}>
            <Benefits/>
        </FormikStep>
        <FormikStep label={EmployeePages.DOCUMENT}
                    hideStep={() => initialValues.documents === undefined}
                    validationSchema={object({
                        documents: validateDocumentContent()
                    })}
                    validate={(values) => {
                        return {
                            ...validateDocumentsTotalSize(values),
                            ...validateHasRequiredDocuments(values)
                        }
                    }}>
            <EmployeeDocument/>
        </FormikStep>
        <FormikStep label={EmployeePages.PAYMENTS}
                    hideStep={() => initialValues.paymentPreference === undefined}
                    validationSchema={object({
                        routingNumber: validRoutingNumber(Party.EMPLOYEE),
                        accountNumber2: accountNumberValidator(Party.EMPLOYEE),
                        eftConsent: eftConsentValidator(Party.EMPLOYEE)
                    })}>
            <Payments/>
        </FormikStep>
        <FormikStep label={EmployeePages.SUMMARY}>
            <Summary/>
        </FormikStep>
        <FormikStep label={EmployeePages.FINISH}>
            <Finished/>
        </FormikStep>
    </FormikStepper>
}

function findEmployeeValuesFor(claimType: BenefitType) {
    if (BenefitType.PFML === claimType) {
        return defaultPfmlEmployeeFormValues;
    } else if (BenefitType.DISABILITY === claimType) {
        return defaultDisabilityEmployeeFormValues;
    } else if (BenefitType.HOSPITAL_INDEMNITY === claimType) {
        return defaultHospitalIndemnityEmployeeFormValues;
    } else if (BenefitType.WELLNESS === claimType) {
        return defaultWellnessEmployeeFormValues;
    }
    return {};
}

export default Employee;