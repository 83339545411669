import React from 'react';
import EConsentModal from "../../structure/EConsentModal";
import { EmployeeFormValues } from "../../common/EmployeeFormValues";
import QuestionPanel from "../../structure/QuestionPanel";
import { BenefitType, findDocumentTypesFor } from "../../enums/BenefitType";
import _ from "lodash";
import IconWithContent from "../../structure/IconWithContent";
import { PdsIconAlertTriangle, PdsIconClock, PdsIconFile, PdsIconPhone } from "@principal/design-system-icons-react";
import HorizontalRule from "../../structure/HorizontalRule";

export type EmployeeStartProps = {
    values?: EmployeeFormValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

const EmployeeStart = (props: EmployeeStartProps) => {
    const benefitType = props.values?.benefit as BenefitType;
    const documentTypes = findDocumentTypesFor(benefitType);
    return (
        <QuestionPanel headerText="What to expect on the form for employees"
                       detailsText={<>
                                Need to submit a different claim form?<br/>
                                <a href="https://www.principal.com/help/help-individuals/find-form" rel="noopener noreferrer" target="_blank">
                                    Visit Forms for individuals
                                </a>.<br/><br/>
                                Sign in with your Principal.com credentials, and we will fill in what we know about you. Along the way, you can review and make changes as needed.
                            </>}
                       panelContent={<>
                           <IconWithContent icon={PdsIconClock} content={<span>Allow 10-15 minutes to complete the form.</span>}/>
                           <HorizontalRule/>
                           <IconWithContent icon={PdsIconAlertTriangle} content={<span>Your work isn't saved, and we wouldn't want you to have to start over.</span>}/>
                           <HorizontalRule/>
                           <IconWithContent icon={PdsIconPhone} content={
                               <span>
                                   If you have any questions about your claim, please call (800) 245-1522 between the hours of 7:30 am and 5:00 pm CST.
                               </span>}/>
                           <HorizontalRule/>
                           <IconWithContent icon={PdsIconFile} content={
                               <>
                                   <span style={{ fontWeight: 700 }}>What you'll need to complete this form</span>
                                   <ul>
                                       <li className="pds-u-padding-top-10">Contact information of your employer</li>
                                       <li className="pds-u-padding-top-10">The claim dates you're requesting</li>
                                       <li className="pds-u-padding-top-10">Bank account information for direct deposit (if desired)</li>
                                       {!!documentTypes.length &&
                                           <li className="util-padding-top-10">
                                               <strong>
                                                   Any supporting documentation
                                                   (e.g. {documentTypes.map(type => _.lowerFirst(type)).join(', ')})
                                               </strong>
                                           </li>
                                       }
                                   </ul>
                               </>}/>
                           <EConsentModal {...props}/>
                       </>}
        >
        </QuestionPanel>
    )
};

export default EmployeeStart;