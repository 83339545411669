import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import DateInput from "../../input/DateInput";
import TextInput from "../../input/TextInput";
import AddListElementButton from "../../button/AddListElementButton";
import DateRange from "../../input/DateRange";
import { DosageUnit } from "../../enums/DosageUnit";
import { Direction } from "../../enums/Direction";
import Tooltip from "../../input/Tooltip";
import { DateTime } from "luxon";
import { PhysicianFormValues } from "../../common/PhysicianFormValues";
import Checkbox from "../../input/Checkbox";
import { PregnancyDisabilityPhysicianFormValues } from "../../disability/PregnancyDisabilityPhysicianFormValues";
import { DisabilityPhysicianFormValues } from "../../disability/DisabilityPhysicianFormValues";
import { Medication } from "../../domain/Medication";
import { Procedure } from "../../domain/Procedure";
import { Referral } from "../../domain/Referral";
import ReferralSelection from "../../input/ReferralSelection";
import ProcedureSelection from "../../input/ProcedureSelection";
import MedicationSelection from "../../input/MedicationSelection";

type PatientCareProps = {
    values?: PhysicianFormValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

const PatientCare = (props: PatientCareProps) => {
    const values = props.values;
    const setFieldValue = props.setFieldValue;
    return (
        <QuestionPanel headerText="What is your patient's medical timeline for this condition?"
                       detailsText={"These dates determine the eligibility and length of your patient's leave. "
                           + "If you don't know how long the patient's condition will last, please provide your best estimate."}
                       panelContent={
                           <div>
                               <DateInput id="condition-start-date" label="Date the patient's condition began" required={true}
                                          labelContent={<Tooltip text="This could be the date symptoms first appeared or accident happened."/>}/>
                               <DateInput id="first-visit" label="Date of first visit" required={true}/>
                               <DateInput id="restriction-or-care-start-date" label="Date your patient required care and/or was restricted from working" required={true}/>
                               <DateInput id="last-visit" label="Date of last visit" required={true}/>
                               <DateInput id="delivery-date" label="Date of delivery" required={true}
                                          labelContent={<Tooltip text="If your patient has not already delivered, enter the expected delivery date."/>}
                                          changeCallback={(target: HTMLInputElement) => {
                                              const deliveryDate = DateTime.fromFormat(target.value, 'MM/dd/yyyy')
                                              setFieldValue?.('expectedDelivery', DateTime.now() < deliveryDate);
                                          }}/>
                               <AddListElementButton {...props} idPrefix="medications" componentType={MedicationSelection}
                                                     elementInitialValue={{
                                                         medication: '',
                                                         dosage: '',
                                                         dosageUnit: '' as DosageUnit,
                                                         dosageFrequency: ''
                                                     } as Medication}
                                                     label="Please specify all treatments (medications, surgeries, therapy, and/or referrals)"
                                                     required={true}
                                                     buttonText="Add Medication"/>
                               <AddListElementButton {...props} idPrefix="surgeries" componentType={ProcedureSelection}
                                                     elementInitialValue={{
                                                         procedureCode: '',
                                                         dateOfProcedure: ''
                                                     } as Procedure}
                                                     label=""
                                                     buttonText="Add Surgery"/>
                               <AddListElementButton {...props} idPrefix="therapies" componentType={ProcedureSelection}
                                                     elementInitialValue={{
                                                         procedureCode: '',
                                                         dateOfProcedure: ''
                                                     } as Procedure}
                                                     label=""
                                                     buttonText="Add Therapy"/>
                               <AddListElementButton {...props} idPrefix="referrals" componentType={ReferralSelection}
                                                     elementInitialValue={{
                                                         name: '',
                                                         phoneNumber: '',
                                                         direction: '' as Direction
                                                     } as Referral}
                                                     label=""
                                                     buttonText="Add Referral"/>
                               <Checkbox id="bed-confined" label="Has your patient been bed confined?" changeCallback={(target: HTMLInputElement) => {
                                   if(!target.checked) {
                                       setFieldValue?.('bedConfinedStartDate', '')
                                       setFieldValue?.('bedConfinedEndDate', '')
                                   }
                               }}/>
                               {(values as any as PregnancyDisabilityPhysicianFormValues)?.bedConfined && <DateRange idPrefix="bed-confined"/>}
                               <DateInput id="next-visit" label="Date of next visit" required={false}/>
                               {!(values as any as DisabilityPhysicianFormValues)?.restrictingPermanently
                                    && <DateInput id="restriction-or-care-end-date" label="Expected date the patient is restricted through or will no longer require care" required={true}/>}
                               <Checkbox id="restricting-permanently" label="Restricting permanently" changeCallback={(target: HTMLInputElement) => {
                                   if(target.checked) {
                                       setFieldValue?.('restrictionOrCareEndDate', '')
                                   }
                               }}/>
                               <TextInput id="visit-frequency" label="Frequency of visits" required={true}/>
                               <AddListElementButton {...props}
                                                     idPrefix="substance-abuse-dates" componentType={DateRange}
                                                     elementInitialValue={{startDate: '', endDate: ''}}
                                                     label="If condition is substance abuse, provide dates of inpatient treatment"
                                                     required={false}
                                                     buttonText="Add Date Range"/>
                           </div>
                       }>
        </QuestionPanel>
    )
};
export default PatientCare;