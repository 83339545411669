import _ from "lodash";
import { AffiliatePostalAddressType } from "./AffiliatePostalAddressType";
import { AffiliateEmailAddressType } from "./AffiliateEmailAddressType";
import { AffiliatePhoneNumberType } from "./AffiliatePhoneNumberType";

export enum Party {
    EMPLOYEE = 'employee',
    EMPLOYER = 'employer',
    PHYSICIAN = 'physician',
    BENEFICIARY = 'beneficiary'
}

export function findPartyAbbreviationFor(party: Party) {
    if (Party.EMPLOYEE === party) {
        return 'EE'
    } else if (Party.EMPLOYER === party) {
        return 'ER'
    } else if (Party.PHYSICIAN === party) {
        return 'APS'
    } else if (Party.BENEFICIARY === party) {
        return 'BENE';
    }
}

export function findPartyChoiceTextFor(party: Party) {
    if (Party.BENEFICIARY === party) {
        return 'Employee/beneficiary';
    }
    return _.capitalize(party);
}

export function findPostalAddressTypesFor(party: Party): AffiliatePostalAddressType[] {
    if (Party.EMPLOYEE === party) {
        return [AffiliatePostalAddressType.PRIMARY_MAILING, AffiliatePostalAddressType.MAILING_TWO,
            AffiliatePostalAddressType.RESIDENTIAL, AffiliatePostalAddressType.PURCHASED_ADDRESS, AffiliatePostalAddressType.PURCHASED_RESIDENTIAL];
    } else if (Party.EMPLOYER === party) {
        return [AffiliatePostalAddressType.PURCHASED_MAILING, AffiliatePostalAddressType.BUSINESS_ONE,
            AffiliatePostalAddressType.BUSINESS_TWO, AffiliatePostalAddressType.PURCHASED_ADDRESS, AffiliatePostalAddressType.PURCHASED_BUSINESS];
    }
    return [];
}

export function findEmailAddressTypesFor(party: Party): AffiliateEmailAddressType[] {
    if (Party.EMPLOYEE === party) {
        return [AffiliateEmailAddressType.HOME_ONE, AffiliateEmailAddressType.HOME_TWO, AffiliateEmailAddressType.PURCHASED_HOME_DISCOVERY];
    } else if (Party.EMPLOYER === party) {
        return [AffiliateEmailAddressType.PURCHASED_BUSINESS_DISCOVERY, AffiliateEmailAddressType.ECOA_PURCHASED, AffiliateEmailAddressType.BUSINESS_TEXT_MESSAGING,
            AffiliateEmailAddressType.BUSINESS_ONE, AffiliateEmailAddressType.BUSINESS_TWO, AffiliateEmailAddressType.APPENDED_PRIMARY_PURCHASED,
            AffiliateEmailAddressType.APPENDED_SECONDARY_PURCHASED, AffiliateEmailAddressType.ALTERNATE_PURCHASED];
    }
    return [];
}

export function findPhoneNumberTypesFor(party: Party): AffiliatePhoneNumberType[] {
    if (Party.EMPLOYEE === party) {
        return [AffiliatePhoneNumberType.CELL_ONE, AffiliatePhoneNumberType.HOME_ONE, AffiliatePhoneNumberType.HOME_TWO, AffiliatePhoneNumberType.HOME_FAX, AffiliatePhoneNumberType.PURCHASED_HOME];
    } else if (Party.EMPLOYER === party) {
        return [AffiliatePhoneNumberType.CELL_TWO, AffiliatePhoneNumberType.MODEM, AffiliatePhoneNumberType.PAGER,
            AffiliatePhoneNumberType.WORK_ONE, AffiliatePhoneNumberType.WORK_TWO, AffiliatePhoneNumberType.WORK_FAX_ONE, AffiliatePhoneNumberType.WORK_FAX_TWO, AffiliatePhoneNumberType.OTHER];
    }
    return [];
}