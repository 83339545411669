import { BenefitType } from "../enums/BenefitType";
import { Relationship } from "../enums/Relationship";

export type DisabilityFormValues = {
    links: {
        employee: string
        employer: string
        physician: string
    },
}

export const defaultDisabilityFormValues = {
    benefit: BenefitType.DISABILITY,
    links: {
        employee: "",
        employer: "",
        physician: ""
    },
    relationship: Relationship.EMPLOYEE,
    medicalRelated: true
}