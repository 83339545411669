import { DisabilityProduct } from "./DisabilityProduct";
import { BenefitType } from "./BenefitType";

export enum Product {
    PAID_FAMILY_LEAVE = "paidFamily",
    PAID_MEDICAL_LEAVE = "paidMedical",
    SHORT_TERM_DISABILITY = "shortTerm",
    LONG_TERM_DISABILITY = "longTerm",
    HOSPITAL_INDEMNITY = "hospitalIndemnity",
    WELLNESS = "wellness",
    LIFE = "life"
}

export function findProductFor(benefitType: BenefitType, disabilityProduct: DisabilityProduct): Product | null {
    if(disabilityProduct === DisabilityProduct.SHORT_TERM_DISABILITY) {
        return Product.SHORT_TERM_DISABILITY;
    } else if(disabilityProduct === DisabilityProduct.LONG_TERM_DISABILITY) {
        return Product.LONG_TERM_DISABILITY;
    } else if(BenefitType.HOSPITAL_INDEMNITY === benefitType) {
        return Product.HOSPITAL_INDEMNITY;
    } else if(BenefitType.WELLNESS === benefitType) {
        return Product.WELLNESS;
    } else if(BenefitType.LIFE === benefitType) {
        return Product.LIFE;
    }
    return null;
}