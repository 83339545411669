import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import { FormikErrors } from "formik";
import { EmployeeFormValues } from "../../common/EmployeeFormValues";
import EmployeeAddress from "../../structure/EmployeeAddress";
import PhoneInput from "../../input/PhoneInput";
import Checkbox from "../../input/Checkbox";
import SelectDropdown from "../../input/SelectDropdown";
import { Language } from "../../enums/Language";
import { DisabilityEmployeeFormValues } from "../../disability/DisabilityEmployeeFormValues";
import EmailInput from "../../input/EmailInput";
import HorizontalRule from "../../structure/HorizontalRule";

export type ContactYouProps = {
    values?: EmployeeFormValues,
    errors?: FormikErrors<any>,
    setFieldValue?: Function,
    validateForm?: Function,
    submitForm?: Function,
    children?: React.ReactNode;
}

const ContactYou = (props: ContactYouProps) => {
    return (
        <QuestionPanel headerText="What is your contact information?"
                       detailsText="Your privacy is important to us. We'll only use your email for official communication regarding your claim."
                       panelContent={
                               <div>
                                   <EmailInput id="email-address" label="Email" required={true}/>
                                   <PhoneInput id="phone-number" label="Phone number" required={true}/>
                                   <Checkbox id="text-permission" label="If cell, permission to text?"/>
                                   <SelectDropdown id="preferred-language" label="Preferred language" required={true}
                                                   options={[{ id: "english", text: Language.ENGLISH },
                                                       {id: "spanish", text: Language.SPANISH},
                                                       {id: "other", text: Language.OTHER}]}
                                                    changeCallback={(target: HTMLSelectElement) => {
                                                        if(target.value !== Language.OTHER) {
                                                            props.setFieldValue?.('otherLanguage', '')
                                                        }
                                                    }}/>
                                   {(props.values as DisabilityEmployeeFormValues)?.preferredLanguage === Language.OTHER
                                       && <TextInput id="other-language" label="Other language" required={false} maxLength={50}/>}
                                   <HorizontalRule/>
                                   <EmployeeAddress {...props}/>
                               </div>
                           }
        >
        </QuestionPanel>
    )
};

export default ContactYou;