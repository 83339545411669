import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import MoneyInput from "../../input/MoneyInput";
import { EmployerFormValues } from '../../common/EmployerFormValues';
import { BenefitType, findAbbreviationFor } from '../../enums/BenefitType';
import RadioGroup from "../../structure/RadioGroup";

export type ConditionProps = {
    values?: EmployerFormValues
    setFieldValue?: Function
}

const Condition = (props: ConditionProps) => {
    function workRelatedChange(radio: HTMLInputElement) {
        if(radio.value !== 'Yes' && props.setFieldValue) {
            props.setFieldValue('workersCompensation', '')
            workersCompensationChange({ value: '' } as any)
        }
    }

    function workersCompensationChange(radio: HTMLInputElement) {
        if(radio.value !== 'Yes' && props.setFieldValue) {
            props.setFieldValue('workersCompensationAmount', '')
        }
    }

    function findMemberIdentifiers() {
        const values = props.values;
        return values?.privacyId
            ? `Principal member ID: ${values.privacyId}`
            : `${values?.firstName} ${values?.lastName} (date of birth: ${values?.dateOfBirth})`;
    }

    const workersCompensationSubject = encodeURIComponent(`${findAbbreviationFor(props.values?.benefit as BenefitType)} Workers' Compensation - ER - ${props.values?.claimId}`);
    const workersCompensationBody = encodeURIComponent(`Please see the attached workers' compensation letter for:\r\n${findMemberIdentifiers()}`);
    return (
        <QuestionPanel headerText="Is your employee's request for leave..."
                       detailsText="Depending on the reason for leave, we may need to check if workers' compensation applies."
                       panelContent={
                           <div>
                               <RadioGroup id="work-related"
                                           label="Due to a work-related illness or injury?"
                                           required={true}
                                           radioProps={[
                                               { id: "yes", text: "Yes", changeCallback: workRelatedChange },
                                               { id: "no", text: "No", changeCallback: workRelatedChange }
                                           ]}/>
                               { props.values?.workRelated === 'Yes' &&
                                   <RadioGroup id="workers-compensation"
                                               label="Has a workers' compensation claim been filed?"
                                               required={true}
                                               radioProps={[
                                                   { id: "yes", text: "Yes", changeCallback: workersCompensationChange },
                                                   { id: "no", text: "No", changeCallback: workersCompensationChange }
                                               ]}/>
                               }
                               { props.values?.workersCompensation === 'Yes' &&
                                   <>
                                       <MoneyInput id="workers-compensation-amount" label="Workers' compensation amount (per week)" required={true}/>
                                       { props.values?.benefit === BenefitType.DISABILITY &&
                                           <div id="workers-compensation-letter" className="pds-u-padding-bottom-16">
                                               If workers' compensation is approved or denied, please email a copy of the award/denial
                                               letter to <a href={`mailto:sbdclaims@principal.com?subject=${workersCompensationSubject}&body=${workersCompensationBody}.`}>sbdclaims@principal.com</a>.
                                           </div> }
                                   </>
                               }
                           </div>}
        >
        </QuestionPanel>
    )
};

export default Condition;