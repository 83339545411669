export enum EmployeePages {
    START = 'Start',
    YOUR_INFO = 'Your info',
    CONTACT_INFO = 'Contact info',
    EMPLOYER = 'Employer',
    RELATIONSHIP = 'Relationship',
    CLAIM_DATES = 'Claim dates',
    CLAIM_TYPE = 'Claim type',
    DOCUMENT = 'Document',
    BENEFITS = 'Benefits',
    PAYMENTS = 'Payments',
    SUMMARY = 'Summary',
    FINISH = 'Finish'
}