import Checkbox from "../../input/Checkbox";
import UnorderedList from "../../structure/UnorderedList";
import {DisabilityEmployeeFormValues} from "../../disability/DisabilityEmployeeFormValues";
import QuestionPanel from "../../structure/QuestionPanel";
import React from "react";
import {EmployeeFormValues} from "../../common/EmployeeFormValues";
import {BenefitType} from "../../enums/BenefitType";
import {OtherBenefitType} from "../../enums/OtherBenefitType";

type OtherBenefitsProps = {
    values?: EmployeeFormValues,
}

function OtherBenefits(props: OtherBenefitsProps) {
    const values = props.values;
    const benefit = values?.benefit as BenefitType;

    return (
        <QuestionPanel headerText="Are you receiving benefits from any of the following sources?"
                       panelContent={<>
                           <Checkbox id="other-benefits"
                                     label="Yes, I am receiving or have applied for at least one of the following:"/>
                           <div className="pds-u-padding-left-20">
                               <UnorderedList id="benefit-types"
                                              elements={findOtherBenefitTypesFor(benefit)}/>
                           </div>
                           <Checkbox id="other-disability-insurer"
                                     label="I have disability insurance from another insurance company"/>
                           <h5 id="click-next">If none, click Next.</h5>
                           {(values?.otherBenefits || (values as DisabilityEmployeeFormValues)?.otherDisabilityInsurer)
                           && <div id="other-benefits-communication" className="pds-u-padding-bottom-16">
                               After your claim submission, you will receive additional communication from
                               Principal asking for details regarding your additional income.
                               Please have the following information available: the type(s) of income, the
                               date the income(s) began, and the income amount(s).
                           </div>}
                       </>}
        >
        </QuestionPanel>
    );
}

function findOtherBenefitTypesFor(claimType: BenefitType) {
    if (BenefitType.PFML === claimType) {
        return [
            OtherBenefitType.SOCIAL_SECURITY_DISABILITY,
            OtherBenefitType.UNEMPLOYMENT,
            OtherBenefitType.WORKERS_COMPENSATION
        ];
    } else if (BenefitType.DISABILITY === claimType) {
        return [
            OtherBenefitType.PENSION,
            OtherBenefitType.SOCIAL_SECURITY_DISABILITY,
            OtherBenefitType.SOCIAL_SECURITY_EARLY_RETIREMENT,
            OtherBenefitType.SOCIAL_SECURITY_RETIREMENT,
            OtherBenefitType.SOCIAL_SECURITY_WIDOWS,
            OtherBenefitType.STATE_DISABILITY,
            OtherBenefitType.WORKERS_COMPENSATION
        ];
    }
    return [];
}

export default OtherBenefits;