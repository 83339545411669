import React from 'react';
import NumericInput from "./NumericInput";

type MoneyInputProps = {
    id: string,
    name?: string,
    label: string,
    required: boolean,
    children?: React.ReactNode
}

const MoneyInput = (props: MoneyInputProps) => {
    return <NumericInput id={props.id} name={props.name} label={props.label} required={props.required}
                         inputMask={{ regex: '^[0-9]+(.[0-9]{1,2})?$' }}
                         leftContent={<>$</>} />
};
export default MoneyInput;