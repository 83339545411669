import {defaultEmployeeFormValues, EmployeeFormValues} from "../common/EmployeeFormValues";
import {defaultWellnessFormValues, WellnessFormValues} from "./WellnessFormValues";
import _ from "lodash";
import { Language } from "../enums/Language";

export type WellnessEmployeeFormValues = Omit<EmployeeFormValues, 'workState' | 'workStateSameAsResidencyState' | 'otherBenefits' | 'documents'>  & WellnessFormValues & {
    textPermission: boolean,
    preferredLanguage: string,
    otherLanguage: string,
    claimDates: [{
        startDate: string
    }],
    premiumTaxation: string,
    employeeContributionPercentage: string,
    activelyAtWork: boolean,
    dateLastWorked: string,
    facilityName: string,
    facilityPhoneNumber: string,
}

export const defaultWellnessEmployeeFormValues = {
    ..._.omit(defaultEmployeeFormValues, ['workState', 'workStateSameAsResidencyState', 'otherBenefits', 'documents']),
    ...defaultWellnessFormValues,
    textPermission: false,
    preferredLanguage: Language.ENGLISH,
    otherLanguage: '',
    claimDates: [{
        startDate: ''
    }],
    premiumTaxation: '',
    employeeContributionPercentage: '',
    activelyAtWork: true,
    dateLastWorked: '',
    facilityName: '',
    facilityPhoneNumber: '',
}