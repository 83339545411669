import React from 'react';

const ContactSupport = () => {
    return (
        <div id="contact-support">
            <h4>Contact support</h4>
            <ul style={{listStyleType: "none", paddingLeft: 0}}>
                <li>Toll Free: 1 (800) 245-1522</li>
                <li>Open Mon. – Fri., 7:30 a.m. – 5 p.m. CST</li>
                <li>
                    <a href="https://www.principal.com/contact-us" target="_blank" rel="noopener noreferrer">
                        More ways to reach us
                    </a>
                </li>
            </ul>
        </div>
    )
};
export default ContactSupport;