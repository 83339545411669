import React from 'react';
import { FormValues } from "../common/FormValues";
// @ts-ignore
import Base64Downloader from 'common-base64-downloader-react';
import Button from "../button/Button";
import { ButtonClass } from "../enums/ButtonClass";

export type DownloadClaimProps = {
    values?: FormValues,
}

const DownloadClaim = (props: DownloadClaimProps) => {
    const summaryContent = props.values?.summaryContent;
    return (
        <>
            {summaryContent && <Base64Downloader base64={summaryContent.replace(/filename=.*.pdf;/gi, '')}
                                                 downloadName="claimSubmission"
                                                 Tag={Button}
                                                 extraAttributes={{ mainButtonClass: ButtonClass.PRIMARY, buttonContent: 'Download Claim Submission (.pdf)' }}/>}
        </>
    )
};
export default DownloadClaim;