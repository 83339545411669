import React from 'react';
import EConsentModal from "../../structure/EConsentModal";
import { BeneficiaryFormValues } from "../../common/BeneficiaryFormValues";
import QuestionPanel from "../../structure/QuestionPanel";
import { PdsIconAlertTriangle, PdsIconClock, PdsIconFile, PdsIconPhone } from "@principal/design-system-icons-react";
import IconWithContent from "../../structure/IconWithContent";
import HorizontalRule from "../../structure/HorizontalRule";

export type BeneficiaryStartProps = {
    values?: BeneficiaryFormValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

const BeneficiaryStart = (props: BeneficiaryStartProps) => {
    return (
        <QuestionPanel headerText="What to expect on the form for beneficiaries"
                       panelContent={<>
                           {<IconWithContent icon={PdsIconClock} content={<span>Allow 10-15 minutes to complete the form.</span>}/>}
                           <HorizontalRule/>
                           {<IconWithContent icon={PdsIconAlertTriangle} content={<span>Your work isn't saved, and we wouldn't want you to have to start over.</span>}/>}
                           <HorizontalRule/>
                           {<IconWithContent icon={PdsIconPhone} content={<span>
                               If you have any questions about your claim, please call (800) 245-1522 between the hours of 7:30 am and 5:00 pm CST.
                           </span>}/>}
                           <HorizontalRule/>
                           {<IconWithContent icon={PdsIconFile} content={
                               <div>
                                   <span style={{ fontWeight: 700 }}>What you'll need to complete this form</span>
                                   <ul>
                                       <li className="pds-u-padding-top-10">Demographic information of the deceased</li>
                                       <li className="pds-u-padding-top-10">Assignments and/or settlement instructions (if available)</li>
                                   </ul>
                               </div>}/>}
                           <EConsentModal {...props}/>
                       </>}
        >
        </QuestionPanel>
    )
};

export default BeneficiaryStart;