import React, { ReactElement } from 'react';
import { ButtonClass } from "../enums/ButtonClass";
import { PdsButton } from "@principal/design-system-react";

type ButtonProps = {
    buttonContent?: string | ReactElement,
    type?: 'submit' | 'reset' | 'button',
    mainButtonClass?: ButtonClass,
    buttonProps?: object,
    onClick?: (e: Event) => void,
    children?: React.ReactNode
}

const Button = (props: ButtonProps) => {
    return (
        <PdsButton type={props.type ? props.type : "button"}
                   variant={props.mainButtonClass ? props.mainButtonClass : ButtonClass.PRIMARY}
                   onClick={props.onClick}
                   {...props.buttonProps}>
            {props.buttonContent}
        </PdsButton>
    )
};
export default Button;