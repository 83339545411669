import React from 'react';
import { PdsButton, PdsTooltip } from "@principal/design-system-react";
import { PdsIconHelpCircle } from "@principal/design-system-icons-react";

type TooltipProps = {
    text: React.ReactNode,
    children?: React.ReactNode
}

const Tooltip = (props: TooltipProps) => {
    return (
        <PdsTooltip className="pds-u-padding-left-4" placement="right">
            <PdsButton slot="trigger" variant="icon" size="sm" ariaLabel="tooltip">
                <PdsIconHelpCircle/>
            </PdsButton>
            {props.text}
        </PdsTooltip>
    )
};

export default Tooltip;