export enum OtherBenefitChoices {
    NURSING_FACILITY = "Skilled nursing facility",
    REHABILITATION_FACILITY = "Rehabilitation facility",
    HOME_HEALTH_SERVICE = "Home health service",
    HOSPICE_CARE = "Hospice care",
    PRESCRIPTION_DRUG = "Prescription drug",
    MEDICAL_TRAVEL = "Medical travel",
    COMPANION_LODGING = "Companion lodging",
    FAMILY_CARE = "Family care",
    PET_CARE = "Pet care"
}