import React from 'react';
import { ListElementProps } from "./AddListElementButton";
import Button from "./Button";

const CloseButton = (props: ListElementProps) => {
    return (
        <div className="pds-u-flex" style={{justifyContent: "end"}}>
            <Button buttonProps={{ link: "default", size: "sm", removeLinkPadding: true }}
                    onClick={() => {
                        if(props.closeCallback) {
                            props.closeCallback(props.index)
                        }
                    }}
                    buttonContent={<span>&times;</span>}>
            </Button>
        </div>);
};
export default CloseButton;