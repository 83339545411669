import { BenefitType } from "../enums/BenefitType";

export type WellnessFormValues = {
    links: {
        employee: string
    }
}

export const defaultWellnessFormValues = {
    benefit: BenefitType.WELLNESS,
    links: {
        employee: ""
    }
}