import React from 'react';
import { PdsAlert } from "@principal/design-system-react";

export type AlertBannerProps = {
    message: string,
    children?: React.ReactNode
}

const AlertBanner = (props: AlertBannerProps) => {
    return (
        <PdsAlert variant="error" className="pds-u-margin-bottom-16">
            {props.message}
        </PdsAlert>
    )
};
export default AlertBanner;