import React from 'react';
import Panel from "../../structure/Panel";
import ContactSupport from "../../support/ContactSupport";
import { FormValues } from "../../common/FormValues";
import DownloadClaim from "../../support/DownloadClaim";

export type EmployerFinishedProps = {
    values?: FormValues,
}

const EmployerFinished = (props: EmployerFinishedProps) => {
    const claimNumber = props.values?.claimNumber;
    return (
        <Panel headerText="Thank you!"
               panelContent={
                   <div>
                       {claimNumber && <h4>Your claim number is {claimNumber}.</h4>}
                       <h4>Thank you for completing the employer portion of the claim. We'll contact you if additional information is needed.</h4>
                       <DownloadClaim values={props.values}/>
                       <ContactSupport/>
                   </div>}
        >
        </Panel>
    )
};
export default EmployerFinished;