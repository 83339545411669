import { defaultEmployeeFormValues, EmployeeFormValues } from "../common/EmployeeFormValues";
import { Language } from "../enums/Language";
import { defaultDisabilityFormValues, DisabilityFormValues } from "./DisabilityFormValues";

export type DisabilityEmployeeFormValues = EmployeeFormValues & DisabilityFormValues & {
    textPermission: boolean,
    preferredLanguage: string,
    otherLanguage: string,
    product: string,
    conditionDetails: string,
    expectedDelivery: boolean,
    deliveryDate: string,
    deliveryType: string,
    otherDisabilityInsurer: boolean,
    claimDates: [{
        startDate: string
    }],
    motorVehicleAccident: boolean
}

export const defaultDisabilityEmployeeFormValues = {
    ...defaultEmployeeFormValues,
    ...defaultDisabilityFormValues,
    textPermission: false,
    preferredLanguage: Language.ENGLISH,
    otherLanguage: '',
    product: '',
    conditionDetails: '',
    expectedDelivery: false,
    deliveryDate: "",
    deliveryType: "",
    otherDisabilityInsurer: false,
    claimDates: [{
        startDate: ''
    }],
    motorVehicleAccident: false
}