import axios from "axios";
import { Member } from "../../service/src/domain/Member"
import { DateTime } from "luxon";
import { Product } from "../component/enums/Product";
import { Party } from "../component/enums/Party";

export async function findMembersFor(policyNumber?: string, unitNumber?: string, token?: string): Promise<Member[]> {
    return findMemberOrMembersFor(true, policyNumber, unitNumber, token, Party.EMPLOYER) as Promise<Member[]>;
}

export async function findMemberFor(policyNumber?: string, privacyId?: string, token?: string, party?: Party, effectiveDate?: string, product?: Product): Promise<Member> {
    return findMemberOrMembersFor(false, policyNumber, privacyId, token, party, effectiveDate, product) as Promise<Member>;
}

async function findMemberOrMembersFor(arrayBased: boolean, policyNumber?: string, unitNumberOrPrivacyId?: string,
                                      token?: string, party?: Party, effectiveDate?: string, product?: Product): Promise<Member | Member[]> {
    if (!policyNumber || !unitNumberOrPrivacyId || !token) {
        return arrayBased ? [] as Member[] : {} as Member;
    }
    const productPath = product ? `/${product}` : '';
    const params = new URLSearchParams({
        effectiveDate: effectiveDate ? DateTime.fromFormat(effectiveDate, 'MM/dd/yyyy').toISODate() as string : "",
        party: party ? party : ""
    })
    return sendGetRequest(`${process.env.REACT_APP_FORMS_SERVICE_URL}/member/${policyNumber}/${unitNumberOrPrivacyId}${productPath}?${params.toString()}`, token)
        .then(r => {
            return arrayBased ? r.data as Member[] : r.data as Member;
        }).catch(e => {
            handleError(e);
            return arrayBased ? [] as Member[] : {} as Member;
        });
}

async function sendGetRequest(url: string, token: string) {
    return await axios.get(
        url,
        await getAuthorizationHeaders(token)
    )
}

async function getAuthorizationHeaders(token: string) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
}

function handleError(e: any) {
    if (e.response) {
        const errorData = JSON.stringify(e.response.data);
        if(!errorData?.includes('not found')) {
            console.log(e.toString().substring(7) + ': ' + errorData)
        }
    } else {
        console.log(e)
    }
}
