import { FormValues, defaultFormValues } from "./FormValues";

export type EmployerFormValues = FormValues & {
    privacyId: string,
    socialSecurityNumber: string,
    dateOfBirth: string,
    jobTitle: string,
    phoneNumber: string,
    workState: string,
    workStateSameAsResidencyState: boolean,
    streetAddress: string,
    city: string,
    state: string,
    zipCode: string,
    workSchedule: string,
    weeklyWorkSchedule: [{
        sunday: string,
        monday: string,
        tuesday: string,
        wednesday: string,
        thursday: string,
        friday: string,
        saturday: string,
    }],
    alternativeScheduleFrequency: string,
    averageHoursWorkedPerWeek: string,
    averageDaysWorkedPerWeek: string,
    workRelated: string,
    workersCompensation: string,
    workersCompensationAmount: string,
    dateOfHire: string,
    dateLastWorked: string,
    dateReturnedToWork: string,
    baseWage: string,
    baseWageFrequency: string,
    baseWageEffectiveDate: string,
    payAfterDateLastWorked: string,
    fullPayAfterDateLastWorked: string,
    payAfterDateLastWorkedAmount: string,
    policyNumber: string,
    unitNumber: string,
    employerName: string,
    employerContactName: string,
    employerPhoneNumber: string,
    employerEmailAddress: string,
    employerTitle: string,
    fraud: boolean,
    fraudContinue: boolean
}

export const defaultEmployerFormValues = {
    ...defaultFormValues,
    jobTitle: '',
    privacyId: '',
    socialSecurityNumber: '',
    dateOfBirth: '',
    phoneNumber: '',
    workState: '',
    workStateSameAsResidencyState: true,
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    workSchedule: '',
    weeklyWorkSchedule: [{
        sunday: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
    }, {
        sunday: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
    }],
    alternativeScheduleFrequency: '',
    averageHoursWorkedPerWeek: '',
    averageDaysWorkedPerWeek: '',
    workRelated: '',
    workersCompensation: '',
    workersCompensationAmount: '',
    dateOfHire: '',
    dateLastWorked: '',
    dateReturnedToWork: '',
    baseWage: '',
    baseWageFrequency: '',
    baseWageEffectiveDate: '',
    payAfterDateLastWorked: '',
    fullPayAfterDateLastWorked: '',
    payAfterDateLastWorkedAmount: '',
    policyNumber: '',
    unitNumber: '',
    employerName: '',
    employerContactName: '',
    employerTitle: '',
    employerPhoneNumber: '',
    employerEmailAddress: '',
    fraud: false,
    fraudContinue: false
}
