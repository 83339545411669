import React from 'react';
import TwoColumnDiv from "../structure/TwoColumnDiv";
import { buildArrayIdFor, buildArrayNameFor, ListElementProps } from "../button/AddListElementButton";
import CloseButton from "../button/CloseButton";
import TextInput from "./TextInput";
import DateInput from "./DateInput";

const ProcedureSelection = (props: ListElementProps) => {
    return (
        <div>
            {props.closeCallback && <CloseButton idPrefix="" index={props.index} closeCallback={props.closeCallback}/>}
            {buildProcedureQuestions()}
        </div>
    )

    function buildProcedureQuestions() {
        return <TwoColumnDiv leftContent={<TextInput id={buildArrayIdFor("procedure-code", props)}
                                                     name={buildArrayNameFor("procedureCode", props)}
                                                     label="Procedure code"
                                                     required={true}/>}
                             rightContent={<DateInput id={buildArrayIdFor("date-of-procedure", props)}
                                                      name={buildArrayNameFor("dateOfProcedure", props)}
                                                      label="Procedure date"
                                                      required={true}/>}/>;
    }
};

export default ProcedureSelection;