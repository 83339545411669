import React from 'react';
import Button from "./Button";
import { ButtonClass } from "../enums/ButtonClass";
import { PdsIconChevronLeft } from "@principal/design-system-icons-react";

type PreviousButtonProps = {
    onClick?: (e: Event) => void,
    children?: React.ReactNode
}

const PreviousButton = (props: PreviousButtonProps) => {
    return (
        <Button mainButtonClass={ButtonClass.DEFAULT}
                buttonContent={<><PdsIconChevronLeft slot="icon-left"/><span>Previous</span></>}
                onClick={props.onClick}/>
    )
};
export default PreviousButton;