import React, { ReactElement } from 'react';
import TextInput from "./TextInput";

type SsnInputProps = {
    id: string,
    label: string,
    required: boolean,
    labelContent?: ReactElement,
    children?: React.ReactNode
}

const SsnInput = (props: SsnInputProps) => {
    return (
        <TextInput id={props.id} label={props.label} required={props.required}
                   inputMask={{ mask: '999-99-9999' }}
                   labelContent={props.labelContent}
                   maxLength={11}
                   validate={validateSocialSecurityNumber}/>
    )

    function validateSocialSecurityNumber(value: string) {
        return value?.match(/^\d{3}-?\d{2}-?\d{4}$/gi) ? '' : 'Social Security number must be in the format XXX-XX-XXXX.';
    }
};
export default SsnInput;