import React from 'react';
import { buildArrayIdFor, buildArrayNameFor, ListElementProps } from "../button/AddListElementButton";
import FileInput from "./FileInput";
import SelectDropdown from "./SelectDropdown";
import CloseButton from "../button/CloseButton";
import { hasRequiredDocumentsFor } from "../enums/BenefitType";

const DocumentUpload = (props: ListElementProps) => {
    const dropdownOptions = props.otherProps?.map((documentType: DocumentType) => {
        return { id: String(documentType).replace(/\W/g, '-').toLowerCase(), text: documentType }
    })

    return <>
        { props.closeCallback && <CloseButton idPrefix="" index={props.index} closeCallback={props.closeCallback}/> }
        <FileInput id={buildArrayIdFor("document", props)} name={buildArrayNameFor("document", props)} label="File upload" required={hasRequiredDocumentsFor(props.values?.benefit)}
                   supportedExtensions={[".pdf"]} values={props.values} setFieldValue={props.setFieldValue} setFieldError={props.setFieldError}/>
        <SelectDropdown id={buildArrayIdFor("document-type", props)} name={buildArrayNameFor("documentType", props)} label="Document type" required={false}
                        options={dropdownOptions}/>
    </>
}

export default DocumentUpload;