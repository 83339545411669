import React from 'react';
import { EmployerFormValues } from "../../common/EmployerFormValues";
import { BenefitType, findTitleFor } from "../../enums/BenefitType";
import Panel from "../../structure/Panel";

export type UseLinkErrorProps = {
    values?: EmployerFormValues
}

const UseLinkError = (props: UseLinkErrorProps) => {
    const benefitTitle = findTitleFor(props.values?.benefit as BenefitType);
    return (
        <Panel headerText={`It looks like you're trying to submit the employer portion of a ${benefitTitle} claim.`}
               panelContent={
                   <div>
                       Please use the link your employee provided to submit the employer portion of this claim. If you didn't receive the link, please reach out to your employee.
                   </div>}
        />
    )
};

export default UseLinkError;
