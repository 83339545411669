import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import DateInput from "../../input/DateInput";
import SelectDropdown from "../../input/SelectDropdown";
import {FormikValues} from "formik";
import Callout from '../../structure/Callout';
import {BeneficiaryRelationshipType} from "../../enums/BeneficiaryRelationshipType";
import {findPossessiveFor, PartOfSpeech} from "../../common/PersonalLanguage";
import {FormValues} from "../../common/FormValues";
import RadioGroup from "../../structure/RadioGroup";
import { BeneficiaryMakingClaimTo } from "../../enums/BeneficiaryMakingClaimTo";

export type BeneficiaryRelationshipProps = {
    initialValues?: FormikValues,
    values?: FormikValues,
    setFieldValue?: Function
}

const BeneficiaryRelationship = (props: BeneficiaryRelationshipProps) => {
    const values = props.values;
    const setFieldValue = props.setFieldValue;
    const nounPossessiveWithArticle = findPossessiveFor(values as FormValues, true, true, PartOfSpeech.NOUN);

    return (
        <QuestionPanel headerText="Who is the deceased?"
                       panelContent={
                           <div>
                               <RadioGroup id="deceased-is-employee"
                                           label="Was the deceased the employee who had Principal life insurance?"
                                           required={true}
                                           radioProps={[
                                               { id: "yes", text: "Yes", changeCallback: () => {
                                                   if(setFieldValue) {
                                                       setFieldValue('makingClaimTo', '');
                                                   }
                                               }},
                                               { id: "no", text: "No", changeCallback: () => {
                                                   if(setFieldValue) {
                                                       setFieldValue('makingClaimTo', BeneficiaryMakingClaimTo.ALL_PROCEEDS);
                                                   }
                                               }}
                                           ]}/>
                               <SelectDropdown id="deceased-relationship" label={`What is ${nounPossessiveWithArticle} relationship to the deceased?`} required={true}
                                               options={Object.keys(BeneficiaryRelationshipType).map(b => ({
                                                   id: b.replace(/_/g, '-').toLowerCase(),
                                                   text: (BeneficiaryRelationshipType as any)[b]
                                               }))}
                                               changeCallback={(target: HTMLSelectElement) => {
                                                   if(!setFieldValue) {
                                                       return;
                                                   }
                                                   if(target.value !== BeneficiaryRelationshipType.OTHER) {
                                                       setFieldValue('deceasedOtherRelationship', '');
                                                   }
                                               }}/>
                               {values?.deceasedRelationship === BeneficiaryRelationshipType.OTHER && <TextInput id="deceased-other-relationship" label="Other relationship" required={true} maxLength={1024}/>}
                               <Callout>
                                   <TextInput id="deceased-first-name" label="Deceased first name" required={true} maxLength={1024}/>
                                   <TextInput id="deceased-last-name" label="Deceased last name" required={true} maxLength={1024}/>
                                   <DateInput id="deceased-date-of-birth" label="Deceased date of birth" required={true}/>
                               </Callout>
                           </div>
                       }
        >
        </QuestionPanel>
    )
};
export default BeneficiaryRelationship;