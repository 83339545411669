import { BenefitType } from "../enums/BenefitType";

export type PfmlFormValues = {
    links: {
        employee: string
        employer: string
        physician: string
    }
}

export const defaultPfmlFormValues = {
    benefit: BenefitType.PFML,
    links: {
        employee: "",
        employer: "",
        physician: ""
    }
}