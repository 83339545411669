import React from 'react';
import TwoColumnDiv from "../structure/TwoColumnDiv";
import DateInput from "./DateInput";
import { ListElementProps } from "../button/AddListElementButton";
import CloseButton from "../button/CloseButton";
var _ = require('lodash');

export type DateRangeProps = {
    startLabel?: string
    endLabel?: string
    startRequired?: boolean
    endRequired?: boolean
} & ListElementProps;

const DateRange = (props: DateRangeProps) => {
    const index = props.index;
    const name = _.camelCase(props.idPrefix);
    const namePrefixStart = index !== undefined ? `${name}[${index}].startDate` : `${name}StartDate`
    const namePrefixEnd = index !== undefined ? `${name}[${index}].endDate` : `${name}EndDate`
    const indexSuffix = index !== undefined ? `-${index}` : ''
    const startRequired = props.startRequired;
    const endRequired = props.endRequired;
    return (
        <div>
            { props.closeCallback && <CloseButton idPrefix="" index={index} closeCallback={props.closeCallback}/> }
            <TwoColumnDiv
                leftContent={
                    <div>
                        <DateInput id={props.idPrefix + "-start-date" + indexSuffix} name={namePrefixStart}
                                   label={props.startLabel ?? "Start date"} required={startRequired !== undefined ? startRequired : true}/>
                    </div>
                }
                rightContent={
                    <div>
                        <DateInput id={props.idPrefix + "-end-date" + indexSuffix} name={namePrefixEnd}
                                   label={props.endLabel ?? "End date"} required={endRequired !== undefined ? endRequired : false}/>
                    </div>
                }
            />
        </div>
    )
};

export default DateRange;