import React from 'react';
import {EmployeeFormValues} from "../../common/EmployeeFormValues";
import ClaimType from "../common/ClaimType";

export type EmployeeClaimTypeProps = {
    values?: EmployeeFormValues,
    setFieldValue?: Function
}

const EmployeeClaimType = (props: EmployeeClaimTypeProps) => {
    return (
        <ClaimType {...props}
                   headerText="What type of claim are you filing for?"
                   detailsText="We ask this because certain types of claims require different information/documentation."
                   showAdditionalInformation={true}
                   children={<></>}
        />
    )
};
export default EmployeeClaimType;