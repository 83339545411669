import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import DateInput from "../../input/DateInput";
import SsnInput from "../../input/SsnInput";
import { PfmlEmployeeFormValues } from "../../pfml/PfmlEmployeeFormValues";
import { DisabilityEmployeeFormValues } from "../../disability/DisabilityEmployeeFormValues";
import { HospitalIndemnityEmployeeFormValues } from "../../indemnity/HospitalIndemnityEmployeeFormValues";

export type AboutYouProps = {
    initialValues?: PfmlEmployeeFormValues | DisabilityEmployeeFormValues | HospitalIndemnityEmployeeFormValues
}

const AboutYou = (props: AboutYouProps) => {
    return (
        <QuestionPanel headerText="Let's gather some information about you."
                       panelContent={
                               <div>
                                   <TextInput id="first-name" label="First name" required={true} maxLength={1024}/>
                                   <TextInput id="middle-name" label="Middle name" required={false} maxLength={1024}/>
                                   <TextInput id="last-name" label="Last name" required={true} maxLength={1024}/>
                                   <DateInput id="date-of-birth" label="Date of birth" required={true}/>
                                   {!(props.initialValues as DisabilityEmployeeFormValues)?.socialSecurityNumber
                                       && <SsnInput id="social-security-number" label="Social Security number" required={true}/>}
                               </div>}
        >
        </QuestionPanel>
    )
};
export default AboutYou;