import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { isFormikAvailable } from "./FormikStepper";
import { webAnalytics } from "@principal/web-analytics";

var _ = require('lodash');

export function addPageView(label: string, initialRender?: React.MutableRefObject<boolean>) {
    const hash = window.location.hash.replace('#/', '');
    const camelCaseLabel = label ? `/${_.camelCase(label)}` : '';
    const pageView = {
        content: {
            businessline: 'benefits-and-protection',
            intendedaudience: 'individual',
            purpose: 'retention',
        },
        page: {
            author: '',
            compliancedate: '',
            contractid: '',
            documenttype: 'single-page-application',
            language: 'english',
            memberid: '',
            publishdate: '',
        }
    } as any;

    if(initialRender?.current) {
        webAnalytics.trackEvent('pageViewLoaded', pageView);
        initialRender.current = false;
    } else {
        webAnalytics.trackEvent('virtualPageChange', {
            virtualpagedetails: {
                pagepath: `/${hash}${camelCaseLabel}`,
                pagetitle: document.title,
            },
            ...pageView,
        })
    }
}

const PageViewMetric = () => {
    let location = useLocation()
    const initialRender = useRef(true)

    useEffect(
        () => {
            if(!isFormikAvailable()) {
                addPageView('', initialRender)
            }
        },
        [location]
    )

    return (<></>)
};
export default PageViewMetric;