import Modal from "./Modal";
import React, { useEffect } from "react";
import Checkbox from "../input/Checkbox";
import { ModalSize } from "../enums/ModalSize";
import CenteredFullWidthDiv from "./CenteredFullWidthDiv";
import Button from "../button/Button";
import { FormikValues } from "formik";
import { findFraudFor } from "../../service/FraudService";
import { Fraud } from "../../../service/src/domain/Fraud";
import LoadingSpinner from "./LoadingSpinner";

export type FraudModalProps = {
    initialValues?: FormikValues,
    values?: FormikValues,
    setFieldValue?: Function,
    children?: React.ReactNode
}

const FraudModal = (props: FraudModalProps) => {
    const [fraud, setFraud] = React.useState(null as any as Fraud[])

    useEffect(() => {
        async function findFraud() {
            setFraud(await findFraudFor())
        }
        findFraud()
    }, [])

    const consent = props.values?.fraud;
    if(props.initialValues?.fraud === undefined) {
        return null;
    } else if(!fraud) {
        return <LoadingSpinner spinnerText="Loading"/>
    } else if(consent && props.values?.fraudContinue) {
        return buildFraudAboveSignatureStates(fraud)
    }
    return <><Modal id="fraud-validation"
                    headerText="Notice requirements"
                    modalContent={
                        <div id="fraud-validation-content">
                            <ul>
                                {fraud.filter(f => !f.aboveSignature).map(f =>
                                    <li style={findStateStyleFor(f)}>
                                        {findFraudSummaryFor(f)}
                                    </li>)}
                            </ul>
                            <p>
                                Agreement - By clicking the checkbox below, you understand that you read this information and agree to the
                                statement above.
                            </p>
                            {fraud && <CenteredFullWidthDiv divContent={<Checkbox id="fraud" label="I agree"/>}/>}
                        </div>}
                    modalFooter={
                        <>
                            {consent && <Button buttonContent="Continue" onClick={() => {
                                if (props.setFieldValue) {
                                    props.setFieldValue('fraudContinue', true);
                                }
                            }}/>}
                        </>}
                    modalSize={ModalSize.LARGE}
                    closeable={false}/>
        {buildFraudAboveSignatureStates(fraud)}
    </>;
}

function findStateStyleFor(f: Fraud) {
    return { fontWeight: f.bold ? 'bold' : 'normal', fontSize: `${f.fontSize}pt` };
}

function findFraudSummaryFor(f: Fraud) {
    return `${f.state}: ${f.text}`;
}

function buildFraudAboveSignatureStates(fraud: Fraud[]) {
    return <div id="fraud-above-signature">
        {fraud.filter(f => f.aboveSignature).map(f =>
            <div style={findStateStyleFor(f)} className="pds-u-padding-bottom-20">
                {findFraudSummaryFor(f)}
            </div>)}
    </div>;
}

export default FraudModal;
