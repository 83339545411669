import React from 'react';
import TwoColumnDiv from "../structure/TwoColumnDiv";
import { buildArrayIdFor, buildArrayNameFor, ListElementProps } from "../button/AddListElementButton";
import CloseButton from "../button/CloseButton";
import TextInput from "./TextInput";
import SelectDropdown from "./SelectDropdown";
import PhoneInput from "./PhoneInput";
import { Direction } from "../enums/Direction";

const ReferralSelection = (props: ListElementProps) => {
    return (
        <div>
            {props.closeCallback && <CloseButton idPrefix="" index={props.index} closeCallback={props.closeCallback}/>}
            {buildReferralQuestions()}
        </div>
    )

    function buildReferralQuestions() {
        return <>
                <SelectDropdown id={buildArrayIdFor("direction", props)}
                                name={buildArrayNameFor("direction", props)}
                                label="Referred"
                                required={true}
                                options={Object.keys(Direction).map(p => ({
                                    id: p.replace(/_/g, '-').toLowerCase(),
                                    text: (Direction as any)[p]
                                }))}/>
                <TwoColumnDiv leftContent={<TextInput id={buildArrayIdFor("name", props)}
                                                      name={buildArrayNameFor("name", props)}
                                                      label="Name of referred physician"
                                                      required={true}/>}
                              rightContent={<PhoneInput id={buildArrayIdFor("phone-number", props)}
                                                        name={buildArrayNameFor("phoneNumber", props)}
                                                        label="Phone number of referred physician"
                                                        required={true}/>}/>
                </>;
    }
};

export default ReferralSelection;