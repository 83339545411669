import React from 'react';
import { PdsIconCheck } from "@principal/design-system-icons-react";
import IconWithContent from "../structure/IconWithContent";

type NextStepsProps = {
    steps: string[]
}

const NextSteps = (props: NextStepsProps) => {
    const steps = ['You\'ll have the opportunity to print a copy of your submission after you submit the claim.', ...props.steps];

    return (<div>
        <h5>Next steps</h5>
        {steps.map(s => (
            <IconWithContent icon={PdsIconCheck} content={<span>{s}</span>}/>
        ))}
    </div>)
}

export default NextSteps;