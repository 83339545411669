import React, { useEffect } from "react";
import { gatherInitialValues, submitHandler } from "../../service/FormService";
import { Party } from "../enums/Party";
import { FormikValues } from "formik";
import { FormikStepper } from "../navigation/FormikStepper";
import { FormikStep } from "../navigation/FormikStep";
import { BenefitType, findRelationshipsFor } from "../enums/BenefitType";
import { defaultLifeBeneficiaryFormValues } from "../life/LifeBeneficiaryFormValues";
import BeneficiaryStart from "../page/beneficiary/BeneficiaryStart";
import AboutBeneficiary from "../page/beneficiary/AboutBeneficiary";
import { BeneficiaryPages } from "../enums/BeneficiaryPages";
import BeneficiaryFinished from "../page/beneficiary/BeneficiaryFinished";
import BeneficiarySummary from "../page/beneficiary/BeneficiarySummary";
import ContactBeneficiary from "../page/beneficiary/ContactBeneficiary";
import {
    addressValidator,
    validateDocumentContent,
    validateDocumentsTotalSize
} from "../../form/Validators";
import BeneficiaryRelationship from "../page/beneficiary/BeneficiaryRelationship";
import BeneficiaryClaimType from "../page/beneficiary/BeneficiaryClaimType";
import BeneficiaryFinancial from "../page/beneficiary/BeneficiaryFinancial";
import { object } from "yup";
import BeneficiaryDocument from "../page/beneficiary/BeneficiaryDocument";
import { useParams } from "react-router-dom";

const Beneficiary = () => {
    const [formInitialValues, setFormInitialValues] = React.useState({});

    const params = useParams();
    const coverage = params.coverage as BenefitType;
    const claimId = params.claimId;

    useEffect(() => {
        async function findInitialValues() {
            const formValues = await gatherInitialValues(claimId, coverage, Party.BENEFICIARY);
            setFormInitialValues(formValues)
        }
        findInitialValues();
    }, [coverage, claimId])

    const initialValues = {
        ...findBeneficiaryValuesFor(coverage),
        ...formInitialValues,
    } as FormikValues;

    return <FormikStepper initialValues={initialValues}
                          onSubmit={submitHandler}>
        <FormikStep label={BeneficiaryPages.START}>
            <BeneficiaryStart/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.BENEFICIARY_INFO} hideStep={() => initialValues.beneficiaryFirstName === undefined}>
            <AboutBeneficiary/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.CONTACT_INFO}
                    hideStep={() => initialValues.beneficiaryPhoneNumber === undefined}
                    validate={(values) => addressValidator(values, "beneficiaryStreetAddress", values.beneficiaryStreetAddress, values.beneficiaryCity, values.beneficiaryState, values.beneficiaryZipCode)}>
            <ContactBeneficiary/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.RELATIONSHIP} hideStep={() => findRelationshipsFor(initialValues.benefit).length < 2}>
            <BeneficiaryRelationship/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.CLAIM_TYPE} hideStep={() => initialValues.claimType === undefined}>
            <BeneficiaryClaimType/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.FINANCIAL}
                    hideStep={() => initialValues.taxIdentificationNumber === undefined}>
            <BeneficiaryFinancial/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.DOCUMENT}
                    hideStep={() => initialValues.documents === undefined}
                    validationSchema={object({
                        documents: validateDocumentContent(),
                    })} validate={(values) => validateDocumentsTotalSize(values)}>
            <BeneficiaryDocument/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.SUMMARY}>
            <BeneficiarySummary/>
        </FormikStep>
        <FormikStep label={BeneficiaryPages.FINISH}>
            <BeneficiaryFinished/>
        </FormikStep>
    </FormikStepper>;
}

function findBeneficiaryValuesFor(claimType: BenefitType) {
    if(BenefitType.LIFE === claimType) {
        return defaultLifeBeneficiaryFormValues;
    }
    return {};
}

export default Beneficiary;