export enum DocumentType {
    BIRTH_CERTIFICATE = 'Birth certificate',
    PHYSICIAN_STATEMENT = 'Physician statement',
    ADOPTION_FOSTER_PLACEMENT = 'Adoption/foster placement',
    ACTIVE_DUTY_ORDERS = 'Active duty orders',
    LETTER_OF_IMPENDING_ACTIVATION = 'Letter of impending activation',
    COPY_OF_CIVIL_COURT_APPOINTMENTS_OR_SERVICES = 'Copy of civil/court appointments or services',
    BILLS = 'Bills',
    RECEIPTS = 'Itemized receipts of services',
    EXPLANATION_OF_BENEFITS = 'Medical carrier\'s explanation of benefits',
    EMPLOYEE_STATEMENT = 'Employee statement',
    EARNINGS = 'Earnings',
    MEDICAL_RECORDS = 'Medical records',
    BENEFICIARY_DESIGNATION = 'Beneficiary designation',
    ENROLLMENT_FORM = 'Enrollment form',
    DEATH_CERTIFICATE = 'Death certificate',
    INCIDENT = 'Incident report',
    AUTOPSY = 'Autopsy/Toxicology report',
    NEWSPAPER = 'Newspaper articles',
    POLICE_DEPARTMENT_INFO = 'Investigating police department or officers contact name and phone number',
    TRANSPORTATION_BILL = 'Transportation bill',
    HIPAA_AUTH = 'HIPAA Auth',
    ADMISSION = 'Admission/discharge paperwork',
    UNIFORM_BILL = 'Uniform bill (UB-04)',
    OTHER = 'Other'
}