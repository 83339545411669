import React, { ReactNode } from 'react';
import { PfmlEmployeeFormValues } from "../../pfml/PfmlEmployeeFormValues";
import { TypeOfClaim } from "../../enums/TypeOfClaim";
import { DocumentType } from "../../enums/DocumentType";
import { MilitaryLeaveReason } from "../../enums/MilitaryLeaveReason";
import TextInput from "../../input/TextInput";
import PhoneInput from "../../input/PhoneInput";
import { HospitalIndemnityEmployeeFormValues } from "../../indemnity/HospitalIndemnityEmployeeFormValues";
import { BenefitType, findPartyChoicesFor } from "../../enums/BenefitType";
import { Party } from "../../enums/Party";
import Document from "../common/Document";
import { ChildRelationship } from "../../enums/ChildRelationship";
import HorizontalRule from "../../structure/HorizontalRule";

export type EmployeeDocumentProps = {
    values?: PfmlEmployeeFormValues | HospitalIndemnityEmployeeFormValues,
    setFieldValue?: Function,
    setFieldError?: Function,
}

const EmployeeDocument = (props: EmployeeDocumentProps) => {
    const claimType = props.values?.claimType;
    const benefit = props.values?.benefit as BenefitType;
    const biological = isBiological((props.values as PfmlEmployeeFormValues)?.childRelationship);
    const coveredServiceMember = (props.values as PfmlEmployeeFormValues)?.militaryLeaveReason === MilitaryLeaveReason.CARE_FOR_A_COVERED_SERVICE_MEMBER;
    const medicalRelated = props.values?.medicalRelated;
    const hospitalIndemnity = BenefitType.HOSPITAL_INDEMNITY === props.values?.benefit;

    function findDefaultDocumentType() {
        if (hospitalIndemnity) {
            return DocumentType.BILLS;
        } else if ([TypeOfClaim.SERIOUS_HEALTH_CONDITION, TypeOfClaim.MATERNITY, TypeOfClaim.PHYSICAL_ILLNESS, TypeOfClaim.MENTAL_HEALTH,
            TypeOfClaim.INJURY, TypeOfClaim.PREGNANCY].includes(claimType as TypeOfClaim)) {
            return DocumentType.PHYSICIAN_STATEMENT;
        } else if (TypeOfClaim.MILITARY === claimType) {
            return coveredServiceMember ? DocumentType.PHYSICIAN_STATEMENT : DocumentType.ACTIVE_DUTY_ORDERS;
        } else if (TypeOfClaim.CHILD_BONDING === claimType) {
            return biological ? DocumentType.BIRTH_CERTIFICATE : DocumentType.ADOPTION_FOSTER_PLACEMENT;
        } else if (TypeOfClaim.PERSONAL === claimType) {
            return medicalRelated ? DocumentType.PHYSICIAN_STATEMENT : DocumentType.COPY_OF_CIVIL_COURT_APPOINTMENTS_OR_SERVICES;
        }
        return null;
    }

    let document = '';
    let documentDescription = '' as ReactNode;
    let documentTypeOptions = [findDefaultDocumentType()] as DocumentType[];
    const supportingDocsNotice = ' If you don\'t have any supporting documentation right now, we\'ll ask you for it later via email.';
    if (hospitalIndemnity) {
        const isInjury = props.values?.claimType === TypeOfClaim.INJURY;
        const claimsSpecificDocuments = isInjury ? <>an accident report if applicable (ex: police report)</> : <>admission/discharge paperwork</>;
        const claimsSpecificDocumentType = isInjury ? DocumentType.INCIDENT : DocumentType.ADMISSION
        document = 'medical and other documentation'
        documentDescription = <>In order to review your claim for benefits, you will need to submit copies of bills, itemized receipts of services, UB-04, medical carrier's explanation of benefits, or {claimsSpecificDocuments}. <strong>This information is required.</strong></>;
        documentTypeOptions.push(DocumentType.RECEIPTS, DocumentType.UNIFORM_BILL, DocumentType.EXPLANATION_OF_BENEFITS, claimsSpecificDocumentType);
    } else if (claimType === TypeOfClaim.SERIOUS_HEALTH_CONDITION
        || claimType === TypeOfClaim.MATERNITY
        || coveredServiceMember
        || medicalRelated) {
        document = 'a physician statement'
        documentDescription = "If you want to direct your physician to an online form after you file, just click Next. We'll send you a link that you can send to them. If you would also like us to reach out to the physician for their statement, please enter your physician details."
    } else if (claimType === TypeOfClaim.CHILD_BONDING) {
        document = biological ? 'a birth document' : 'a adoption/foster document'
        documentDescription = (biological ? 'This can be a birth certificate, or a statement from the health care provider with the child\'s birthdate.'
            : 'Please upload your child\'s adoption/foster placement paperwork.') + supportingDocsNotice;
        if(biological) {
            documentTypeOptions.push(DocumentType.PHYSICIAN_STATEMENT);
        }
    } else if (claimType === TypeOfClaim.MILITARY) {
        document = 'a military order'
        documentDescription = 'This document can be a military order showing active duty or impending activation.' + supportingDocsNotice;
        documentTypeOptions.push(DocumentType.LETTER_OF_IMPENDING_ACTIVATION)
    } else if (claimType === TypeOfClaim.PERSONAL && !medicalRelated) {
        document = 'a copy of civil/court appointments or services'
        documentDescription = 'This document can be a court order or other documents related to civil/criminal proceedings that involve family violence.'
            + supportingDocsNotice;
    }

    return (
        <Document {...props}
                  documentHeader={document}
                  documentDescription={documentDescription}
                  documentTypeOptions={documentTypeOptions}
                  panelContent={findPartyChoicesFor(benefit).includes(Party.PHYSICIAN) && medicalRelated && <>
                      <HorizontalRule/>
                      <TextInput id="physician-first-name" label="Physician's first name" required={false} maxLength={1024}/>
                      <TextInput id="physician-last-name" label="Physician's last name" required={false} maxLength={1024}/>
                      <PhoneInput id="physician-phone-number" label="Physician's phone number" required={false}/>
                  </>}/>
    )
};

function isBiological(childRelationship: string | undefined) {
    const biologicalRelationships = [ChildRelationship.BIOLOGICAL_CHILD, ChildRelationship.STEP_CHILD, ChildRelationship.SPOUSE_DOMESTIC_PARTNERS_CHILD] as string[];
    return childRelationship && biologicalRelationships.includes(childRelationship);
}

export default EmployeeDocument;