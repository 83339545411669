import { defaultEmployerFormValues, EmployerFormValues } from "../common/EmployerFormValues";
import { defaultHospitalIndemnityFormValues, HospitalIndemnityFormValues } from "./HospitalIndemnityFormValues";
import _ from "lodash";

export type HospitalIndemnityEmployerFormValues = Omit<EmployerFormValues, 'phoneNumber' | 'workState' | 'workStateSameAsResidencyState' | 'streetAddress' | 'city' | 'state' | 'zipCode'
    | 'jobTitle' | 'workSchedule' | 'dateReturnedToWork' | 'claimType' | 'workRelated' | 'baseWage' | 'baseWageFrequency' | 'baseWageEffectiveDate' | 'payAfterDateLastWorked'> & HospitalIndemnityFormValues
    & {
    hoursWorkedPerWeek: string,
    employeeContributionPercentage: string,
    premiumTaxation: string
}

export const defaultHospitalIndemnityEmployerFormValues = {
    ..._.omit(defaultEmployerFormValues, ['phoneNumber', 'workState', 'workStateSameAsResidencyState', 'streetAddress', 'city', 'state', 'zipCode',
        'jobTitle', 'workSchedule', 'dateReturnedToWork', 'claimType', 'workRelated', 'baseWage', 'baseWageFrequency', 'baseWageEffectiveDate', 'payAfterDateLastWorked']),
    ...defaultHospitalIndemnityFormValues,
    hoursWorkedPerWeek: '',
    employeeContributionPercentage: '',
    premiumTaxation: ''
}