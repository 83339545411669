import { Party } from "../enums/Party";
import { FormikStepper } from "../navigation/FormikStepper";
import { FormikStep } from "../navigation/FormikStep";
import { PhysicianPages } from "../enums/PhysicianPages";
import PhysicianStart from "../page/physician/PhysicianStart";
import { object } from "yup";
import {
    addressValidator,
    dateRangeArrayValidator,
    dateRangeValidator, validateDocumentContent, validateDocumentsTotalSize
} from "../../form/Validators";
import AboutPhysician from "../page/physician/AboutPhysician";
import AboutRelationship from "../page/common/AboutRelationship";
import PhysicianClaimType from "../page/physician/PhysicianClaimType";
import CoveredIndividual from "../page/physician/CoveredIndividual";
import Condition from "../page/physician/Condition";
import PatientCare from "../page/physician/PatientCare";
import PhysicianEmployment from "../page/physician/PhysicianEmployment";
import PhysicianSummary from "../page/physician/PhysicianSummary";
import React, { useEffect } from "react";
import { gatherInitialValues, submitHandler } from "../../service/FormService";
import { BenefitType } from "../enums/BenefitType";
import { defaultDisabilityPhysicianFormValues } from "../disability/DisabilityPhysicianFormValues";
import { defaultPfmlPhysicianFormValues } from "../pfml/PfmlPhysicianFormValues";
import PhysicianDocument from "../page/physician/PhysicianDocument";
import PhysicianFinished from "../page/physician/PhysicianFinished";
import { FormikValues } from "formik";
import { Relationship } from "../enums/Relationship";
import { TypeOfClaim } from "../enums/TypeOfClaim";
import { useParams } from "react-router-dom";

const Physician = () => {
    const [formInitialValues, setFormInitialValues] = React.useState({});

    const params = useParams();
    const coverage = params.coverage as BenefitType;
    const claimId = params.claimId;

    useEffect(() => {
        async function findInitialValues() {
            const formValues = await gatherInitialValues(claimId, coverage, Party.PHYSICIAN);
            setFormInitialValues(formValues)
        }
        findInitialValues();
    }, [coverage, claimId])

    const initialValues = {
        ...findPhysicianValuesFor(coverage),
        ...formInitialValues,
    } as FormikValues;
    return <FormikStepper initialValues={initialValues}
                          onSubmit={submitHandler}>
        <FormikStep label={PhysicianPages.START}>
            <PhysicianStart/>
        </FormikStep>
        <FormikStep label={PhysicianPages.PHYSICIAN_INFO} hideStep={() => initialValues.physicianFirstName === undefined}
                    validate={(values) =>
                        addressValidator(values, "physicianStreetAddress", values.physicianStreetAddress, values.physicianCity, values.physicianState, values.physicianZipCode)
                    }>
            <AboutPhysician/>
        </FormikStep>
        <FormikStep label={PhysicianPages.PATIENT_INFO} hideStep={() => initialValues.relationship === undefined}>
            <AboutRelationship/>
        </FormikStep>
        <FormikStep label={PhysicianPages.CLAIM_TYPE} hideStep={() => initialValues.claimType === undefined}>
            <PhysicianClaimType/>
        </FormikStep>
        <FormikStep label={PhysicianPages.CONDITION} hideStep={() => initialValues.diagnoses === undefined}
                    validate={(values) => {
                        if ([values.inpatientCare, values.permanentOrLongTermCondition, values.multipleTreatmentsAndRecovery,
                            values.chronic, values.pregnancy, values.incapacityAndTreatment]
                            .every(item => item === false)) {
                            return {
                                inpatientCare: 'Please specify at least one serious health condition type.'
                            }
                        }
                    }}>
            <Condition/>
        </FormikStep>
        <FormikStep label={PhysicianPages.COVERED_INDIVIDUAL}
                    hideStep={(values) => initialValues.patientRelationship === undefined || !values?.relationship || values?.relationship === Relationship.EMPLOYEE }>
            <CoveredIndividual/>
        </FormikStep>
        <FormikStep label={PhysicianPages.ABILITY_TO_WORK}
                    hideStep={(values) => (initialValues.patientMissWork === undefined && initialValues.returnToWork === undefined)
                        || !values?.relationship || Relationship.FAMILY_MEMBER === values?.relationship
                        || !values?.claimType || TypeOfClaim.PREGNANCY === values?.claimType}>
            <PhysicianEmployment/>
        </FormikStep>
        <FormikStep label={PhysicianPages.PATIENT_CARE} hideStep={() => initialValues.conditionStartDate === undefined}
                    validationSchema={object({
                        restrictionOrCareStartDate: dateRangeValidator('conditionStartDate'),
                        restrictionOrCareEndDate: dateRangeValidator('restrictionOrCareStartDate'),
                        substanceAbuseDates: dateRangeArrayValidator()
                    })} validate={(values) => {
            if (values.medications && [values.medications, values.surgeries, values.therapies, values.referrals]
                .every(item => item.length === 0)) {
                return {
                    medications: 'Please specify at least one treatment.'
                }
            }
        }}>
            <PatientCare/>
        </FormikStep>
        <FormikStep label={PhysicianPages.DOCUMENT} hideStep={() => initialValues.documents === undefined} validationSchema={object({
            documents: validateDocumentContent()
        })} validate={(values) => validateDocumentsTotalSize(values)}>
            <PhysicianDocument/>
        </FormikStep>
        <FormikStep label={PhysicianPages.SUMMARY}>
            <PhysicianSummary/>
        </FormikStep>
        <FormikStep label={PhysicianPages.FINISH}>
            <PhysicianFinished/>
        </FormikStep>
    </FormikStepper>
}

function findPhysicianValuesFor(claimType: BenefitType) {
    if(BenefitType.PFML === claimType) {
        return defaultPfmlPhysicianFormValues;
    } else if(BenefitType.DISABILITY === claimType) {
        return defaultDisabilityPhysicianFormValues;
    }
    return {};
}

export default Physician;
