import React from 'react';
import Panel from "../../structure/Panel";
import ContactSupport from "../../support/ContactSupport";
import { FormValues } from "../../common/FormValues";
import DownloadClaim from "../../support/DownloadClaim";

export type BeneficiaryFinishedProps = {
    values?: FormValues,
}

const BeneficiaryFinished = (props: BeneficiaryFinishedProps) => {
    return (
        <Panel headerText="Thank you!"
               panelContent={
                   <div>
                       <h4>Thank you for completing the beneficiary portion of the claim. We'll contact you if additional information is
                           needed.</h4>
                       <DownloadClaim values={props.values}/>
                       <ContactSupport/>
                   </div>}
        >
        </Panel>
    )
};
export default BeneficiaryFinished;