import React, { useRef } from 'react';
import Panel from "../../structure/Panel";
import Button from "../../button/Button";
import { FormikStepProps } from "../../navigation/FormikStep";
import { BeneficiaryPages } from "../../enums/BeneficiaryPages";
import LoadingSpinner from '../../structure/LoadingSpinner';
import { BeneficiaryFormValues } from "../../common/BeneficiaryFormValues";
import _ from "lodash";
import { summarize } from "../../support/FormikSummarizer";
import FraudModal from "../../structure/FraudModal";
import NextSteps from "../../support/NextSteps";
import Signature, { isEmpty } from "../../input/Signature";
import { PdsButton, PdsGrid, PdsGridItem } from "@principal/design-system-react";
import { PdsIconEdit } from "@principal/design-system-icons-react";

type BeneficiarySummaryProps = {
    initialValues?: BeneficiaryFormValues,
    values?: BeneficiaryFormValues,
    setFieldValue?: Function,
    isSubmitting?: boolean,
    steps?: React.ReactElement<FormikStepProps>[],
    stepChangeCallback?: Function,
    children?: React.ReactNode
}

const BeneficiarySummary = (props: BeneficiarySummaryProps) => {
    const values = props.values;
    const steps = props.steps;
    const stepChangeCallback = props.stepChangeCallback;
    const summary = useRef(null);
    const needsSignature = values?.isUnitedStatesPerson === 'Yes';

    if(!values || !stepChangeCallback) {
        return null;
    }
    return (
        <div id="overall-summary" ref={summary}>
            <Panel headerText="Review and submit this claim."
                   panelContent={
                       <PdsGrid variant="2up">
                           <PdsGridItem style={{borderRightStyle: "solid", borderColor: "#e6e6e6", borderWidth: "1px"}}>
                               {steps?.map(s => {
                                   const page = s.props.label as BeneficiaryPages;
                                   if([BeneficiaryPages.START, BeneficiaryPages.SUMMARY, BeneficiaryPages.FINISH].includes(page)) {
                                       return <></>
                                   }
                                   return stepSummary(steps, stepChangeCallback, page);
                               })}
                           </PdsGridItem>
                           <PdsGridItem>
                               <h3 className="pds-u-margin-top-0">
                                   Is everything accurate?
                               </h3>
                               <div className="pds-u-padding-bottom-12">
                                   {needsSignature && <div className="pds-u-padding-bottom-12">
                                       <b>
                                           The Internal Revenue Service does not require your consent to any provision of this
                                           document other than the certifications required to avoid backup withholding.
                                       </b>
                                   </div>}
                                   <FraudModal {...props}/>
                                   {needsSignature &&
                                       <Signature {...props} label="Signature of beneficiary (please make sure you sign form as your name appears on your
                                           Social Security card.)" id="beneficiary-signature" required={true}/>}
                                   <Button type="submit"
                                           buttonContent={props.isSubmitting ? <LoadingSpinner spinnerText="Submitting"/> : "Submit Claim"}
                                           buttonProps={!props.values?.fraud || props.isSubmitting || (needsSignature && isEmpty(props.values.beneficiarySignature))
                                               ? { disabled: true }
                                               : {}}>
                                   </Button>
                               </div>
                               <NextSteps steps={["If we need any additional information, we'll contact you."]} />
                           </PdsGridItem>
                       </PdsGrid>}
            >
            </Panel>
        </div>
    )

    function stepSummary(steps: React.ReactElement<FormikStepProps>[],
                         stepChangeCallback: Function, page: BeneficiaryPages) {
        const stepIndex = findStepIndexByName(steps, page);
        const step = steps[stepIndex]
        return <div id={`${_.kebabCase(page)}-summary`} className="pds-u-padding-bottom-8">
            {buildHeaderLink(stepIndex, stepChangeCallback, page)}
            {indent(summarize(React.cloneElement(step.props.children, props), props.initialValues as any, props.values as any))}
        </div>;
    }

    function buildHeaderLink(stepIndex: number, stepChangeCallback: Function,
                             stepName: BeneficiaryPages) {
        return <PdsButton link="icon-left" size="sm" removeLinkPadding={true} type="button" onClick={() => {stepChangeCallback(stepIndex)}}>
            <PdsIconEdit slot="icon-left"/>
            <span>{stepName}</span>
        </PdsButton>;
    }

    function findStepIndexByName(steps: React.ReactElement<FormikStepProps>[], stepName: BeneficiaryPages) {
        return steps.findIndex((step) => step.props.label === stepName);
    }

    function indent(toIndent: JSX.Element) {
        return <div className="pds-u-padding-left-24">
            {toIndent}
        </div>
    }
};

export default BeneficiarySummary;
