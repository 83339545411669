import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import PhoneInput from "../../input/PhoneInput";
import SelectDropdown from "../../input/SelectDropdown";
import {BeneficiaryRelationshipType} from "../../enums/BeneficiaryRelationshipType";

const AboutContactPerson = () => {
    return (
        <QuestionPanel headerText="Do you have someone we can reach out to regarding this claim?"
                       detailsText="This can be a family member or a beneficiary. If you don't know of anyone, just click Next."
                       panelContent={
                           <div>
                               <TextInput id="contact-name" label="Family member/beneficiary's name" required={false}
                                          maxLength={1024}/>
                               <PhoneInput id="contact-phone-number" label="Family member/beneficiary's phone number"
                                           required={false}/>
                               <SelectDropdown id="contact-relationship"
                                               label="Family member/beneficiary's relationship" required={false}
                                               options={Object.keys(BeneficiaryRelationshipType).map(b => ({
                                                   id: b.replace(/_/g, '-').toLowerCase(),
                                                   text: (BeneficiaryRelationshipType as any)[b]
                                               }))}/>
                           </div>
                       }>
        </QuestionPanel>
    )
};
export default AboutContactPerson;