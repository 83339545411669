import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { Formik, FormikValues, useFormikContext } from "formik";
import { AuthProvider } from "oidc-react";

export type ValueInitializerProps = {
    values: FormikValues,
} & PropsWithChildren<any>

export const ValueInitializer = (props: ValueInitializerProps) => {
    const { setValues } = useFormikContext();
    const values = props.values;

    useEffect(() => {
        setValues(values);
    }, [values, setValues]);

    return React.Children.only(props.children);
}

export function summarize(element: ReactElement, initialValues: FormikValues, values: FormikValues): ReactElement {
    const html = ReactDOMServer.renderToStaticMarkup(<AuthProvider>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
            <ValueInitializer values={values}>
                {element}
            </ValueInitializer>
        </Formik>
    </AuthProvider>);
    const doc = new DOMParser().parseFromString(`<div>${html}</div>`, 'text/html');
    const labels = doc.querySelectorAll("label");
    const elements = [];
    for (let i = 0; i < labels.length; ++i) {
        const label = labels[i] as HTMLLabelElement;
        const fieldName = label.getAttribute('data-field-name');
        if(!fieldName) {
            addLabelTo(elements, label);
            continue;
        }
        let formikValue = getNested(values, fieldName);
        if(Array.isArray(formikValue)) {
            formikValue = formikValue.toString().replace(',', ', ')
        } else if('boolean' === typeof formikValue) {
            formikValue = formikValue ? 'Yes' : 'No'
        }
        if(!formikValue) {
            continue;
        }
        addLabelTo(elements, label);
        elements.push(<small>{mask(fieldName, formikValue)}</small>)
        elements.push(<br/>)
    }
    return <>{elements}</>
}

function addLabelTo(elements: any[], label: HTMLLabelElement) {
    const fieldLabel = label.getAttribute('data-field-label');
    elements.push(<small><b>{fieldLabel ? fieldLabel : label.textContent}</b></small>)
    elements.push(<br/>)
}

function getNested(theObject: any, path: string, separator?: string): any {
    try {
        separator = separator || '.';
        return path.replace('[', separator)
            .replace(']','')
            .split(separator)
            .reduce(
                function (obj, property) {
                    return obj[property];
                }, theObject);
    } catch (err) {
        return undefined;
    }
}

function mask(fieldName: string, fieldValue: string) {
    if(!["socialSecurityNumber",
        "accountNumber", "accountNumber2", "routingNumber",
        "employerAccountNumber", "employerAccountNumber2", "employerRoutingNumber"].includes(fieldName)) {
        return fieldValue;
    }
    return fieldValue.replace(/./g, '*')
}