import React, { ReactElement, ReactNode, useEffect } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import { FormValues } from "../../common/FormValues";
import DocumentUpload from "../../input/DocumentUpload";
import AddListElementButton from "../../button/AddListElementButton";
import { UploadedDocument } from "../../domain/UploadedDocument";
import { DocumentType } from "../../enums/DocumentType";
import { BenefitType, hasRequiredDocumentsFor } from "../../enums/BenefitType";

export type DocumentProps = {
    values?: FormValues,
    setFieldValue?: Function,
    setFieldError?: Function,
    documentHeader?: string,
    documentDescription?: ReactNode,
    documentTypeOptions: DocumentType[],
    panelContent?: ReactElement | boolean,
}

const Document = (props: DocumentProps) => {
    const {values, setFieldValue, setFieldError, documentHeader, documentDescription, documentTypeOptions, panelContent} = props;

    const firstDocumentType = documentTypeOptions[0];
    useEffect(() => {
        setFieldValue?.('defaultDocumentType', firstDocumentType)
    }, [setFieldValue, firstDocumentType])

    const documentTitle = documentHeader ? documentHeader : 'documents';
    const benefit = props.values?.benefit as BenefitType;
    const headerText = hasRequiredDocumentsFor(benefit) ?
        `Please upload any ${documentTitle} to support your claim.` :
        `Do you have ${documentTitle} to upload?`;
    const documentTypesWithOther = [...documentTypeOptions, DocumentType.OTHER]
    return (
        <QuestionPanel headerText={headerText}
                       detailsText={
                           <>
                               {documentDescription && <>{documentDescription}<br/><br/></>}
                               Additional supporting documentation can also be emailed to SBDClaims@principal.com or faxed to 800-255-6609. Please be sure to include your name and phone number with all documents that are submitted.
                               <br/><br/>
                               PDF files are supported, with a total maximum file size of 3MB.
                           </>}
                       panelContent={
                           <>
                               <AddListElementButton idPrefix="documents"
                                                     componentType={DocumentUpload}
                                                     elementInitialValue={{
                                                         document: '',
                                                         documentContent: '',
                                                         documentType: ''
                                                     } as UploadedDocument}
                                                     buttonText="Add Document"
                                                     values={values}
                                                     setFieldValue={setFieldValue}
                                                     setFieldError={setFieldError}
                                                     otherElementProps={documentTypesWithOther}/>
                               {panelContent}
                           </>
                       }
        >
        </QuestionPanel>
    )
};

export default Document;