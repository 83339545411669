import React, { useEffect, useRef } from 'react';
import { PdsCard } from "@principal/design-system-react";

export type CalloutProps = {
    children?: React.ReactNode
}

const Callout = (props: CalloutProps) => {
    const cardRef = useRef(null) as React.RefObject<any>;

    useEffect(() => {
        const shadowRoot = cardRef.current?.shadowRoot;
        const styleId = 'overflow-visible-style';
        if(shadowRoot?.getElementById(styleId)) {
            return;
        }
        const styleElement = document.createElement('style');
        styleElement.setAttribute('id', styleId);
        styleElement.innerHTML = 'article.pds-c-card {\n' +
            '    overflow: visible;\n' +
            '}';
        shadowRoot?.appendChild(styleElement);
    }, [cardRef])

    return (
        <PdsCard className="pds-u-margin-vertical-16" ref={cardRef}>
            {props.children}
        </PdsCard>
    )
};
export default Callout;