import { BenefitType } from "../enums/BenefitType";

export type LifeFormValues = {
    links: {
        beneficiary: string
        employer: string
    },
    claimDates: [{
        startDate: string
    }]
}

export const defaultLifeFormValues = {
    benefit: BenefitType.LIFE,
    links: {
        beneficiary: '',
        employer: ''
    },
    claimDates: [{
        startDate: ''
    }]
}