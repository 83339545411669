import React from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import TextInput from "../../input/TextInput";
import { PfmlPhysicianFormValues } from "../../pfml/PfmlPhysicianFormValues";
import RadioGroup from "../../structure/RadioGroup";
import { ReturnToWork } from "../../enums/ReturnToWork";
import NumericInput from "../../input/NumericInput";

export type CoveredIndividualProps = {
    values?: PfmlPhysicianFormValues,
    setFieldValue?: Function
}

const CoveredIndividual = (props: CoveredIndividualProps) => {
    const patientRequiresCare = props.values?.patientRequiresCare === 'Yes';
    const recommendedTimeOffPartTime = props.values?.recommendedTimeOff === ReturnToWork.YES_PART_TIME;
    const setFieldValue = props.setFieldValue;

    function recommendedTimeOffChange(radio: HTMLInputElement) {
        if(radio.value !== ReturnToWork.YES_PART_TIME && setFieldValue) {
            setFieldValue('recommendedTimeOffHours', '');
            setFieldValue('recommendedTimeOffDays', '');
        }
    }

    function patientRequiresCareChange(radio: HTMLInputElement) {
        if(radio.value !== 'Yes' && setFieldValue) {
            setFieldValue('recommendedTimeOff', '');
            recommendedTimeOffChange(radio)
        }
    }

    return (
        <QuestionPanel headerText="Who will be caring for your patient?"
                       detailsText="In this case, the covered individual is the person who has paid medical leave coverage with Principal."
                       panelContent={
                               <div>
                                   <TextInput id="first-name" label="Covered individual's first name" required={true} maxLength={1024}/>
                                   <TextInput id="last-name" label="Covered individual's last name" required={true} maxLength={1024}/>
                                   <TextInput id="patient-relationship" label="Relationship to patient" required={true} maxLength={1024}/>
                                   <RadioGroup id="patient-requires-care" label="Does the patient require care by the covered individual?"
                                               required={true}
                                               radioProps={[
                                                   { id: "yes", text: "Yes" , changeCallback: patientRequiresCareChange},
                                                   { id: "no", text: "No" , changeCallback: patientRequiresCareChange},
                                               ]}
                                   />
                                   {patientRequiresCare && <>
                                       <RadioGroup id="recommended-time-off"
                                                   label="Is the covered individual required to miss work in order to care for the patient?"
                                                   required={true}
                                                   radioProps={Object.keys(ReturnToWork).map(returnToWork => ({
                                                       id: returnToWork.replace(/_/g, '-').toLowerCase(),
                                                       text: (ReturnToWork as any)[returnToWork],
                                                       changeCallback: recommendedTimeOffChange
                                                   }))}/>
                                       {recommendedTimeOffPartTime && <>
                                           <NumericInput id="recommended-time-off-hours" label="Hours per day" required={true}
                                                         maxLength={2}/>
                                           <NumericInput id="recommended-time-off-days" label="Days per week" required={true}
                                                         maxLength={1}/>
                                       </>}
                                   </>}
                               </div>
                       }
        >
        </QuestionPanel>
    )
};
export default CoveredIndividual;