import React, { useRef } from 'react';
import Panel from "../../structure/Panel";
import Button from "../../button/Button";
import { FormikStepProps } from "../../navigation/FormikStep";
import { PhysicianPages } from "../../enums/PhysicianPages";
import LoadingSpinner from '../../structure/LoadingSpinner';
import { PhysicianFormValues } from "../../common/PhysicianFormValues";
import _ from "lodash";
import { summarize } from "../../support/FormikSummarizer";
import NextSteps from "../../support/NextSteps";
import FraudModal from "../../structure/FraudModal";
import { PdsButton, PdsGrid, PdsGridItem } from "@principal/design-system-react";
import { PdsIconEdit } from "@principal/design-system-icons-react";

type PhysicianSummaryProps = {
    initialValues?: PhysicianFormValues,
    values?: PhysicianFormValues,
    setFieldValue?: Function,
    isSubmitting?: boolean,
    steps?: React.ReactElement<FormikStepProps>[],
    stepChangeCallback?: Function,
    children?: React.ReactNode
}

const PhysicianSummary = (props: PhysicianSummaryProps) => {
    const values = props.values;
    const steps = props.steps;
    const stepChangeCallback = props.stepChangeCallback;
    const summary = useRef(null);

    if(!values || !stepChangeCallback) {
        return null;
    }
    return (
        <div id="overall-summary" ref={summary}>
            <Panel headerText="Review and submit this claim."
                   panelContent={
                       <PdsGrid variant="2up">
                           <PdsGridItem style={{borderRightStyle: "solid", borderColor: "#e6e6e6", borderWidth: "1px"}}>
                               {steps?.map(s => {
                                   const page = s.props.label as PhysicianPages;
                                   if([PhysicianPages.START, PhysicianPages.SUMMARY, PhysicianPages.FINISH].includes(page)) {
                                       return <></>
                                   }
                                   return stepSummary(steps, stepChangeCallback, page);
                               })}
                           </PdsGridItem>
                           <PdsGridItem>
                               <h3 className="pds-u-margin-top-0">
                                   Is everything accurate?
                               </h3>
                               <div className="pds-u-padding-bottom-8">
                                   <FraudModal {...props}/>
                                   <Button type="submit"
                                           buttonContent={props.isSubmitting ? <LoadingSpinner spinnerText="Submitting"/> : "Submit Claim"}
                                           buttonProps={props.isSubmitting ? { disabled: true } : {}}>
                                   </Button>
                               </div>
                               <NextSteps steps={['If we need any additional information, we\'ll contact you by email.']} />
                           </PdsGridItem>
                       </PdsGrid>}
            >
            </Panel>
        </div>
    )

    function stepSummary(steps: React.ReactElement<FormikStepProps>[],
                         stepChangeCallback: Function, page: PhysicianPages) {
        const stepIndex = findStepIndexByName(steps, page);
        const step = steps[stepIndex]
        return <div id={`${_.kebabCase(page)}-summary`} className="pds-u-padding-bottom-8">
            {buildHeaderLink(stepIndex, stepChangeCallback, page)}
            {indent(summarize(React.cloneElement(step.props.children, props), props.initialValues as any, props.values as any))}
        </div>;
    }

    function buildHeaderLink(stepIndex: number, stepChangeCallback: Function,
                             stepName: PhysicianPages) {
        return <PdsButton link="icon-left" size="sm" removeLinkPadding={true} type="button" onClick={() => {stepChangeCallback(stepIndex)}}>
            <PdsIconEdit slot="icon-left"/>
            <span>{stepName}</span>
        </PdsButton>;
    }

    function findStepIndexByName(steps: React.ReactElement<FormikStepProps>[], stepName: PhysicianPages) {
        return steps.findIndex((step) => step.props.label === stepName);
    }

    function indent(toIndent: JSX.Element) {
        return <div className="pds-u-padding-left-24">
            {toIndent}
        </div>
    }
};

export default PhysicianSummary;
