import SelectDropdown from "../../input/SelectDropdown";
import {HospitalizationBenefits} from "../../enums/HospitalizationBenefits";
import {SurgeryBenefits} from "../../enums/SurgeryBenefits";
import {MedicalTests} from "../../enums/MedicalTests";
import {OtherBenefitChoices} from "../../enums/OtherBenefitChoices";
import {EmergencyCare} from "../../enums/EmergencyCare";
import {OutpatientBenefits} from "../../enums/OutpatientBenefits";
import {TypeOfClaim} from "../../enums/TypeOfClaim";
import {SubstanceAbuse} from "../../enums/Substance Abuse";
import {MentalDisorder} from "../../enums/MentalDisorder";
import {TypeOfDelivery} from "../../enums/TypeOfDelivery";
import {PregnancyCare} from "../../enums/PregnancyCare";
import QuestionPanel from "../../structure/QuestionPanel";
import React from "react";
import {EmployeeFormValues} from "../../common/EmployeeFormValues";

type BenefitChoicesProps = {
    values?: EmployeeFormValues,
}

function BenefitChoices(props: BenefitChoicesProps) {
    const values = props.values;
    const typeOfClaim = values?.claimType as TypeOfClaim;

    return (
        <QuestionPanel headerText="Please select all benefits that you're filing for."
                       panelContent={<>
                           <SelectDropdown id="hospitalization" label="Hospitalization" required={false}
                                           multiple={true}
                                           options={Object.keys(HospitalizationBenefits).map(p => ({
                                               id: p.replace(/_/g, '-').toLowerCase(),
                                               text: (HospitalizationBenefits as any)[p]
                                           }))}/>
                           <SelectDropdown id="surgery-benefits" label="Surgery" required={false} multiple={true}
                                           options={Object.keys(SurgeryBenefits).map(p => ({
                                               id: p.replace(/_/g, '-').toLowerCase(),
                                               text: (SurgeryBenefits as any)[p]
                                           }))}/>
                           <SelectDropdown id="medical-tests" label="Medical tests" required={false} multiple={true}
                                           options={Object.keys(MedicalTests).map(p => ({
                                               id: p.replace(/_/g, '-').toLowerCase(),
                                               text: (MedicalTests as any)[p]
                                           }))}/>
                           <SelectDropdown id="other-benefits" label="Other benefits" required={false}
                                           multiple={true}
                                           options={Object.keys(OtherBenefitChoices).map(p => ({
                                               id: p.replace(/_/g, '-').toLowerCase(),
                                               text: (OtherBenefitChoices as any)[p]
                                           }))}/>
                           <SelectDropdown id="emergency-care" label="Emergency care" required={false}
                                           multiple={true}
                                           options={Object.keys(EmergencyCare).map(p => ({
                                               id: p.replace(/_/g, '-').toLowerCase(),
                                               text: (EmergencyCare as any)[p]
                                           }))}/>
                           <SelectDropdown id="outpatient-benefits" label="Outpatient benefits" required={false}
                                           multiple={true}
                                           options={Object.keys(OutpatientBenefits).map(p => ({
                                               id: p.replace(/_/g, '-').toLowerCase(),
                                               text: (OutpatientBenefits as any)[p]
                                           }))}/>
                           {TypeOfClaim.SICKNESS === typeOfClaim
                           && <>
                               <SelectDropdown id="substance-abuse" label="Substance abuse" required={false}
                                               multiple={true}
                                               options={Object.keys(SubstanceAbuse).map(p => ({
                                                   id: p.replace(/_/g, '-').toLowerCase(),
                                                   text: (SubstanceAbuse as any)[p]
                                               }))}/>
                               <SelectDropdown id="mental-disorder" label="Mental disorder" required={false}
                                               multiple={true}
                                               options={Object.keys(MentalDisorder).map(p => ({
                                                   id: p.replace(/_/g, '-').toLowerCase(),
                                                   text: (MentalDisorder as any)[p]
                                               }))}/>
                           </>}
                           {TypeOfClaim.PREGNANCY === typeOfClaim
                           && <>
                               <SelectDropdown id="type-of-delivery" label="Type of delivery" required={false}
                                               multiple={true}
                                               options={Object.keys(TypeOfDelivery).map(p => ({
                                                   id: p.replace(/_/g, '-').toLowerCase(),
                                                   text: (TypeOfDelivery as any)[p]
                                               }))}/>
                               <SelectDropdown id="pregnancy-care" label="Pregnancy care" required={false}
                                               multiple={true}
                                               options={Object.keys(PregnancyCare).map(p => ({
                                                   id: p.replace(/_/g, '-').toLowerCase(),
                                                   text: (PregnancyCare as any)[p]
                                               }))}/>
                           </>}
                       </>}
        >
        </QuestionPanel>
    )
}

export default BenefitChoices;