import { Party } from "../component/enums/Party";
import { v4 as uuidv4 } from "uuid";
import { FormikHelpers, FormikValues } from "formik";
import { NavigateFunction } from "react-router-dom";
import { sendIntake } from "../component/intake/IntakeSender";
import { BenefitType, findRequiredPartiesFor } from "../component/enums/BenefitType";
import axios from "axios";
import generatePDF, { Resolution } from "react-to-pdf";

export async function gatherInitialValues(routeClaimId: string | undefined | null, benefit: BenefitType, party: Party, token?: string) {
    const claimId = routeClaimId ?? uuidv4();
    const baseUrl = `${process.env.REACT_APP_FORMS_BASE_URL}${benefit}/${claimId}/`;
    pushToGoogleAnalytics(claimId, party);
    let formValues = undefined;
    if(token) {
        try {
            const affiliateResponse = await axios.get(`${process.env.REACT_APP_FORMS_SERVICE_URL}/affiliate?party=${party}&benefit=${benefit}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            formValues = affiliateResponse.data
        } catch(e) {
            console.log(e);
        }
    }
    const requiredParties = findRequiredPartiesFor(benefit);
    const links = requiredParties.reduce((acc: any, party: string) => {
        acc[party] = baseUrl + party;
        return acc;
    }, {});
    return {
        claimId: claimId,
        links,
        party: party,
        formKey: `${benefit}-${party}`,
        ...(formValues ? formValues : {})
    }
}

export async function submitHandler(initialValues: FormikValues, values: FormikValues, helpers: FormikHelpers<any>, navigate: NavigateFunction) {
    removeValuesNotPresentInitiallyFrom(initialValues, values);
    await generateSummaryPdf(values, helpers.setFieldValue);
    try {
        const claimNumber = await sendIntake(values);
        await helpers.setFieldValue('claimNumber', claimNumber)
    } catch (e) {
        navigate("/error")
    }
}

async function generateSummaryPdf(values: FormikValues, setFieldValue?: Function) {
    const summaryContent = (await generatePDF(
        () => document.getElementById('root'),
        { method: 'build', resolution: Resolution.LOW })).output('datauristring');
    values.summaryContent = summaryContent
    setFieldValue?.('summaryContent', summaryContent)
}

function removeValuesNotPresentInitiallyFrom(initialValues: FormikValues, values: FormikValues) {
    const initialValueKeys = Object.keys(initialValues);
    Object.keys(values).forEach(key => {
        if (!initialValueKeys.includes(key)) {
            delete values[key]
        }
    })
}

function pushToGoogleAnalytics(claimId: string, party: Party) {
    let customerType = 'MEMBER'
    if (party === Party.EMPLOYER) {
        customerType = 'EMPLOYER'
    } else if (party === Party.PHYSICIAN) {
        customerType = 'PROVIDER'
    }
    // @ts-ignore
    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'info-added',
        'contract': {
            'contract-number': claimId,
        },
        'user-info': {
            'customer-type': customerType
        }
    });
}
