import React from 'react';
import TwoColumnDiv from "../structure/TwoColumnDiv";
import DateInput from "./DateInput";
import { ListElementProps } from "../button/AddListElementButton";
import CloseButton from "../button/CloseButton";
import SelectDropdown from "./SelectDropdown";
import { PayType } from "../enums/PayType";
var _ = require('lodash');

const Pay = (props: ListElementProps) => {
    const namePrefix = _.camelCase(props.idPrefix);
    const index = props.index;
    return (
        <div>
            { props.closeCallback && <CloseButton idPrefix="" index={index} closeCallback={props.closeCallback}/> }
            <TwoColumnDiv
                leftContent={
                    <SelectDropdown id={props.idPrefix + "-pay-type-" + index} name={`${namePrefix}[${index}].payType`}
                                    label="Pay type" required={true} options={
                        Object.keys(PayType).map(p => ({
                            id: p.replace(/_/g, '-').toLowerCase(),
                            text: (PayType as any)[p]
                        }))}/>
                }
                rightContent={
                    <DateInput id={props.idPrefix + "-end-date-" + index} name={`${namePrefix}[${index}].endDate`}
                               label="Paid through" required={true}/>
                }
            />
        </div>
    )
};

export default Pay;