import React from 'react';
import {EmployeeFormValues} from "../../common/EmployeeFormValues";
import {BenefitType} from "../../enums/BenefitType";
import OtherBenefits from "./OtherBenefits";
import BenefitChoices from "./BenefitChoices";

type BenefitProps = {
    values?: EmployeeFormValues,
}

const Benefits = (props: BenefitProps) => {
    const values = props.values;
    const benefit = values?.benefit as BenefitType;

    return benefit === BenefitType.HOSPITAL_INDEMNITY ? <BenefitChoices values={values}/> : <OtherBenefits values={values}/>
};

export default Benefits;