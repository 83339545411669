import React from 'react';
import { PfmlEmployerFormValues } from "../../pfml/PfmlEmployerFormValues";
import ClaimType from "../common/ClaimType";
import DateRange from "../../input/DateRange";
import Checkbox from "../../input/Checkbox";
import TwoColumnDiv from "../../structure/TwoColumnDiv";
import NumericInput from "../../input/NumericInput";
import { findClaimDateFor } from "../../enums/BenefitType";

export type EmployerClaimTypeProps = {
    values?: PfmlEmployerFormValues,
    setFieldValue?: Function
}

const EmployerClaimType = (props: EmployerClaimTypeProps) => {
    function priorDisabilityChange(checkbox: HTMLInputElement) {
        if(!checkbox.checked && props.setFieldValue) {
            props.setFieldValue('priorDisabilityDates[0].startDate', '')
            props.setFieldValue('priorDisabilityDates[0].endDate', '')
        }
    }

    function priorPfmlChange(checkbox: HTMLInputElement) {
        if(!checkbox.checked && props.setFieldValue) {
            props.setFieldValue('priorPaidFamilyMedicalLeaveDates[0].startDate', '')
            props.setFieldValue('priorPaidFamilyMedicalLeaveDates[0].endDate', '')
            props.setFieldValue('priorPaidFamilyMedicalLeaveHours', '')
        }
    }

    return (
        <>
            <ClaimType headerText="What type of claim is being requested?"
                       {...props}>
                {(props.values?.hasOwnProperty?.('priorDisability') || props.values?.hasOwnProperty?.('priorPaidFamilyMedicalLeave'))
                    && <h5 id="click-next">Has your employee taken leave in the past 52 weeks?</h5>}
                <Checkbox id="prior-disability" label="Disability" changeCallback={priorDisabilityChange}/>
                {props.values?.priorDisability && <DateRange idPrefix="prior-disability-dates" index={0}/>}
                <Checkbox id="prior-paid-family-medical-leave" label="Paid family or medical leave" changeCallback={priorPfmlChange}/>
                {props.values?.priorPaidFamilyMedicalLeave &&
                    <div>
                        <DateRange idPrefix="prior-paid-family-medical-leave-dates" index={0}/>
                        <TwoColumnDiv leftContent={
                            <NumericInput id="prior-paid-family-medical-leave-hours"
                                          label="Total hours of paid leave" required={true}/>}>
                        </TwoColumnDiv>
                    </div>}
                <DateRange idPrefix="claim-dates" index={0} startLabel={findClaimDateFor(props.values?.benefit).startDateLabel} startRequired={false}/>
            </ClaimType>
        </>
    )
};
export default EmployerClaimType;