import React, { useEffect, useState } from 'react';
import QuestionPanel from "../../structure/QuestionPanel";
import NumericInput from "../../input/NumericInput";
import Tooltip from "../../input/Tooltip";
import TextInput from "../../input/TextInput";
import PhoneInput from "../../input/PhoneInput";
import TwoColumnDiv from "../../structure/TwoColumnDiv";
import { EmployerFormValues } from "../../common/EmployerFormValues";
import { useAuth } from "oidc-react";
import * as employerService from "../../../service/EmployerService"
import LoadingSpinner from "../../structure/LoadingSpinner";
import SelectDropdown from "../../input/SelectDropdown";
import { Group } from "../../../../service/src/domain/Group";
import EmailInput from "../../input/EmailInput";

export type AboutEmployerProps = {
    values?: EmployerFormValues,
    setFieldValue?: Function
}

const AboutEmployer = (props: AboutEmployerProps) => {
    const [employer, setEmployer] = useState();
    const auth = useAuth();

    const values = props.values;
    const policyNumber = values?.policyNumber;
    const unitNumber = values?.unitNumber;
    const setFieldValue = props.setFieldValue;
    const userData = auth.userData;
    const signedIn = !!userData;
    const token = userData?.access_token;
    const loading = signedIn && !employer
    const policies = (employer as any as Group)?.policies;

    useEffect(() => {
        async function loadEmployer() {
            if (!signedIn || employer) {
                return;
            }
            const serviceEmployer = await employerService.findEmployerFor(token);
            setEmployer(serviceEmployer as any);
            if (serviceEmployer.policies?.length !== 1) {
                return;
            }
            const firstPolicy = serviceEmployer.policies[0];
            if (!policyNumber) {
                setFieldValue?.('policyNumber', firstPolicy.number);
            }
            if (firstPolicy.units.length !== 1) {
                return;
            }
            if (!unitNumber) {
                setFieldValue?.('unitNumber', firstPolicy.units[0].number);
            }
        }

        loadEmployer();
    }, [policyNumber, unitNumber, signedIn, token, setFieldValue, employer]);

    useEffect(() => {
        setFieldValue?.('loading', loading);
        return () => setFieldValue?.('loading', false);
    }, [loading, setFieldValue]);

    if (loading) {
        return <LoadingSpinner spinnerText='Loading'/>
    }

    function buildPolicyNumber() {
        if (!signedIn || !policies) {
            return <NumericInput id="policy-number" label="Principal plan ID" required={false}
                                 maxLength={7} labelContent={<Tooltip text="You can find this 7-digit number in your policy booklet."/>}/>
        }
        return <SelectDropdown id="policy-number" label="Principal plan ID" required={false}
                               changeCallback={() => {
                                   setFieldValue?.('unitNumber', '')
                                   setFieldValue?.('employerName', '')
                               }}
                               options={policies.map((p, index) => {
                                   return {
                                       id: String(index),
                                       text: p.number
                                   }
                               })}/>
    }

    function buildUnitNumber() {
        if (!signedIn || !policies) {
            return <NumericInput id="unit-number" label="Unit number" required={false} maxLength={5}
                                 labelContent={<Tooltip
                                     text="You can find this 5 digit number in your policy booklet, depending on what unit your employee is in."/>}/>
        }
        const policy = policies.find(p => p.number === policyNumber);
        return <SelectDropdown id="unit-number" label="Unit number" required={false}
                               changeCallback={(e: any) =>
                                   setFieldValue?.('employerName', policy?.units?.find(u => u.number === e.value)?.name ?? "") }
                               options={policy ? policy.units.map((u, index) => {
                                   return {
                                       id: String(index),
                                       text: u.number
                                   }
                               }) : []}/>
    }

    return (
        <QuestionPanel headerText="Let's gather some information about you."
                       panelContent={
                           <div>
                               <TwoColumnDiv leftContent={buildPolicyNumber()} rightContent={buildUnitNumber()}/>
                               <TextInput id="employer-name" label="Company / organization name" required={true}/>
                               <TextInput id="employer-contact-name" label="Employer contact name" required={true}/>
                               <TextInput id="employer-title" label="Employer contact title" required={false}/>
                               <EmailInput id="employer-email-address" label="Employer contact email" required={true}/>
                               <PhoneInput id="employer-phone-number" label="Employer contact phone" required={true}/>
                           </div>
                       }/>
    )
};
export default AboutEmployer;